import Snackbar from '@mui/material/Snackbar';
import { useState, useEffect } from "react";
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notificacion ({ mensaje,visible = false,funcionAbrir, funcionCerrar, ...props }){
  const[snackVisible, setSnackVisible] = useState(false);
  useEffect(() => {
    setSnackVisible(visible);
  }, [visible]);
  return (      
    <div>
      <Snackbar 
      open={snackVisible} 
      autoHideDuration={3000} 
      onClose={funcionCerrar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      style={{ position: 'fixed',top: '50vh', right: '40vw' }}
      >
        <Alert onClose={funcionCerrar} severity="info" sx={{ width: '100%' }}>
          {mensaje}
        </Alert>
      </Snackbar>
    </div>
  )
};
export default Notificacion 