import { useEffect,useState } from "react";
import HookModal from "../hooks/hookModal";
import ModalAplicarPercepciones from "../modalAplicarPercepciones";
import rutas from "../../configuracion/Configuracion";
import TablaPercepciones from "../tablaPercepciones";
import Modal from "../modal";
import { Button } from "@mui/material";
import Sesion from "../../configuracion/controladorSesiones";

export default function IconoPercepciones({
  codigoFactura,actualizar
}){
  const[modal,abrirModal,cerrarModal] = HookModal();
  const [cargando, setCargando] = useState(false);
  const [exitoso, setExitoso] = useState(false);

  useEffect( () => {
    if(exitoso){
      actualizar();
    }
  }, [exitoso]);
  const aplicarPercepciones = async () =>{
    try {
      const sesion =  new Sesion();
      if(sesion.sesionActiva() && codigoFactura != ''){
        setCargando(true)
        const response = await fetch(rutas.API + rutas.aplicarPercepciones + codigoFactura);
        const data = await response.json();
        if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
          setExitoso(true);
          cerrarModal();
        }else{
          cerrarModal();
        }
        setCargando(false)
      }
    } catch (error) {
      console.error(error);
    }
  }
  return(  
    <div>
      <div>
        {cargando && 
        <div className="modal-background" visible>
            <div className="modal-container" >
                <div className="loader" />
                <p>Aplicando pago...</p>
            </div>
        </div>}
        {!cargando &&
        <Modal isOpen={modal} closeModal={cerrarModal} margenSuperior='0px' >
          <h1  style={{ marginTop:0}} >Percepciones aplicables</h1>
          <TablaPercepciones codigoFactura={codigoFactura} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:20, gap:10}} >
              <Button variant="contained" onClick={aplicarPercepciones}>APLICAR</Button>
              <Button variant="contained" onClick={cerrarModal}>CANCELAR</Button>
          </div>
        </Modal>}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 96 960 960"
        fill="green"
        onClick={abrirModal}
      >
        <path d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z" />
      </svg>
    </div>

  )
}
