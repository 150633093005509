import { useState, useEffect } from "react"
import Sesion from '../configuracion/controladorSesiones';
import Modal from "./modal";
import HookModal from "./hooks/hookModal";
import { Link } from "react-router-dom";
import IconoInfo from "./iconos/iconoInfo";
import IconoAlert from "./iconos/iconoAlert";


function ModalEmailSinRegistrar(){
    const[estadoModal, abrir, cerrar] = HookModal();

    useEffect(() => {
        const sesion = new Sesion();
        if(!sesion.emailRegistrado()){
            abrir()
        }else{
            cerrar()
        }
        
    }, []);
    return (
        <div>
            <Modal 
                isOpen={estadoModal} 
                closeModal={cerrar} 
                blockedBackground={true} 
                children={generarMensaje()}
            />
        </div>
    )
    function generarMensaje(){
        return(
            <div style={{ maxWidth: 600}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:500}} >
                        <IconoAlert/>
                        <p>Su usuario no tiene registrada una direccion de correo electronico, por favor <Link to={"/contribuyente/registro/email"}>registre su correo electronico.</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalEmailSinRegistrar