import {
    StyledTableContainer,StyledTableHead,ColumnaNumerica,
    StyledTableBody,ColumnaNumericaHeader,
    ColumnaTexto,ColumnaTextoHeader,
    ColumnaTextoExtendida,StyledTableRow
} from './componentesModificados';
import {
    Table,
    Box,
    Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState,useEffect } from 'react';
import Sesion from '../configuracion/controladorSesiones';
import rutas from '../configuracion/Configuracion';
import {useNavigate } from 'react-router-dom';


function TablaPlanesPago({setModalCargando}){
    const[rows, setRows] = useState([]);
    const anchoColumnas = ['10%','5%', '65%','10%','5%','5%'];
    const navigate = useNavigate();
    const estiloPuntero = {
        cursor: 'pointer' // Cambia 'pointer' por el tipo de cursor que desees
      };
    //busquedas 
    const buscarPlanes = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                setModalCargando(true)
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                const response = await fetch(rutas.API + rutas.BusquedaPlanesPago + codigoContribuyente);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setRows(data.Datos.Instancias)
                }else{
                    //generarMensaje("Error de conexion")
                }
                setModalCargando(false)
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    //busquedas
    //actualizacion y estados
    useEffect(() => {
        buscarPlanes();
    }, []);
    //actualizacion y estados

    const handleRow = estado => {
        if(estado.tipo === "MOD"){
            //planes de pago en modulos
            navigate("/contribuyente/planpago/modulos" + "?Codigo=" + estado.codigo );
        }else{
            navigate("/contribuyente/planpago" + "?Codigo=" + estado.codigo + "&tipo=" + estado.tipo);
        }
        
    }

    return(
        <div>
            <div >
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom:'100px'}}>
                    <StyledTableContainer >
                        <Table stickyHeader >
                            <StyledTableHead >
                                <StyledTableRow>
                                    <ColumnaTextoHeader style={{ width: anchoColumnas[0],minWidth:0 }}>TIPO</ColumnaTextoHeader>
                                    <ColumnaTextoHeader style={{ width: anchoColumnas[1],minWidth:0 }}>ESTADO</ColumnaTextoHeader>
                                    <ColumnaTextoExtendida style={{ width: anchoColumnas[2],minWidth:0 }}>NOMBRE</ColumnaTextoExtendida>
                                    <ColumnaNumericaHeader style={{ width: anchoColumnas[3],minWidth:0, textAlign:'right' }}>SALDO EXIGIBLE</ColumnaNumericaHeader>
                                    <ColumnaNumericaHeader style={{ width: anchoColumnas[4],minWidth:0, textAlign:'right' }}>INTERÉS</ColumnaNumericaHeader>
                                    <ColumnaNumericaHeader style={{ width: anchoColumnas[5],minWidth:0, textAlign:'right' }}>TOTAL</ColumnaNumericaHeader>
                                </StyledTableRow>
                            </StyledTableHead>
                            <StyledTableBody style={estiloPuntero}>
                            {
                                rows.map((row) => {
                                    return generarFila(row);
                                })
                            }
                            </StyledTableBody>
                        </Table>
                    </StyledTableContainer>
                </Box>
            </div>
        </div>
    )
    function generarFila(data){
        var estadoPadron = data.activo;
        return (
            <StyledTableRow onClick={() => handleRow(data)}>
                <ColumnaTexto style={{ width: anchoColumnas[0] }}>
                    {data.tipoImpuesto}
                </ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[1] }} component="th" scope="row">
                    {data.estado}
                </ColumnaTexto>
                <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }} component="th" scope="row">
                    {data.nombre}
                </ColumnaTextoExtendida>
                <ColumnaNumerica style={{ width: anchoColumnas[3] }} component="th" scope="row">
                    {data.saldo.toFixed(2)}
                </ColumnaNumerica>
                <ColumnaNumerica style={{ width: anchoColumnas[4] }} component="th" scope="row">
                    {data.interes.toFixed(2)}
                </ColumnaNumerica>
                <ColumnaNumerica style={{ width: anchoColumnas[5] }} component="th" scope="row">
                    {data.total.toFixed(2)}
                </ColumnaNumerica>
            </StyledTableRow>
        );
    };

}
export default TablaPlanesPago;
