import React from "react";
import Footer from "../componentes/footer";
import Header from "../componentes/header";
import IconoEmail from "../componentes/iconos/iconoEmail";

function Contacto(){
    const panelStyle = {
        marginTop:120,
        fontFamily:'Roboto, sans-serif',
        display: 'block',
        marginBottom:120
      };
      const panelItem = {
        //display: 'inline-flex'
      };
      const tituloItem = {
        marginTop: 0,
        marginBottom: 0
      };
    return(
        <div>
            <Header hideButton={false} hideReturnMenu={false}/>
            <div style={panelStyle}>
                <h1 style={{marginLeft:30}}>Correos de contacto</h1>
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                        <IconoEmail/>
                        <div style={{marginLeft:30}}>
                            <h3 style={tituloItem}>
                                Agentes de Percepción/Agentes de Retención Ingresos Brutos:
                            </h3>
                            fiscalizacionycobranzas@trevelin.gob.ar
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                        <IconoEmail/>
                        <div style={{marginLeft:30}}>
                            <h3 style={tituloItem}>
                                Impuestos, Tasas y Contribuciones  en general: 
                            </h3>
                            rentas@trevelin.gob.ar
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                        <IconoEmail/>
                        <div style={{marginLeft:30}}>
                            <h3 style={tituloItem}>
                                Inmuebles (valuación Fiscal, Detalle de Deuda, Libre de Deuda, etc.): 
                            </h3>
                            escribanias@trevelin.gob.ar
                        </div>
                    </div>
                </ul>
            </div>
            <Footer/>   
        </div>
    )

}
export default Contacto;