
import Login from '../pages/login';
import NotFound from '../pages/notfound';
import IngresosBrutos from '../pages/contribuyente/ingresosbrutos';
import CambiarContrasenia from '../pages/cambiarContrasenia';
import Impuestos from '../pages/contribuyente/impuestos';
import TISH from '../pages/contribuyente/tish';
import Urbano from '../pages/contribuyente/urbano';
import Automotor from '../pages/contribuyente/automotor';
import Rural from '../pages/contribuyente/rural';
import Impresion from '../pages/contribuyente/impresion';
import Contacto from '../pages/contacto';
import PlanPago from '../pages/contribuyente/planPago';
import GenerarPlanPago from '../pages/contribuyente/generarPlanPago';
import Edicion from '../pages/contribuyente/edicion';
import TerminosPlanPago from '../pages/contribuyente/terminosPlanPago';
import Registro from '../pages/registro';
import RegistroEmail from '../pages/contribuyente/RegistroEmail';
import LoginRedireccionado from '../pages/loginRedireccionado';
import FacturasSueltas from '../pages/contribuyente/facturasSueltas';
import PlanPagoModulos from '../pages/contribuyente/planPagoModulos';

//const subruta = '/test2023'; // Define la subruta que deseas utilizar
const subruta = ''; // Define la subruta que deseas utilizar


const routerConfig = [
  {
    path: `${subruta}/`,
    element: <Login/>,
    errorElement: <NotFound/>
  },
  {
    path: `${subruta}/login`,
    element: <Login/>,
  },
  {
    path: `${subruta}/contribuyente/ingresosbrutos`,
    element: <IngresosBrutos/>,
  },
  {
    path: `${subruta}/cambio`,
    element: <CambiarContrasenia/>,
  },
  {
    path: `${subruta}/contribuyente/impuestos`,
    element: <Impuestos/>,
  },
  {
    path: `${subruta}/contribuyente/tish`,
    element: <TISH/>,
  },
  {
    path: `${subruta}/contribuyente/urbano`,
    element: <Urbano/>,
  },
  {
    path: `${subruta}/contribuyente/rural`,
    element: <Rural/>,
  },
  {
    path: `${subruta}/contribuyente/automotor`,
    element: <Automotor/>,
  },
  {
    path: `${subruta}/contribuyente/impresion`,
    element: <Impresion/>,
  },
  {
    path: `${subruta}/contacto`,
    element: <Contacto/>,
  },
  {
    path: `${subruta}/contribuyente/planpago`,
    element: <PlanPago/>,
  },
  {
    path: `${subruta}/contribuyente/planpago/modulos`,
    element: <PlanPagoModulos/>,
  },
  {
    path: `${subruta}/contribuyente/planpago/generar`,
    element: <GenerarPlanPago/>,
  },
  {
    path: `${subruta}/contribuyente/edicion`,
    element: <Edicion/>,
  },
  {
    path: `${subruta}/contratos/planpago`,
    element: <TerminosPlanPago/>,
  },
  {
    path: `${subruta}/registro`,
    element: <Registro/>,
  },
  {
    path: `${subruta}/contribuyente/registro/email`,
    element: <RegistroEmail/>,
  },
  {
    path: `${subruta}/login/registro/email`,
    element: <RegistroEmail/>,
  },
  {
    path: `${subruta}/login/redireccion`,
    element: <LoginRedireccionado/>,
  },
  {
    path: `${subruta}/contribuyente/facturas`,
    element: <FacturasSueltas/>,
  },
];

const RouterDirecciones = {
  routes: routerConfig,
};

export default RouterDirecciones;