import { useEffect,useState } from "react";
import rutas from '../configuracion/Configuracion'
import { Box,Grid,TextField } from "@mui/material";
import CampoEditable from "./campoEditable";
import MenuCertificados from "./menuCertificados";


function PanelIIBB({codigoCliente}){
    const [impuesto1, setImpuesto1] = useState("");
    const [impuesto2, setImpuesto2] = useState("");
    const [impuesto3, setImpuesto3] = useState("");
    const [existeImpuesto1, setExisteImpuesto1] = useState(false);
    const [existeImpuesto2, setExisteImpuesto2] = useState(false);
    const [existeImpuesto3, setExisteImpuesto3] = useState(false);
    const divStyle = {
        //border: '1px solid gray',
        //boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        //borderRadius: '5px',
        //padding: '10px',
        margin: '20px',
        color: '#00000061',
        fontFamily:'Roboto, sans-serif',
        marginTop:40, 
        marginBottom: 40,
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(rutas.API + rutas.BusquedaDatosiibb + codigoCliente);
                const data = await response.json();
                if(data.Exito){//busqueda exitosa
                    setImpuesto1(data.Datos.Datos.nombre1)
                    if(data.Datos.Datos.hasOwnProperty("nombre1")){
                        setExisteImpuesto1(true)
                    }else{
                        setExisteImpuesto1(false)
                    }
                    setImpuesto2(data.Datos.Datos.nombre2)
                    if(data.Datos.Datos.hasOwnProperty("nombre2")){
                        setExisteImpuesto2(true)
                    }else{
                        setExisteImpuesto2(false)
                    }
                    setImpuesto3(data.Datos.Datos.nombre3)
                    if(data.Datos.Datos.hasOwnProperty("nombre3")){
                        setExisteImpuesto3(true)
                    }else{
                        setExisteImpuesto3(false)
                    }

                }
              } catch (error) {
                console.error(error);
              }
          }
        fetchData();
    }, [codigoCliente]);
    return(
        <div style={divStyle} >
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={2}>
                    {existeImpuesto1  &&                 
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="IB.Actividad"
                                id="standard-basic"  
                                variant="standard"
                                value = {impuesto1} 
                            />
                        </Grid>
                    }
                    {existeImpuesto1  &&                 
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Estado"
                                id="standard-basic"  
                                variant="standard"
                                value = {"Activo"} 
                            />
                        </Grid>
                    }
                    {existeImpuesto2  &&                 
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="IB.Actividad"
                                id="standard-basic"  
                                variant="standard"
                                value = {impuesto2} 
                            />
                        </Grid>
                    }
                    {existeImpuesto2  &&                 
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Estado"
                                id="standard-basic"  
                                variant="standard"
                                value = {"Activo"} 
                            />
                        </Grid>
                    }
                    {existeImpuesto3  &&                 
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="IB.Actividad"
                                id="standard-basic"  
                                variant="standard"
                                value = {impuesto3} 
                            />
                        </Grid>
                    }
                    {existeImpuesto3  &&                 
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Estado"
                                id="standard-basic"  
                                variant="standard"
                                value = {"Activo"} 
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
        </div>
    )
}
export default PanelIIBB;
