import { useState, useEffect } from "react"
import Sesion from '../configuracion/controladorSesiones';
import Modal from "./modal";
import { useNavigate } from 'react-router-dom';
import HookModal from "./hooks/hookModal";
import { Link } from "react-router-dom";


function ModalPrimerUso(){
    const[estadoModal, abrir, cerrar] = HookModal();

    useEffect(() => {
        const sesion = new Sesion();
        if(!sesion.primerUso()){
            abrir()
        }else{
            cerrar()
        }
        
    }, []);
    return (
        <div>
            <Modal 
                isOpen={estadoModal} 
                closeModal={cerrar} 
                blockedBackground={true} 
                children={generarMensaje()}
            />
        </div>
    )
    function generarMensaje(){
        return(
            <div style={{ maxWidth: 600}}>
                <p>El usuario se genero por primera vez, debe generar una nueva contraseña para continuar. Haga clic en el siguiente enlace para <Link to={"/cambio"}>cambiar su contraseña.</Link>
                </p>
            </div>
        )
    }
}
export default ModalPrimerUso