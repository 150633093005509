import { useState,useEffect } from 'react';
import rutas from '../configuracion/Configuracion'
import Sesion from '../configuracion/controladorSesiones';
import { Button} from "@mui/material";
import Modal from './modal';
import IconoCorrecto from './iconos/iconoCorrecto';
import HookModal from './hooks/hookModal';

function PanelSugerencias({visible, setVisible}){
    const [texto, setTexto] = useState("");
    const [enviada, setEnviada] = useState(false);
    const handleTextareaChange = (event) => {
        setTexto(event.target.value);
    };
    useEffect(() => {
        if(!visible){
            setEnviada(false);
        }
    }, [visible]);
    const panelStyle = {
        position: 'fixed',
        right: 0,
        bottom: 0,
        marginBottom: '70px',
        marginRight: '10px',
        zIndex:10,
        padding:'1em',
        backgroundColor:'white',
        borderRadius:'5px',
        boxShadow:'0px 0px 10px rgb(0 0 0 / 20%)',
        width:380
      };
      const enviarSugerencia = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && texto !== ""){
                var url = rutas.API + rutas.EnviarSugerencia;
                const currentUrl = window.location.href;
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                url = url.replace('param1', codigoContribuyente);
                url = url.replace('param2', texto);
                url = url.replace('param3', currentUrl);
                console.log(url)
                
                const response = await fetch(url);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setTexto("");
                    setEnviada(true);
                    //abrir(true)
                }
            }
        } catch (error) {
            console.error(error);
        }
    } 

    return(
        <div>
            <Modal isOpen={visible} closeModal={handleClose} style={{marginTop:200}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:500}} >
                        {enviada ? 
                            <div>
                                <IconoCorrecto/>
                                <p>Sugerencia enviada.</p>
                                <Button 
                                    variant="contained"
                                    onClick={handleClose}
                                >
                                LISTO
                                </Button>
                            </div>
                            :
                            <div>
                                <h1>Sugerencias y comentarios</h1>
                                <textarea
                                    value={texto}
                                    onChange={handleTextareaChange}
                                    rows={7} 
                                    cols={70}
                                    style={{resize:'none'}}
                                    placeholder="Escriba sus sugerencias o comentarios aqui..."
                                />
                                <div>
                                <Button variant="contained" style={{margin:2}} onClick={() => enviarSugerencia()}>Enviar</Button>
                                <Button variant="contained" style={{margin:2}} onClick={handleClose}>Salir</Button>
                                </div>
                            </div> 
                        }
                    </div>
                </div>
            </Modal>
        </div>

    );
    function handleClose(){
        setVisible(false);
    }
}
export default PanelSugerencias; 