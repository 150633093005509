import Header from "../../componentes/header"
import Footer from "../../componentes/footer"
import TablaDeclaracionesPendientes from "../../componentes/tablaDeclaracionesPendientes";
import TablaVencimientosYPagos from "../../componentes/tablaVencimientosYPagos";
import { useState, useEffect } from "react";
import { Tabs, Tab, Box,Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom'
import PanelContribuyente from "../../componentes/panelContribuyente";
import HookModal from '../../componentes/hooks/hookModal';
import ModalPercepciones from '../../componentes/modalPercepciones';
import Sesion from '../../configuracion/controladorSesiones';
import rutas from '../../configuracion/Configuracion';
import ModalCargando from '../../componentes/modalCargando';
import Notificacion from "../../componentes/notificacion";
import ContextoIIBB from "../../componentes/contexts/contextoiibb";
import ModalPrimerUso from "../../componentes/modalPrimerUso";
import PanelIIBB from "../../componentes/paneliibb";
import ModalDeclaracion from "../../componentes/modalDeclaracion";
import TablaPadron from "../../componentes/tablaPadron";

function IngresosBrutos() {
  //recarga de componentes hijo
  const[recarga, setRecarga] = useState(false);
  //recarga de componentes hijo
  const [activeTab, setActiveTab] = useState(0);
  const [existenPercepciones,cambiarExistinciaPercepciones] = useState(false);
  const[estadoModal, abrir, cerrar] = HookModal();
  const [cargando, cambiarEstadoCargando] = useState(false);
  const [codigoCliente, setCodigoCliente] = useState("");
  const[declaracionExitosa,setDeclaracionExitosa] = useState(false);
  const[codigoDeclaracion,setCodigoDeclaracion] = useState("");
  const [codigoImpuesto, setCodigoImpuesto] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  //contexto para actualizacion global

  //contexto para actualizacion global
  useEffect(() => {
    setCodigoImpuesto(searchParams.get('ID'));
  }, []);
  //tab entre detalle de deuda y declaraciones pendientes
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const validarExistenPercepciones = async () =>{
    const sesion =  new Sesion();
    if(sesion.sesionActiva()){
        cambiarEstadoCargando(true)
        setCodigoCliente(sesion.buscarCodigoContribuyente());
        const codigoContribuyente = sesion.buscarCodigoContribuyente();
        const response = await fetch(rutas.API + rutas.ExistenPercepcionesCliente + codigoContribuyente);
        const data = await response.json();
        if(data.Exito){//busqueda exitosa
          cambiarExistinciaPercepciones(data.Datos.Respuesta)
        }else{
          cambiarExistinciaPercepciones(false)
        }
        cambiarEstadoCargando(false)
    }
  }
  useEffect(() => {
    validarExistenPercepciones();
  }, []);
  useEffect(() => {
    validarExistenPercepciones();
  }, [recarga]);
  const [notificacionVisible, setNotificacionVisible] = useState(false);
  const [textoNotificacion, setTextoNotificacion] = useState("");
  return (
      <div>
        <ContextoIIBB.Provider value={validarExistenPercepciones}>
          <ModalPrimerUso/>
          <ModalDeclaracion codigoFactura={codigoDeclaracion} estadoModal={declaracionExitosa} cerrarModal={setDeclaracionExitosa}/>
          <Notificacion 
            mensaje={textoNotificacion} 
            visible={notificacionVisible} 
            funcionAbrir = {abrirNotificacion} 
            funcionCerrar = {cerrarNotificacion}
          ></Notificacion>
          <ModalPercepciones 
            estadoModalPercepciones = {estadoModal}
            cerrarPercepciones = {cerrar}
            codigoFactura = {""} 
            setRecarga={setRecarga}
            recarga={recarga} 
          />
          <ModalCargando estadoModal={cargando}/>
          <Header hideButton={false} hideReturnMenu={false} certificadoIIBB={true} codigoCertificados={codigoCliente} />
          <div style={{paddingTop: 80, paddingBottom:80}}>
            <PanelContribuyente 
              generarMensaje={generarMensaje}
              generarMensajeExito={generarMensajeExito}
            />
            <PanelIIBB codigoCliente={codigoCliente}/>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="DECLARACIONES PENDIENTES" />
              <Tab label="VENCIMIENTOS Y PAGOS" />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <TablaDeclaracionesPendientes setCodigoDeclaracion={setCodigoDeclaracion} setDeclaracionExitosa={setDeclaracionExitosa}/>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
            <TablaPadron codigoImpuesto={codigoImpuesto} esIngresoBruto={true} tipoPadron="XB" ></TablaPadron>
            {/* 
              {existenPercepciones && <div style={{ display: 'flex', justifyContent: 'flex-end',margin: 10}}>
                <Button 
                  variant="contained" 
                  onClick={abrir}
                >
                  PERCEPCIONES DISPONIBLES
                </Button>
              </div>}

              <TablaVencimientosYPagos setRecarga={setRecarga} recarga={recarga} />*/}
            </TabPanel>
          </div>
          <Footer  sugerenciaActiva={true} />
          </ContextoIIBB.Provider>
      </div>
  );
  function generarMensaje(mensaje){
    setTextoNotificacion(mensaje)
    setNotificacionVisible(true)
  }
  function generarMensajeExito(mensaje){
      setTextoNotificacion(mensaje)
      setNotificacionVisible(true)
  }
  function abrirNotificacion(){
      setNotificacionVisible(true)
  }
  function cerrarNotificacion(){
      setNotificacionVisible(false)
  }

}

export default IngresosBrutos

//tab entre detalle de deuda y declaracion
function TabPanel({ children, value, index }) {
    return (
      <div hidden={value !== index}>
        {value === index && (
          <Box p={3} style={{paddingTop: 0}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

