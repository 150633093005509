import { useState, useEffect } from "react";
import Sesion from '../configuracion/controladorSesiones';
import rutas from '../configuracion/Configuracion';
import TotalTabla from './totalTabla'
import {
    Table,
    Box,
    Tooltip,
} from "@mui/material";
import {
    StyledTableContainer,StyledTableHead,ColumnaNumerica,
    StyledTableBody,ColumnaNumericaHeader,
    ColumnaTexto,ColumnaTextoHeader,
    ColumnaTextoExtendida,StyledTableRow
} from './componentesModificados';
import Notificacion from "./notificacion";


function TablaPercepciones({codigoFactura}) {
    //snackbar de notificaciones
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    //snackbar de notificaciones
    const[rows, setRows] = useState([]);
    const anchoColumnas = ['15%', '10%','65%','10%'];
    const [montoFactura, cambiarMontoFactura] = useState(0.0);
    const [montoDescuento, cambiarMontoDescuento] = useState(0.0);
    const [montoAcumuladoPercepciones, setMontoAcumuladoPercepciones] = useState(0.0);
    const [montoFacturaPercepciones, setMontoFacturaPercepciones] = useState(0.0);




    //funciones de consulta a la API
    const buscarPercepcionesContribuyente = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                const response = await fetch(rutas.API + rutas.PercepcionesCliente + codigoContribuyente);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setRows(data.Datos.Instancias)
                    setMontoAcumuladoPercepciones(data.Datos.Datos.monto)
                }else{
                    generarMensaje("Error de conexion")
                }
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    const buscarPercepcionesFactura = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                const response = await fetch(rutas.API + rutas.PercepcionesFactura + codigoFactura);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    cambiarMontoFactura(data.Datos.Datos.saldo);
                    //recortado del exceso
                    if(data.Datos.Datos.monto > data.Datos.Datos.saldo){
                        cambiarMontoDescuento(data.Datos.Datos.saldo);
                    }else{
                        cambiarMontoDescuento(data.Datos.Datos.monto);
                    }
                    setRows(data.Datos.Instancias)
                }else{
                    generarMensaje("Error de conexion")
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    //sensado de estados
    const generarConsulta = async () => {
        if(codigoFactura === ''){//no existe la factura por lo que trae las del cliente
            buscarPercepcionesContribuyente();
        }else{
            buscarPercepcionesFactura();
        }
    }
    const buscarColumnas = async () =>{
        try {
            generarConsulta();
        } catch (error) {
            console.error(error);
        }
    }/*
    useEffect(() => {
        buscarColumnas();
    }, []);*/
    useEffect(() => {
        buscarColumnas()
    }, [codigoFactura]);
    return(
        <div style={{ maxWidth: 1000}}>
            <Notificacion 
                mensaje={textoNotificacion} 
                visible={notificacionVisible} 
                funcionAbrir = {abrirNotificacion} 
                funcionCerrar = {cerrarNotificacion}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', maxHeight: 175, minHeight:100}}>
                <StyledTableContainer >
                    <Table stickyHeader >
                        <StyledTableHead >
                            <StyledTableRow>
                                <ColumnaTextoExtendida style={{ width: anchoColumnas[0] }} >DOCUMENTO</ColumnaTextoExtendida>
                                <ColumnaTextoHeader style={{ width: anchoColumnas[1] }}>FECHA</ColumnaTextoHeader>
                                <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }} >REFERENCIA</ColumnaTextoExtendida>
                                <ColumnaNumericaHeader style={{ width: anchoColumnas[3] }}>MONTO</ColumnaNumericaHeader>
                            </StyledTableRow>
                        </StyledTableHead>
                        <StyledTableBody>
                        {
                            rows.map((row) => {
                                return generarFila(row);
                            })
                        }
                        </StyledTableBody>
                    </Table>
                </StyledTableContainer>
                
            </Box>
            {generarMontoTotal()}
        </div>
    );
    //notificaciones por snackbar
    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function generarMensajeExito(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
    //notificaciones por snackbar
    //PROCESADO DE LAS FILAS
    function generarFila(data){
        return (
            <StyledTableRow>
                
                <ColumnaTextoExtendida style={{ width: anchoColumnas[0] }} component="th" scope="row">
                    {data.documentoCancelante}
                </ColumnaTextoExtendida>
                <ColumnaTexto style={{ width: anchoColumnas[1] }}>
                    {data.fecha}
                </ColumnaTexto>
                <Tooltip title={data.referencia}>
                    <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }}>
                        {data.referencia}
                    </ColumnaTextoExtendida>
                </Tooltip>
                <ColumnaNumerica style={{ width: anchoColumnas[3] }}>{acortarDosDecimales(data.monto)}</ColumnaNumerica>
            </StyledTableRow>
        );
    };
    function acortarDosDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(2)
    }
    function generarMontoTotal(){
        var montoPercepciones = montoAcumuladoPercepciones;


        if(codigoFactura === ''){
            return (
                <TotalTabla 
                    textoMonto = "Total de percepciones disponibles"
                    monto = {montoPercepciones}
                />
            )
        }
        return (
            <TotalTabla 
                textoMonto = "Total factura"
                monto = {montoFactura}
                textoDescuento = "Total de percepciones aplicadas"
                descuento = {montoDescuento}
                textoTotal = "Saldo a pagar"
            />
        )
    }

}
export default TablaPercepciones 