
import { useEffect,useState } from "react";
import { useRef } from "react";
import '../styles/InputStyle.css';
import CurrencyInput from "react-currency-input-field";

function BaseDeclarableInput(props) {
    const{ onChange, indiceEdicion, reset, setReset} = props
    const inputRef = useRef(null); // Definición del ref
    const[largoMaximo, setLargoMaximo] = useState(10);

    function handleFocus(event) {
        event.target.select();
      }
    const validarTeclas  = (e) =>{
        const inputValue = e.target.value;
        const keyCode = e.keyCode || e.which;

        const regex = /[0-9]/;
        if(inputValue.includes(".")){
            setLargoMaximo(12)
        }else{
            if(inputValue.length > 10 && regex.test(e.key)){
                e.preventDefault();
            }
            setLargoMaximo(10);
        }
        if (e.key === 'Enter') {
            inputRef.current.blur();
        }
    }
    const actualizar = (e) =>{
        onChange(inputRef.current.value,indiceEdicion)
    }
    const cambiarFoco = (e) =>{
        const sanitizedValue = inputRef.current.value.replace(/,/g, ""); // Remueve todas las comas
        const parsedValue = parseFloat(sanitizedValue);
        const roundedValue = parsedValue.toFixed(2);
        onChange(roundedValue, indiceEdicion )
        setReset(true);
    }
    useEffect(() => {
        if (inputRef.current ) {
            const parsedValue = parseFloat(0);
            const roundedValue = parsedValue.toFixed(2);
            onChange(roundedValue, indiceEdicion )
            inputRef.current.value = roundedValue
            inputRef.current.focus();
        }
    }, []);
    useEffect(() => {
        if(reset==true){
            
            const sanitizedValue = inputRef.current.value.replace(/,/g, ""); // Remueve todas las comas
            const parsedValue = parseFloat(sanitizedValue);
            const roundedValue = parsedValue.toFixed(2);
            onChange(roundedValue, indiceEdicion )
        }
        
    }, [reset]);
    return (
            <div>
                <CurrencyInput
                    ref={inputRef} 
                    defaultValue={0.00}
                    decimalsLimit={2}
                    groupSeparator=","
                    decimalSeparator="."
                    maxLength={largoMaximo}
                    onKeyDown={validarTeclas}
                    onChange={actualizar}
                    onFocus={handleFocus}
                    onBlur={cambiarFoco}
                    style={{textAlign: 'right'}}
                    allowNegativeValue={false}
                />
            </div>
    );
}
export default BaseDeclarableInput