import TablaPercepciones from '../componentes/tablaPercepciones';
import Modal from '../componentes/modal';
import {
    Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import Sesion from '../configuracion/controladorSesiones';
import rutas from '../configuracion/Configuracion';
import Notificacion from './notificacion';
import Contexto from './contexts/contexto'
import ContextoIIBB from './contexts/contextoiibb';
import React from 'react';

function ModalPercepciones(props) {
    const recargar = React.useContext(Contexto);
    const validarExistenPercepciones = React.useContext(ContextoIIBB);
    const textoAplicando = "Aplicando pago..."
    const textoDescargando = "Procesando..."
    //snackbar de notificaciones
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    //snackbar de notificaciones
    const{estadoModalPercepciones, cerrarPercepciones,codigoFactura} = props
    const [cargando, cambiarEstadoCargando] = useState(false);
    const [modoPercepciones, setModoPercepciones] = useState(true);
    useEffect(() => {
        if(props.recarga){
            console.log("recarga percepciones ",props.recarga)
            props.setRecarga(false)

        }

     }, [props.recarga]);
    useEffect(() => {
       if(codigoFactura === ''){
        setModoPercepciones(true)
       }else{
        setModoPercepciones(false)
       }
    }, [codigoFactura]);
    const pagarFactura = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && codigoFactura != ''){
                cambiarEstadoCargando(true)
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var montos = [];
                var bodyPost = JSON.stringify({"bodyCodigoContribuyente":codigoContribuyente,"bodyCodigoFactura":codigoFactura,"bodyListMPS":montos})
                const response = await fetch(rutas.API + rutas.AplicarPagoConPercepciones + bodyPost);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    generarMensaje("Pago con percepciones correcto.");
                    cerrarPercepciones();
                    recargar();
                    validarExistenPercepciones();
                }else{
                    generarMensaje("Error en la operación, intente nuevamente.");
                    cerrarPercepciones();
                }
                cambiarEstadoCargando(false)
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirPercepciones = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() ){
                cambiarEstadoCargando(true)
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                const response = await fetch(rutas.API + rutas.impresionPercepciones + codigoContribuyente);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    let pdfWindow = window.open("");
                    pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + data.Datos.Datos.base64 +"'></iframe>");
                }else{
                    generarMensaje("Error en la operación, intente nuevamente.");
                }
                cambiarEstadoCargando(false)
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    return(

        <div>
            <Notificacion 
                mensaje={textoNotificacion} 
                visible={notificacionVisible} 
                funcionAbrir = {abrirNotificacion} 
                funcionCerrar = {cerrarNotificacion}
            />
            {cargando && 
            <div className="modal-background" visible>
                <div className="modal-container" >
                    <div className="loader" />
                    {modoPercepciones && <p>{textoDescargando}</p>}
                    {!modoPercepciones && <p>{textoAplicando}</p>}
                </div>
            </div>}
            {!cargando &&
            <Modal isOpen={estadoModalPercepciones} closeModal={cerrarPercepciones} margenSuperior='0px'  >
            {!modoPercepciones && <h1  style={{ marginTop:0}} >Percepciones aplicables</h1>}
            {modoPercepciones && <h1  style={{ marginTop:0}} >Informe de percepciones disponibles</h1>}
            <TablaPercepciones codigoFactura={codigoFactura} />
            {generarBotones()}
            </Modal>}
        </div>

    );
    //notificaciones por snackbar
    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function generarMensajeExito(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
    //notificaciones por snackbar
    function generarBotones(){
        return (

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:20, gap:10}} >
                {!modoPercepciones && <Button variant="contained" onClick={pagarFactura}>APLICAR</Button>}
                {!modoPercepciones && <Button variant="contained" onClick={cerrarPercepciones}>CANCELAR</Button>}
                {modoPercepciones && <Button variant="contained" onClick={imprimirPercepciones} >DESCARGAR</Button>}
                {modoPercepciones && <Button variant="contained" onClick={cerrarPercepciones}>SALIR</Button>}
            </div>
        )
    }
}
export default ModalPercepciones 