import rutas from '../configuracion/Configuracion';
import Sesion from '../configuracion/controladorSesiones';
import { useState, useEffect } from "react";
import { Router, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    InputAdornment,
    IconButton
} from "@mui/material";
import { Link } from "react-router-dom";
import Notificacion from './notificacion';
import IconoOjoAbierto from '../componentes/iconos/iconoOjoAbierto';
import IconoOjoCerrado from '../componentes/iconos/iconoOjoCerrado';

function PanelCambiarContrasenia({funcionGenerarMensaje, funcionGenerarMensajeExito}) {
    const [usuario, setUsuario] = useState("");
    const [contrasenia, setContrasenia] = useState("");
    const [nuevaContrasenia, setNuevaContrasenia] = useState("");
    const [estadoSesion, setEstadoSesion] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    const [mostrarPrimerContrasenia, setMostrarPrimerContrasenia] = useState(false);
    const [mostrarSegundaContrasenia, setMostrarSegundaContrasenia] = useState(false);
    const navigate = useNavigate();
    
    function prepararBody(){
        const salida = `{bodyUsuario: "${usuario}",bodyNuevaContrasenia: "${nuevaContrasenia}",bodyContrasenia: "${contrasenia}"}`
        return salida;
    }
    const cambiarPass = async () =>{
        try {
            const response = await fetch(rutas.API + rutas.CambiarContrasenia + prepararBody());
            const data = await response.json();
            if(data.Exito){//busqueda exitosa
                console.log(data.Datos.Datos.mensaje);
                if(data.Datos.Respuesta){
                    funcionGenerarMensajeExito(data.Datos.Datos.mensaje)
                    navigate("/login")
                }else{
                    funcionGenerarMensaje(data.Datos.Datos.mensaje)
                }
            }else{
                funcionGenerarMensaje(data.Datos.Datos.mensaje)
            }
        } catch (error) {
            console.error(error);
            setEstadoSesion(false);
        }
    }
    const cambiarUsuario = event => {
        setUsuario(event.target.value);
    };
    const cambiarContrasenia = event => {
        setContrasenia(event.target.value);
    };
    const cambiarNuevaContrasenia = event => {
        setNuevaContrasenia(event.target.value);
    };
    const abrirNotificacion = () =>{
        setNotificacionVisible(true)
    }
    const cerrarNotificacion = () =>{
        setNotificacionVisible(false)
    }
    const cambiarVisibilidadPrimerContrasenia  = () => {
        setMostrarPrimerContrasenia(!mostrarPrimerContrasenia);
      };
      const cambiarVisibilidadSegundaContrasenia  = () => {
        setMostrarSegundaContrasenia(!mostrarSegundaContrasenia);
      };
    return(
        <div style={{fontFamily: "Roboto,sans-serif"}}>
            <Notificacion 
                mensaje={textoNotificacion} 
                visible={notificacionVisible} 
                funcionAbrir = {abrirNotificacion} 
                funcionCerrar = {cerrarNotificacion}
            ></Notificacion>
            <img className="logo-login" 
                alt="" 
                height="100" 
                width="150" 
                src="/imagenes/LogoHeader.png" 
                style={{ marginTop:10 }}
            />
            <div className="input-login" style={{alignItems: "center"}} >
                <li>
                    <TextField
                        style={{ marginTop:20 }}
                        label="CUIT/CUIL"
                        type="text"
                        value={usuario}
                        variant="standard"
                        onChange={cambiarUsuario}
                        size="small"
                        fullWidth
                    />
                    <div style={{ position: 'relative'}}>
                        <TextField
                            label="Contraseña"
                            value={contrasenia}
                            variant="standard"
                            onChange={cambiarContrasenia}
                            size="small"
                            type={mostrarPrimerContrasenia ? 'text' : 'password'}
                            fullWidth
                        />
                        <IconButton onClick={cambiarVisibilidadPrimerContrasenia} style={{ position: 'absolute', top: '65%', right: 4, transform: 'translateY(-50%)' }}>
                            {mostrarPrimerContrasenia ? <IconoOjoCerrado style={{ opacity: 0.5 }} /> : <IconoOjoAbierto style={{ opacity: 0.5 }} /> }
                        </IconButton>
                    </div>
                    <div style={{ position: 'relative'}}>
                        <TextField
                            label="Nueva contraseña"
                            value={nuevaContrasenia}
                            variant="standard"
                            onChange={cambiarNuevaContrasenia}
                            size="small"
                            type={mostrarSegundaContrasenia ? 'text' : 'password'}
                            fullWidth
                        />
                        <IconButton onClick={cambiarVisibilidadSegundaContrasenia} style={{ position: 'absolute', top: '65%', right: 4, transform: 'translateY(-50%)' }}>
                            {mostrarSegundaContrasenia ? <IconoOjoCerrado style={{ opacity: 0.5 }} /> : <IconoOjoAbierto style={{ opacity: 0.5 }} /> }
                        </IconButton>
                    </div>
                    <div style={{marginTop:20}}>
                        <Button 
                            style={{display: "block", margin: "auto"}}
                            variant="contained" 
                            onClick={() => {cambiarPass()}}
                        >
                            Cambiar contraseña
                        </Button>
                    </div>
                    <div style={{ marginTop:10,textAlign:'center' }}>
                        <Link 
                            to={"/login"}
                        >
                            ¿Desea iniciar sesión?
                        </Link>
                    </div>
                    <div style={{ marginTop:5 ,textAlign:'center'}}>
                        <Link 
                            to={"/registro"}
                        >
                            Obtener clave web
                        </Link>
                    </div>
                </li>
            </div>
        </div>

    );

}
export default PanelCambiarContrasenia 