import Header from "../../componentes/header";
import Footer from "../../componentes/footer";
import PanelContribuyente from "../../componentes/panelContribuyente";
import Notificacion from "../../componentes/notificacion";
import { useState,useEffect } from "react";
import { useSearchParams } from 'react-router-dom'
import PanelAutomotor from "../../componentes/panelAutomotor";
import ModalPrimerUso from "../../componentes/modalPrimerUso";
import TablaPadron from "../../componentes/tablaPadron";

function FacturasSueltas() {
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    const [nombreImpuesto, setNombreImpuesto] = useState("");
    const [codigoImpuesto, setCodigoImpuesto] = useState("");
    const[recarga, setRecarga] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setCodigoImpuesto(searchParams.get('ID'));
    }, []);
    
    return (
        <div>
            <Header  hideButton={false} hideReturnMenu={false} certificadoContribuyente={true} />
            <div style={{marginTop:64,marginBottom:84}}>
                <ModalPrimerUso/>
                <div style={{paddingTop: 20, paddingBottom:20}}>
                    <PanelContribuyente 
                        generarMensaje={generarMensaje}
                        generarMensajeExito={generarMensajeExito}
                    />
                    <Notificacion 
                        mensaje={textoNotificacion} 
                        visible={notificacionVisible} 
                        funcionAbrir = {abrirNotificacion} 
                        funcionCerrar = {cerrarNotificacion}
                    />
                </div>
                <TablaPadron codigoImpuesto={codigoImpuesto} tipoPadron="UME" esFacturasSueltas={true} ></TablaPadron>
            </div>
          <Footer sugerenciaActiva={true} />
        </div>
    );
    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
      }
    function generarMensajeExito(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
}

export default FacturasSueltas;
