import '../styles/HeaderStyle.css';
import '../styles/FooterStyle.css';
import '../styles/ContainerStyle.css';
import '../styles/SesionStyles.css';
import '../styles/ModalStyle.css'
import Header from "../componentes/header"
import Footer from "../componentes/footer"
import rutas from '../configuracion/Configuracion';
import Sesion from '../configuracion/controladorSesiones';
import { useState,useEffect } from 'react';
import { Router, useNavigate, useSearchParams, Link } from 'react-router-dom';
import {
    TextField,
    Button,
} from "@mui/material";
import ModalCargando from '../componentes/modalCargando';
import Modal from '../componentes/modal';
import IconoAlert from '../componentes/iconos/iconoAlert';

function  LoginRedireccionado(){
    const [usuario, setUsuario] = useState("")
    const [contrasenia, setContrasenia] = useState("")
    const [estadoSesion, setEstadoSesion] = useState(false)
    const [mensajeError, setMensajeError]  = useState("")
    const [mostrarMensajeError, setMostrarMensajeError] = useState(false)
    const [cargando, setCargando] = useState(false)
    const [codigoRedireccion, setCodigoRedireccion] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    
    useEffect(() => {
        setCodigoRedireccion(searchParams.get('codigo'))
    }, [])

    const iniciarSesion = async () =>{
        try {
            setCargando(true)
            var bodyPost = JSON.stringify({
                "bodyUsuario":usuario,
                "bodyContrasenia":contrasenia})
            const response = await fetch(rutas.API + rutas.Login,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: bodyPost
            });
            const data = await response.json();
            if(data.Exito){
                if(data.Datos.Respuesta && data.Datos.Datos.codigoCliente != ''){//busqueda exitosa
                    setMostrarMensajeError(false);
                    //mensaje
                    //generado de sesion
                    const sesion = new Sesion();
                    sesion.desactivarSesion();
                    sesion.generarSesion(
                        data.Datos.Datos.codigoCliente,
                        data.Datos.Datos.nombre,
                        data.Datos.Datos.cuit,
                        data.Datos.Datos.primerUso,
                        data.Datos.Datos.existeEmail
                    );
                    setEstadoSesion(true);
                    switch (codigoRedireccion) {
                        case "0":
                            navigate("/contribuyente/impuestos");
                        break;
                        case "1":
                            navigate("/contribuyente/registro/email");
                        break;
                        default:
                            navigate("/contribuyente/impuestos");
                        break;
                    }
                }else{
                    setEstadoSesion(false);
                    setMensajeError(data.Datos.Datos.mensaje);
                    setMostrarMensajeError(true);
                }
            }
            setCargando(false)
        } catch (error) {
            console.error(error);
            setEstadoSesion(false);
        }
    }
    const cambiarUsuario = event => {
        setUsuario(event.target.value);
    };
    const cambiarContrasenia = event => {
        setContrasenia(event.target.value);
    };
    function cerrarMensaje(){
        setMostrarMensajeError(false);
    }
    const ocultarCargando = () =>{
        setCargando(false)
    }
    const handleContrasenia = (event) => {
        if (event.key === 'Enter') {
          iniciarSesion()
        }
      };
    return(
        <div>
            <Header/>
            <ModalCargando estadoModal={cargando} cerrarModal={ocultarCargando}></ModalCargando> 
            <Modal isOpen={mostrarMensajeError} closeModal={cerrarMensaje} style={{marginTop:200}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:600}}  >
                        <IconoAlert ancho='64px' alto='64px'></IconoAlert>
                        <h2>{mensajeError}</h2>
                        <Button variant="contained" onClick={cerrarMensaje}>Aceptar</Button>
                    </div>
                </div>
            </Modal>
            <div className="form-container" style={{marginTop:80, fontFamily: "Roboto,sans-serif"}}>
                <img className="logo-login" 
                    alt="" 
                    height="100" 
                    width="150" 
                    src="/imagenes/LogoHeader.png" 
                    style={{ marginTop:10 }}
                />
                <div className="input-login" style={{alignItems: "center"}} >
                    <li>
                        <TextField
                            style={{ marginTop:20 }}
                            label="CUIT"
                            type="text"
                            value={usuario}
                            variant="standard"
                            onChange={cambiarUsuario}
                            size="small"
                            fullWidth
                        />
                        <TextField
                            label="Contraseña"
                            type="password"
                            value={contrasenia}
                            variant="standard"
                            onChange={cambiarContrasenia}
                            size="small"
                            onKeyDown={handleContrasenia}
                            fullWidth
                        />
                        <div style={{marginTop:20}}>
                            <Button 
                                style={{display: "block", margin: "auto"}}
                                variant="contained" 
                                onClick={() => {iniciarSesion()}}
                            >
                                Iniciar sesion
                            </Button>
                        </div>
                        <div style={{ marginTop:10 ,textAlign:'center' }}>
                            <Link 
                                to={"/cambio"}
                            >
                                ¿Desea cambiar su contraseña?
                            </Link>
                        </div>
                        <div style={{ marginTop:5 ,textAlign:'center'}}>
                            <Link 
                                to={"/registro"}
                            >
                                Obtener clave web
                            </Link>
                        </div>
                    </li>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default LoginRedireccionado
