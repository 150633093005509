import { useEffect,useState } from "react";
import rutas from '../configuracion/Configuracion';
import { Box,Grid,TextField } from "@mui/material";
import MenuCertificados from "./menuCertificados";


function PanelRural({codigoImpuesto}){
    const [nombre, setNombre] = useState("");
    const [estado, setEstado] =  useState("");
    const divStyle = {
        //border: '1px solid gray',
        //boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        //borderRadius: '5px',
        //padding: '10px',
        margin: '20px',
        //marginBottom: '0px',
        color: '#00000061',
        fontFamily:'Roboto, sans-serif',
        marginTop:40, 
        marginBottom: 40,
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(rutas.API + rutas.BusquedaDatosRural + codigoImpuesto);
                const data = await response.json();
                if(data.Exito){//busqueda exitosa
                    setNombre(data.Datos.Datos.castastro)
                    setEstado(data.Datos.Datos.estado)
                }
              } catch (error) {
                console.error(error);
              }
          }
        fetchData();
    }, [codigoImpuesto]);
    return(
        <div style={divStyle}>
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Rural"
                            id="standard-basic"  
                            variant="standard"
                            value = {nombre} 
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Estado"
                            id="standard-basic"  
                            variant="standard"
                            value = {estado} 
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
export default PanelRural;
