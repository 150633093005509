import Modal from '../componentes/modal';

function ModalCargando({estadoModal, cerrarModal }) {

    return(
        <Modal isOpen={estadoModal} closeModal={cerrarModal} blockedBackground={true} style={{marginTop:200}}>
            <div className="modal-background" visible>
                <div className="modal-container" >
                    <div className="loader" />
                    <p>Cargando...</p>
                </div>
            </div>
        </Modal>
    );
}
export default ModalCargando 