import Footer from "../componentes/footer";
import Header from "../componentes/header";
import { TextField, Button,Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate,Link } from 'react-router-dom';
import ModalCargando from "../componentes/modalCargando";
import rutas from "../configuracion/Configuracion";
import Modal from "../componentes/modal";
import IconoAlert from "../componentes/iconos/iconoAlert";
import ContadorDescendente from "../componentes/contadorDescendente";


function Registro(){
  const navigate = useNavigate();

  const[nombre, setNombre] = useState("")
  const[cuitArmado,setCuitArmado] = useState("")
  const[telefono, setTelefono] = useState("")
  const[email, setEmail] = useState("")

  const[codigoVerificacion, setCodigoVerificacion] = useState("")
  const[tiempoRestante, setTiempoRestante] = useState(0)
  const [cuit,setCuit] = useState("")
  const [cuitValidado,setCuitValidado] = useState(false)
  const [esperandoCodigo,setEsperandoCodigo] = useState(false)
  const [generado,setGenerado] = useState(false)
  const [cargando, setCargando] = useState(false)
  const [mensajeError,setMensajeError] = useState("")
  const[mostrarMensaje, setMostrarMensaje] = useState(false);
  const[permitirValidado, setPermitirValidado] = useState(true);

  var datosCompletos = cuitArmado != '' && email != '' && nombre != '' && email.indexOf('@') !== -1;
  var errorEmail = email === '' || email.indexOf('@') === -1

  const ocultarCargando = () =>{
    setCargando(false)
  }
  const cambiarCuit = event => {
    setCuit(event.target.value);
  };  
  const cambiarCodigoVerificacion = event => {
    setCodigoVerificacion(event.target.value.toUpperCase());
  };  
  const cambiarEmail = event =>{
    setEmail(event.target.value)
  };
  const cambiarTelefono = event =>{
    setTelefono(event.target.value)
  };
  const validarCuit = async () =>{
    try {
      setCargando(true)
      const response = await fetch(rutas.API + rutas.VerificarCUIT + cuit.replace("-",""));
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//busqueda exitosa

        setCuitArmado(data.Datos.Datos.cuit)
        setTelefono(data.Datos.Datos.telefono)
        setEmail(data.Datos.Datos.email)
        setNombre(data.Datos.Datos.nombre)

        if(data.Datos.Datos.enviado){//si ya se envio el mail pasa directo a la validacion del codigo
          setEsperandoCodigo(true)
          setCuitValidado(true)
          setTiempoRestante(data.Datos.Datos.restante)
        }else{
          setEsperandoCodigo(false)
          setCuitValidado(true)
        }
      }else{
        setMensajeError(data.Error)
        setMostrarMensaje(true)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const registrar = async () =>{
    try {
      setCargando(true)
      var url = rutas.API + rutas.GenerarRegistroWeb
      url = url.replace("param1",cuit.replace("-",""))
      url = url.replace("param2",email)
      url = url.replace("param3",telefono)
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//se genero el email correctamente y el registro
        setEsperandoCodigo(true)
        setTiempoRestante(data.Datos.Datos.restante)
      }else{
        setMensajeError(data.Error)
        setMostrarMensaje(true)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const cancelar = async () =>{
    try {
      setCargando(true)
      var url = rutas.API + rutas.CancelarRegistroWeb
      url = url.replace("param1",cuit.replace("-",""))
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//se genero el email correctamente y el registro
        setCuitValidado(false)
        setEsperandoCodigo(false)
        setGenerado(false)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const volverAlIngreso = async () =>{
    setEsperandoCodigo(false)
    setCuitValidado(false)
  }
  const verificarCodigo = async () =>{
    try {
      setCargando(true)
      var url = rutas.API + rutas.ValidarRegistroWeb
      url = url.replace("param1",cuit.replace("-",""))
      url = url.replace("param2",codigoVerificacion)
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//se genero el email correctamente y el registro
        setGenerado(true)
      }else{
        setMensajeError(data.Error)
        setMostrarMensaje(true)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const redirigirLogin = async () =>{
    navigate('/login')
  }
  const panelStyle = {
    marginTop:120,
    fontFamily:'Roboto, sans-serif',
    display: 'block',
    marginBottom:120
  };
  const tituloItem = {
    marginTop: 0,
    marginBottom: 0
  };
  return(
    <div style={{fontFamily: "Roboto,sans-serif"}}>
      <Header />
      <Modal isOpen={mostrarMensaje} closeModal={cerrarMensaje} style={{marginTop:200}}>
        <div className="modal-background" visible>
          <div className="modal-container" style={{minWidth:600}}  >
            {panelError()}
          </div>
        </div>
      </Modal>
      <ModalCargando estadoModal={cargando} cerrarModal={ocultarCargando}></ModalCargando> 
      <div  className="form-container" style={{marginTop:80}}>
        <img className="logo-login" 
          alt="" 
          height="100" 
          width="150" 
          src="/imagenes/LogoHeader.png" 
          style={{ marginTop:0 }}
        />
        
        {!cuitValidado && !esperandoCodigo && !generado && panelValidadoCuit()}
        {cuitValidado && !esperandoCodigo && !generado &&  panelAceptarRegistro()}
        {cuitValidado && esperandoCodigo && !generado &&  panelvalidadoCodigo()}
        {cuitValidado && esperandoCodigo && generado && panelRegistroCorrecto()}

      </div>
      <Footer/>   
    </div>
  )
  //Paneles
  function panelValidadoCuit(){
    return(
      <div>
        <TextField
          style={{ marginTop:10 }}
          label="CUIT/CUIL"
          value={cuit}
          variant="standard"
          onChange={cambiarCuit}
          size="small"
          type="number"
          fullWidth
        />
        <Button 
          style={{display: "block", margin: "auto", marginTop:20}}
          variant="contained" 
          onClick={() => {validarCuit()}}
        >
          Verificar
        </Button>
        <div style={{ marginTop:20,textAlign:'center'}}>
          <Link 
            to={"/login"}
          >
            ¿Desea iniciar sesión?
          </Link>
        </div>
      </div>
    )
  }  
  function panelAceptarRegistro(){
    return(
      <div style={{maxWidth:'300px'}}>
        <div style={{borderRadius: '5px',boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',padding:'1em', marginTop:'20px'}}>
        <TextField
          style={{marginTop:10}}
          label="Nombre"
          value={nombre}
          size="small"
          type="text"
          fullWidth
        />
        <TextField
          style={{marginTop:10}}
          label=""
          value={email}
          size="small"
          type="text"
          onChange={cambiarEmail}
          error={errorEmail}
          helperText={errorEmail ? 'El Correo Electrónico es obligatorio.' : ''}
          fullWidth
        />
        <TextField
          style={{marginTop:10}}
          label="CUIT/CUIL"
          value={cuitArmado}
          size="small"
          type="text"
          fullWidth
        />
        <TextField
          style={{marginTop:10}}
          label="Télefono"
          value={telefono}
          size="small"
          type="text"
          onChange={cambiarTelefono}
          fullWidth
        />
        </div>
          <h3 style={{textAlign:'center'}}>Se le enviará un correo de confirmación, ¿desea continuar?</h3>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button 
                style={{display: "block", margin: "auto"}}
                variant="contained" 
                onClick={() => {volverAlIngreso()}}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button 
                style={{display: "block", margin: "auto"}}
                variant="contained" 
                onClick={() => {registrar()}}
                disabled = {!datosCompletos}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
      </div>
    )
  }
  function panelvalidadoCodigo(){
    return(
      <div style={{maxWidth:'300px', textAlign: 'center', marginTop:'40px'}}>
        <ContadorDescendente segundos={tiempoRestante} notificarFinConteo={notificarFinConteo}></ContadorDescendente>
        <p>Se envió a su correo electrónico un código de verificación, por favor ingréselo.</p>
        <TextField
          style={{ marginTop:10 }}
          label="Codigo"
          type="text"
          value={codigoVerificacion}
          variant="standard"
          onChange={cambiarCodigoVerificacion}
          size="small"
          fullWidth
        />
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button 
            style={{display: "block", margin: "auto", marginTop: '20px'}}
            variant="contained" 
            onClick={() => {cancelar()}}
            >
              Cancelar
            </Button>
          </Grid>
            <Grid item>
            <Button 
              style={{display: "block", margin: "auto", marginTop: '20px'}}
              variant="contained" 
              onClick={() => {verificarCodigo()}}
              disabled={!permitirValidado}
            >
              Validar
            </Button>
          </Grid>
        </Grid>
          

      </div>
    )
  }
  function panelRegistroCorrecto(){
    return(
      <div style={{textAlign: 'center'}}>
        <h1>Bienvenido</h1>
        <p>Su registro fue exitoso, se envió a {email} un correo con su usuario y contraseña.</p>
        <p>Recuerde que estos solo serán válidos para un primer uso.</p>
        <Button 
          style={{display: "block", margin: "auto"}}
          variant="contained" 
          onClick={() => {redirigirLogin()}}
        >
          Iniciar Sesion
        </Button>
      </div>
    )
  }
  function cerrarMensaje(){
    setMostrarMensaje(false);
  }
  function notificarFinConteo(){
    setPermitirValidado(false)
  }
  function panelError(){
    return (
      <div>
        <IconoAlert alto="64px" ancho="64px"></IconoAlert>
          <h2>{mensajeError}</h2>
        <Button variant="contained" onClick={cerrarMensaje} >Aceptar</Button>
      </div>
    )
  }
  //Paneles
}
export default Registro;