const IconoCorrecto = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="128px"
  height="128px"
  viewBox="0 0 256 256"
  {...props}
  >
    <path
      fill="#94d82d"
      strokeMiterlimit={10}
      d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10a9.937 9.937 0 0 0-.541-3.217l-1.62 1.62c.106.515.161 1.05.161 1.597 0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8a7.95 7.95 0 0 1 4.42 1.338l1.432-1.432A9.94 9.94 0 0 0 12 2zm9.293 1.293L11 13.586l-3.293-3.293-1.414 1.414L11 16.414 22.707 4.707z"
      fontFamily="none"
      fontSize="none"
      fontWeight="none"
      style={{
        mixBlendMode: "normal",
      }}
      textAnchor="none"
      transform="scale(10)"
    />
  </svg>
)
export default IconoCorrecto
