const IconoCancelar = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 256 256"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 256V0h256v256h-56.483c24.317-6.459 42.372-28.723 42.372-55 0-31.289-25.6-56.889-56.889-56.889s-56.889 25.6-56.889 56.889c0 26.277 18.055 48.541 42.372 55z"
        />
      </clipPath>
    </defs>
    <g
      fill="none"
      strokeMiterlimit={10}
      clipPath="url(#a)"
      fontFamily="none"
      fontSize="none"
      fontWeight="none"

      style={{
        mixBlendMode: "normal",
      }}
      textAnchor="none"
    >
      <path
        fill="#e50707"
        d="M42.667 42.667c-11.787 0-21.334 9.546-21.334 21.333v128c0 11.787 9.547 21.333 21.334 21.333h170.666c11.787 0 21.334-9.546 21.334-21.333V64c0-11.787-9.547-21.333-21.334-21.333zm0 21.333h170.666v21.333H42.667zm0 53.333h170.666V192H42.667z"
      />
    </g>
    <path
      fill="#fb0101"
      strokeMiterlimit={10}
      d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm2.8 7.4L7.4 8.8 6 7.4 4.6 8.8 3.2 7.4 4.6 6 3.2 4.6l1.4-1.4L6 4.6l1.4-1.4 1.4 1.4L7.4 6z"
      fontFamily="none"
      fontSize="none"
      fontWeight="none"
      style={{
        mixBlendMode: "normal",
      }}
      textAnchor="none"
      transform="translate(142.333 158.333) scale(7.11111)"
    />
  </svg>
)
export default IconoCancelar
