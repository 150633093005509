
 const Modal = ({isOpen, closeModal, children, blockedBackground = false, margenSuperior='200px', modalBase=false}) =>{
    const handleModalDialog = (e) =>{//evita que se cierre el modal al presionar el contenido del modal
        e.stopPropagation();
    } 
    return(
        <div className={`modal ${isOpen && 'modal-abierto'}`} onClick={validarBloqueo}>
            {!modalBase ?
                <div className='modal-panel' onClick={handleModalDialog} style={{marginTop:margenSuperior}} >
                    {children}
                </div>
                :
                <div className='modal-panel-base' onClick={handleModalDialog} style={{marginTop:margenSuperior}} >
                    {children}
                </div>
            }

        </div>
    )
    function validarBloqueo(){
        if(!blockedBackground){
            closeModal()
        }
    }
 }
 export default Modal;