import { Link } from "react-router-dom";
import Sesion from '../configuracion/controladorSesiones';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from "react";
import {
    Button,
    Tooltip
} from "@mui/material";
import PanelSugerencias from "./panelSugerencias";
import MenuCertificados from "./menuCertificados";

function Header({
    hideButton=true, 
    hideReturnMenu=true, 
    codigoCertificados = "",
    certificadoContribuyente=false,
    certificadoIIBB=false, 
    certificadoHabilitacion = false, 
    certificadoAutomotor = false,
    certificadoUrbano = false,
    certificadoRural = false,
    certificadoPlanPago = false,
}) {
    const[sugerenciasVisibles, setSugerenciasVisibles] = useState(false);

    const navigate = useNavigate();
    return(
        <div className="header">
            <div className="header-logo">
                <Link to={"/login"}>
                    <img src="/imagenes/LogoHeader.png" height="60" width="90"/>
                </Link>
                
            </div>
            <PanelSugerencias visible={sugerenciasVisibles} setVisible={setSugerenciasVisibles}/>
            <div style={{ paddingRight:20, maxHeight: 60, display: 'flex'}}>
                {!hideReturnMenu && <div style={{marginLeft:15}}>
                    <Button variant="contained" onClick={volverMenuImpuestos}>Inicio</Button>
                </div>}
                {///certfificados contribuyente
                certificadoContribuyente && <div style={{marginLeft:15}}>
                    <MenuCertificados esContribuyente={true} codigo={codigoCertificados}/>
                </div>}
                {///certfificados automotor
                !hideReturnMenu && certificadoAutomotor && <div style={{marginLeft:15}}>
                    <MenuCertificados esAutomotor={true} codigo={codigoCertificados}/>
                </div>}
                {///certfificados iibb
                !hideReturnMenu && certificadoIIBB && <div style={{marginLeft:15}}>
                    <MenuCertificados esIIBB={true} codigo={codigoCertificados}/>
                </div>}
                {///certfificados habilitacion
                !hideReturnMenu && certificadoHabilitacion && <div style={{marginLeft:15}}>
                    <MenuCertificados esHabilitacion={true} codigo={codigoCertificados}/>
                </div>}
                {///certfificados urbano
                !hideReturnMenu && certificadoUrbano && <div style={{marginLeft:15}}>
                    <MenuCertificados esUrbano={true} codigo={codigoCertificados}/>
                </div>}
                {///certfificados rural
                !hideReturnMenu && certificadoRural && <div style={{marginLeft:15}}>
                    <MenuCertificados esRural={true} codigo={codigoCertificados}/>
                </div>}

                {!hideButton && <div style={{marginLeft:15}}>
                    <Button variant="contained" onClick={handleContacto}>Contacto</Button>
                </div>}
                
                {!hideButton && <div style={{marginLeft:15}}>
                    <Button variant="contained" onClick={cerrarSesion}>Cerrar sesión</Button>
                </div>}

            </div>

        </div>
    );
    function cerrarSesion(){
        const sesion = new Sesion();
        sesion.desactivarSesion();
        navigate('/login')
        //navigate('/test2023/login')
    }    
    function volverMenuImpuestos(){
        navigate('/contribuyente/impuestos')
    }
    function handleSugerencia(){
        setSugerenciasVisibles(true);
    }
    function handleContacto(){
        navigate('/contacto')
    }
}
export default Header 