import Modal from "./modal";
import { useState,useEffect } from "react";
import IconoCorrecto from "./iconos/iconoCorrecto";
import {
    Table,MenuItem,Select,Input,Button,TextField,Box,Grid,Checkbox
} from "@mui/material";
import {
    ColumnaNumerica,
    ColumnaTexto,
    ColumnaTextoExtendida,StyledTableRow
} from './componentesModificados';
import Sesion from "../configuracion/controladorSesiones";
import rutas from "../configuracion/Configuracion";
import ModalPrevisualizarImpresion from "./modalPrevisualizarImpresion";
import HookModal from "./hooks/hookModal";
import axios from 'axios'; // Importa la librería axios

function ModalPlanPago({estadoModal, cerrarModal, listaMovimientos=[],actualizar,tipoPadron=""}){
    const [cargando, setCargando] = useState(false);
    const [correcto, setCorrecto] = useState(false);
    const [rows, setRows] = useState([]);
    const anchoColumnas = ['10%','10%','10%','70%'];
    const [cuotas, setCuotas] = useState(9);
    const [montoCuota, setMontoCuota] = useState(0);
    const [montoTotal, setMontoTotal] = useState(0);
    const [montoNeto, setMontoNeto] = useState(0);
    const [interesFinaciacion, setInteresFinaciacion] = useState(0);
    const [interesMora, setInteresMora] = useState(0);
    const [terminosYCondiciones, setTerminosYCondiciones] = useState(false);
    const [contenidoTerminosYCondiciones, setContenidoTerminosYCondiciones] = useState("");
    const[modalTerminos, abrirModalTerminos, cerrarModalTerminos] = HookModal();
    const formatoInput = {border: '1px solid #00000033', width:150,textAlign:'right',borderRadius:3,margin:7,padding:6, minWidth:120};
    const formatoInputReducido = {border: '1px solid #00000033',textAlign:'right',borderRadius:3,margin:7,padding:6};

    let tagsCuotas = [3,6,9];
    const estiloHipervinculo = {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
    };

    const handleCuota = (event) => {
        setCuotas(event.target.value);
    };
    useEffect(() => {
        if(correcto){
            setCorrecto(false);
        }
    }, []);
    useEffect(() => {
        if(estadoModal){
            setCorrecto(false);
            buscarPreview();
        }
    }, [estadoModal]);
    useEffect(() => {
        if(listaMovimientos.length>0){
            buscarPreview();
        }
    }, [cuotas]);
    const buscarPreview = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                setCargando(true)
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                if(codigoContribuyente != null){
                    var bodyPost = JSON.stringify({
                        "bodyCodigoContribuyente":codigoContribuyente,
                        "bodyCodigoRecibo":"",
                        "bodyListOriginantes":listaMovimientos,
                        "bodyCuotas":cuotas,
                        "tipoPadron":tipoPadron,
                    });
                    const headers = {
                        'Content-Type': 'application/json' // Establece el Content-Type como JSON
                    };
                    var rutaPost = rutas.APIHTTPS + rutas.ProxyPlanPagoPreview;
                    const response = await axios.post(`${rutaPost}`, bodyPost, { headers })
                    .then(response =>{
                        const data = response.data;
                        if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                            setRows(data.Datos.Instancias);
                            setMontoCuota(data.Datos.Datos.montoCuota);
                            setMontoNeto(data.Datos.Datos.montoNeto);
                            setMontoTotal(data.Datos.Datos.montoTotal);
                            setInteresFinaciacion(data.Datos.Datos.interesFinanciacion);
                            setInteresMora(data.Datos.Datos.interesMora);
                            setContenidoTerminosYCondiciones("data:application/pdf;base64,"+data.Datos.Datos.base64)
                            setTerminosYCondiciones(false);
                        }else{
                            cerrarModal()
                        }
                    })
                    .catch(error =>{
                        cerrarModal()
                    });

                }
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
            setCargando(false);
            cerrarModal()
        }
    }
    const generarPlanPago = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                setCargando(true)
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var bodyPost = JSON.stringify({
                    "bodyCodigoContribuyente":codigoContribuyente,
                    "bodyCodigoRecibo":"",
                    "bodyListOriginantes":listaMovimientos,
                    "bodyCuotas":cuotas,
                    "tipoPadron":tipoPadron,
                });
                const headers = {
                    'Content-Type': 'application/json' // Establece el Content-Type como JSON
                };
                var rutaPost = rutas.APIHTTPS + rutas.ProxyPlanPagoGenerar;
                const response = await axios.post(`${rutaPost}`, bodyPost, { headers });
                const data = await response.data;
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setCorrecto(true);
                }else{
                    cerrarModal();
                    actualizar();
                }
                /*
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var bodyPost = JSON.stringify({
                    "bodyCodigoContribuyente":codigoContribuyente,
                    "bodyCodigoRecibo":"",
                    "bodyListOriginantes":listaMovimientos,
                    "bodyCuotas":cuotas,
                    "tipoPadron":tipoPadron,
                });
                const response = await fetch(rutas.API + rutas.GenerarPlanPago + bodyPost);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setCorrecto(true);
                }else{
                    cerrarModal();
                    actualizar();
                }*/
            }
        } catch (error) {
            console.error(error);
            setCargando(false);
        }
    }
    return(
        <Modal isOpen={estadoModal} closeModal={cerrarModal} modalBase={true} margenSuperior="200px">
            {generarPanel()}
        </Modal>
    );
    //renderizado distintas interfaces
    function generarPanel(){
        if(correcto){
            return interfaceGeneracionCorrecta();
        }else{
            return interfaceGeneracionPlan()
        }
    }
    function interfaceGeneracionCorrecta(){
        return (            
            <div className="modal-background" visible>
                <div className="modal-container" style={{minWidth:100}} >
                    <IconoCorrecto/>
                    <p>El plan de pago se generó correctamente.</p>
                    <Button 
                        variant="contained"
                        onClick={cerrarPlan}
                    >
                    LISTO
                    </Button>
                </div>
            </div>
        )
    }
    function interfaceCargando(){
        return(
            <div className="modal-background" visible>
                <div className="modal-container" >
                    <div className="loader" />
                    <p>Cargando...</p>
                </div>
            </div>
        )
    }
    function interfaceGeneracionPlan(){
        return(
            <div>
                {cargando && <div>
                    <div className="modal-background" visible>
                        <div className="modal-container" >
                            <div className="loader" />
                            <p>Cargando...</p>
                        </div>
                    </div>
                </div>}
                <div>
                    <ModalPrevisualizarImpresion 
                        estadoModal={modalTerminos} 
                        cerrarModal={cerrarModalTerminos} 
                        contenidoPDF={contenidoTerminosYCondiciones}
                        funcionAceptar={aceptarTerminosYCondiciones}
                    />
                    <h1 style={{marginLeft:30}}>Plan de pago</h1>

                    <Box sx={{ flexGrow: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={1.5}>
                                <div style={{textAlign:'right', marginRight:10, paddingTop:20}} >
                                    <Select
                                        value={cuotas}
                                        label="Cuotas"
                                        onChange={handleCuota}
                                        style={{width:120}}
                                        size="small"
                                        >
                                        {tagsCuotas.map(item => {
                                            return <MenuItem value={item}>{item} cuotas</MenuItem>
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>
                                    <div style={{textAlign:'right', marginRight:10}} >
                                        Neto 
                                    </div>
                                    <div style={ formatoInput}>
                                        {acortarDosDecimales(montoNeto)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>
                                    <div style={{textAlign:'right', marginRight:10}} >
                                        Interés Mora 
                                    </div>
                                    <div style={formatoInput}>
                                        {acortarDosDecimales(interesMora)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>
                                    <div style={{textAlign:'right', marginRight:10}} >
                                        Interés Financiación  
                                    </div>
                                    <div style={formatoInput}>
                                        {acortarDosDecimales(interesFinaciacion)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>
                                    <div style={{textAlign:'right', marginRight:10}} >
                                        Total
                                    </div>
                                    <div style={formatoInput}>
                                        {acortarDosDecimales(montoTotal)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div style = {{width:'auto', minWidth:100}}>
                                    <div style={{textAlign:'right', marginRight:10}} >
                                        Cuota
                                    </div>
                                    <div style={formatoInputReducido}>
                                        {acortarDosDecimales(montoCuota)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Checkbox  checked={terminosYCondiciones} onChange={abrirTerminosYCondiciones} ></Checkbox>
                    <a>Acepto el <span style={estiloHipervinculo} onClick={abrirTerminosYCondiciones} >convenio de pago.</span> </a>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap:10}} >
                        <Button 
                            variant="contained"
                            onClick={cerrarModal}
                            >
                            CANCELAR
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={generarPlanPago}
                            disabled={!terminosYCondiciones}
                            >
                            ACEPTAR
                        </Button>
                    </div>
                </div>
            </div>

        )
    }
    //renderizado distintas interfaces
    function generarFila(data){
        return (
            <StyledTableRow>
                <ColumnaTexto style={{ width: anchoColumnas[0] }} component="th" scope="row">
                    {data.numeroCuota}
                </ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[1] }} component="th" scope="row">
                    {data.fechaVencimiento}
                </ColumnaTexto>
                <ColumnaNumerica style={{ width: anchoColumnas[2] }}>
                    {acortarDosDecimales(data.monto)}
                </ColumnaNumerica>
                <ColumnaTextoExtendida style={{ width: anchoColumnas[3] }} component="th" scope="row">
                    {data.referencia}
                </ColumnaTextoExtendida>
            </StyledTableRow>
        );
    };
    function acortarDosDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(2)
    }
    function cerrarPlan(){
        setCargando(true);
        cerrarModal();
        if(correcto){
            actualizar();
            
        }
    }
    function abrirTerminosYCondiciones(){
        if(terminosYCondiciones){
            setTerminosYCondiciones(false);
        }else{
            setTerminosYCondiciones(false);
            abrirModalTerminos();
        }
        //setTerminosYCondiciones(!terminosYCondiciones);
    } 
    function aceptarTerminosYCondiciones(){
        setTerminosYCondiciones(true);
        cerrarModalTerminos();
    } 
    function abrirPestaniaTerminosYCondiciones(){
        window.open('/contratos/planpago', '_blank', 'noreferrer');
    }

}
export default ModalPlanPago;