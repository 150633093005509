import { Menu,MenuItem,Button } from "@mui/material";
import React, { useState } from "react";
import IconoDropDown from "./iconos/iconoDropDown";
import rutas from "../configuracion/Configuracion";
import ModalCargando from "./modalCargando";
import Modal from "./modal";
import IconoAlert from "./iconos/iconoAlert";
import { useEffect } from "react";


function MenuCertificados({
    codigo,
    esContribuyente=false,
    esIIBB=false, 
    esHabilitacion = false, 
    esAutomotor = false,
    esUrbano = false,
    esRural = false,
    esPlanPago = false,
  }){
  const [modalCargando, setModalCargando] = useState(false);
  const [mensajeLibreDeuda, setMensajeLibreDeuda] = useState("");
  const[mostrarMensaje, setMostrarMensaje] = useState(false);
  const[libreDeudaDeshabilitado, setLibreDeudaDeshabilitado] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if(codigo != null && codigo !== ''){
      if(esContribuyente){
        validarLibreDeudaHabilitado("CL",codigo.replace(/-/g, ""));
      }
      if(esAutomotor){
        validarLibreDeudaHabilitado("XA",codigo.replace(/-/g, ""));
      }    
      if(esIIBB){
        validarLibreDeudaHabilitado("XB",codigo.replace(/-/g, ""));
      }    
      if(esHabilitacion){
        validarLibreDeudaHabilitado("XH",codigo.replace(/-/g, ""));
      }    
      if(esUrbano){
        validarLibreDeudaHabilitado("XU",codigo.replace(/-/g, ""));
      }    
      if(esRural){
        validarLibreDeudaHabilitado("XR",codigo.replace(/-/g, ""));
      }
    }

  }, [codigo]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const validarLibreDeudaHabilitado = async (tipo, codigo)=>{
    try {
      setLibreDeudaDeshabilitado(true)
      setModalCargando(true);
      var url = rutas.API + rutas.ValidarLibreDeudaImprimible;
      url = url.replace("param2",tipo);
      url = url.replace("param1",codigo);
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito){
        if(data.Datos.Respuesta){//busqueda exitosa
          setModalCargando(false);
          setLibreDeudaDeshabilitado(false)
        }else{
          handleClose();
          setModalCargando(false);
        }
      }else{
        handleClose();
      }
      setModalCargando(false);
    } catch (error) {
      console.error(error);
      setModalCargando(false);
    }
  }
  const validarLibreDeuda = async (tipo, codigo)=>{
    try {
      setModalCargando(true);
      var url = rutas.API + rutas.ValidarLibreDeudaImprimible;
      url = url.replace("param2",tipo);
      url = url.replace("param1",codigo);
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito){
        if(data.Datos.Respuesta){//busqueda exitosa
          setModalCargando(false);
          window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo='+tipo+'&libredeuda=1', '_blank', 'noreferrer');
        }else{
          handleClose();
          setMensajeLibreDeuda(data.Datos.Datos.mensaje);
          setMostrarMensaje(true);
          setModalCargando(false);
        }
      }else{
        setMostrarMensaje(false);
        setModalCargando(false);
        handleClose();
      }

    } catch (error) {
      console.error(error);
      setModalCargando(false);

    }
  }
  return (
    <div>
        <Modal isOpen={mostrarMensaje} closeModal={cerrarMensaje} style={{fontFamily:'Roboto, sans-serif'}}>
          <div className="modal-background" visible>
            <div className="modal-container" style={{minWidth:800}}  >
                <IconoAlert></IconoAlert>  
                <h3>{mensajeLibreDeuda}</h3>
                <Button variant="contained" onClick={cancelar} style={{margin:5}}>Salir</Button>
            </div>
          </div>
        </Modal>
        <ModalCargando estadoModal={modalCargando}/>
        <div>
        <div 
          onClick={handleClick}
          title="Click para ver opciones."
        >
          <Button variant="contained" endIcon={<IconoDropDown />} >Certificados</Button>
        </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {esContribuyente && <div>
              {libreDeudaDeshabilitado ? 
              <MenuItem > <div style={{color:'grey'}} >Libre de Deuda (Adeuda pagos)</div> </MenuItem>
              :  
              <MenuItem onClick={imprimirLibreDeuda}>Libre de Deuda</MenuItem>}
            </div>
            }
            {esIIBB &&  
              <div>
                {libreDeudaDeshabilitado ? 
                  <MenuItem > <div style={{color:'grey'}} >Libre de Deuda (Adeuda pagos)</div> </MenuItem>
                  :  
                  <MenuItem onClick={imprimirLibreDeuda}>Libre de Deuda</MenuItem>
                }
                <MenuItem onClick={imprimirInscripcion}>Inscripción</MenuItem>
              </div>
            }
            {esHabilitacion &&  
              <div>
                {libreDeudaDeshabilitado ? 
                  <MenuItem > <div style={{color:'grey'}} >Libre de Deuda (Adeuda pagos)</div> </MenuItem>
                  :  
                  <MenuItem onClick={imprimirLibreDeuda}>Libre de Deuda</MenuItem>
                }
                {/*<MenuItem onClick={imprimirHabilitacionComercial}>Habilitación comercial</MenuItem>*/}
              </div>
            }
            {esAutomotor &&  
              <div>
                {libreDeudaDeshabilitado ? 
                  <MenuItem > <div style={{color:'grey'}} >Libre de Deuda (Adeuda pagos)</div> </MenuItem>
                  :  
                  <MenuItem onClick={imprimirLibreDeuda}>Libre de Deuda</MenuItem>
                }
                <MenuItem onClick={imprimirInscripcion}>Inscripción</MenuItem>
                <MenuItem onClick={imprimirValuacionFiscal} >Valuación Fiscal</MenuItem>
              </div>
            }
            {esUrbano &&  
              <div>
                {libreDeudaDeshabilitado ? 
                  <MenuItem > <div style={{color:'grey'}} >Libre de Deuda (Adeuda pagos)</div> </MenuItem>
                  :  
                  <MenuItem onClick={imprimirLibreDeuda}>Libre de Deuda</MenuItem>
                }
                <MenuItem onClick={imprimirValuacionFiscal} >Valuación Fiscal</MenuItem>
              </div>
            }
            {esRural &&  
              <div>
                {libreDeudaDeshabilitado ? 
                  <MenuItem > <div style={{color:'grey'}} >Libre de Deuda (Adeuda pagos)</div> </MenuItem>
                  :  
                  <MenuItem onClick={imprimirLibreDeuda}>Libre de Deuda</MenuItem>
                }
                <MenuItem onClick={imprimirValuacionFiscal} >Valuación Fiscal</MenuItem>
              </div>
            }
            {esPlanPago &&  
              <div>
                <MenuItem onClick={imprimirConvenioPlanPago} >Convenio Plan de Pago</MenuItem>
              </div>
            }
        </Menu>
      </div>
    </div>
    
  );
  function cancelar(){
    cerrarMensaje();
  }
  function cerrarMensaje(){
    setMostrarMensaje(false);
  }
  function imprimirConvenioPlanPago(){ 
    if(esPlanPago && esAutomotor){
      window.open('/contratos/planpago?codigo='+codigo+'&padron=XA', '_blank', 'noreferrer');
    }
    if(esPlanPago && esHabilitacion){
      window.open('/contratos/planpago?codigo='+codigo+'&padron=XH', '_blank', 'noreferrer');
    }
    if(esPlanPago && esIIBB){
      window.open('/contratos/planpago?codigo='+codigo+'&padron=XB', '_blank', 'noreferrer');
    }
    if(esPlanPago && esRural){
      window.open('/contratos/planpago?codigo='+codigo+'&padron=XR', '_blank', 'noreferrer');
    }
    if(esPlanPago && esUrbano){
      window.open('/contratos/planpago?codigo='+codigo+'&padron=XU', '_blank', 'noreferrer');
    }
  }
  function imprimirLibreDeuda(){
    if(esContribuyente){
      validarLibreDeuda("CL",codigo.replace(/-/g, ""));
      //window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=CL&libredeuda=1', '_blank', 'noreferrer');
    }
    if(esAutomotor){
      validarLibreDeuda("XA",codigo.replace(/-/g, ""));
      //window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XA&libredeuda=1', '_blank', 'noreferrer');
    }    
    if(esIIBB){
      validarLibreDeuda("XB",codigo.replace(/-/g, ""));
      //window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XB&libredeuda=1', '_blank', 'noreferrer');
    }    
    if(esHabilitacion){
      validarLibreDeuda("XH",codigo.replace(/-/g, ""));
      //window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XH&libredeuda=1', '_blank', 'noreferrer');
    }    
    if(esUrbano){
      validarLibreDeuda("XU",codigo.replace(/-/g, ""));
      //window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XU&libredeuda=1', '_blank', 'noreferrer');
    }    
    if(esRural){
      validarLibreDeuda("XR",codigo.replace(/-/g, ""));
      //window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XR&libredeuda=1', '_blank', 'noreferrer');
    }
  }
  function imprimirValuacionFiscal(){
    if(esAutomotor){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XA&valuacionfiscal=1', '_blank', 'noreferrer');
    }     
    if(esUrbano){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XU&valuacionfiscal=1', '_blank', 'noreferrer');
    }    
    if(esRural){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XR&valuacionfiscal=1', '_blank', 'noreferrer');
    }
  }
  function imprimirInscripcion(){
    if(esAutomotor){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XA&inscripcion=1', '_blank', 'noreferrer');
    }     
    if(esIIBB){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XB&inscripcion=1', '_blank', 'noreferrer');
    }    
  }
  function imprimirHabilitacionComercial(){
    if(esHabilitacion){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XH&habilitacioncomercial=1', '_blank', 'noreferrer');
    }
  }
  function imprimirBaja(){
    if(esAutomotor){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XA&baja=1', '_blank', 'noreferrer');
    }     
    if(esIIBB){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XB&baja=1', '_blank', 'noreferrer');
    }    
    if(esHabilitacion){
      window.open('impresion?cod='+codigo.replace(/-/g, "")+'&tipo=XH&baja=1', '_blank', 'noreferrer');
    } 
  }
}
export default MenuCertificados;