import '../styles/HeaderStyle.css';
import '../styles/FooterStyle.css';
import '../styles/ContainerStyle.css';
import '../styles/SesionStyles.css';
import '../styles/ModalStyle.css'
import Header from "../componentes/header"
import Footer from "../componentes/footer"
import PanelCambiarContrasenia from '../componentes/panelCambiarContrasenia';
import Notificacion from '../componentes/notificacion';
import { useState, useEffect } from "react";

function CambiarContrasenia() {
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");

    return(
        <div>
            <Header/>
            <div className="form-container" style={{marginTop:80}}>
                <PanelCambiarContrasenia funcionGenerarMensaje={generarMensaje} funcionGenerarMensajeExito={generarMensajeExito}/>
            </div>
            <Notificacion 
                mensaje={textoNotificacion} 
                visible={notificacionVisible} 
                funcionAbrir = {abrirNotificacion} 
                funcionCerrar = {cerrarNotificacion}
            ></Notificacion>
            <Footer/>
        </div>
    );

    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function generarMensajeExito(mensaje){
        console.log(mensaje)
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
}
export default CambiarContrasenia 