import ModalCargando from './modalCargando';
import {
    Table,
    Box,
    Button,
    Tooltip,
    Icon,
    TextField,
    fabClasses,
} from "@mui/material";
import {
    StyledTableContainer,StyledTableHead,ColumnaNumerica,
    StyledTableBody,ColumnaNumericaHeader,
    ColumnaTexto,ColumnaTextoHeader,ColumnaTextoExtendida,
    ColumnaBotones,ColumnaIconos,StyledTableRow
} from './componentesModificados';
import React from 'react';
//importacion de iconos
import IconoCheck from './iconos/iconoCheck';
import { useState, useEffect, useContext } from "react";
import rutas from '../configuracion/Configuracion'
import Sesion from '../configuracion/controladorSesiones';
import HookModal from '../componentes/hooks/hookModal';
import ModalDeclaracionExitosa from './modalDeclaracionCorrecta';
import BaseDeclarableInput from './currencyInput';
import Notificacion from './notificacion';
import ContextoInputAmount from './contexts/contextoInputAmount';
import { wait } from '@testing-library/user-event/dist/utils';
import { Await } from 'react-router-dom';


export default function TablaDeclaracionesPendientes({setCodigoDeclaracion, setDeclaracionExitosa}) {

    const[estadoModal, abrir, cerrar] = HookModal();
    const [rows, setRows] = useState([]);
    const [seleccionParaPago, cambiarEstadoDePago] = useState(false);
    const [cargando, cambiarEstadoCargando] = useState(false);
    const anchoColumnas = ['10%', '10%','50%','10%','10%','10%'];
    const [precalculado, cambiarEstadoDeclaracion]  = useState(false);
    //snackbar de notificaciones
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    const [reset, setReset] = useState(false)
    const [valorTestigo, setValorTestigo] = useState("");
    useEffect(() => {
        if(reset==true){
            console.log("padre", reset)

            setReset(false)
        }

    }, [reset]);
    //snackbar de notificaciones

    //const[actualizarInput, setActualizarInput] = useContext(UpdateContext);

    //declaracion
    const declararBase = async () =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                cambiarEstadoCargando(true);
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var montos = await listaMontos();
                var bodyPost = JSON.stringify({"bodyCodigoContribuyente":codigoContribuyente,"bodyCodigoFactura":"","bodyListMPS":montos})
                const response = await fetch(rutas.API + rutas.DeclaracionIIBB + bodyPost);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    generarMensajeExito("Declaracion exitosa.")
                    setCodigoDeclaracion(data.Datos.Datos.codigoFactura);
                    setDeclaracionExitosa(true);
                    setRows([])
                    buscarDatos()
                    
                }else{
                    generarMensaje("Error en la declaración.")
                }
                cambiarEstadoCargando(false);
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    //ejecucion al actualizarse los datos de rows
    const calcularBase = async (estado) =>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var montos = await listaMontos(estado.codigoMPS);
                var bodyPost = JSON.stringify({"bodyCodigoContribuyente":codigoContribuyente,"bodyCodigoFactura":"","bodyListMPS":montos})
                const response = await fetch(rutas.API + rutas.PreCalculoMonto + bodyPost);
                const data = await response.json();
                if(data.Exito){//busqueda exitosa
                    //
                    setRows(data.Datos.Instancias)
                    
                }
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    const listaMontos =  (codigoFila) =>{

        ///validar recalculado de base
        const salida = rows.map(item => {
            if(
                (item.declaracionHabilitada )
            ) {
                var baseParseada = parseFloat(item.baseImponible).toFixed(2);
                if(baseParseada == NaN){
                    baseParseada = parseFloat(0).toFixed(2);
                }
                
              return {bodyIDMPS: item.codigoMPS, bodyMonto: baseParseada};
            }
            return null;
        }).filter(Boolean);
        return salida;
    }
    const buscarDatos = async () =>{
        const sesion =  new Sesion();
        if(sesion.sesionActiva()){
            cambiarEstadoCargando(true);
            const codigoContribuyente = sesion.buscarCodigoContribuyente();
            try {
                const response = await fetch(rutas.API + rutas.DeclaracionPendiente + codigoContribuyente);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setRows(data.Datos.Instancias)
                }else{
                    generarMensaje("Error de conexion.")
                }
            } catch (error) {
                generarMensaje("Error de conexion.")
                console.error(error);
            }
            cambiarEstadoCargando(false)
        }
    }
    const validarEsFacturable = async () =>{
        var facturable = true;
        var cantidadHabilitados=0;
        rows.forEach(element => {
            //si al menos 1 de las actividades declarablesno tiene su base declarada no permite el pago
            if(element.declaracionHabilitada){
                cantidadHabilitados++;
                if(!element.baseCalculada){
                    facturable=false;
                }
            }
        });
        if(rows.count == 0 || cantidadHabilitados == 0){
            facturable = false;
        }
        cambiarEstadoDeclaracion(facturable);
    }
    useEffect(() => {
        cambiarEstadoDeclaracion(false);
        buscarDatos()
        validarEsFacturable()
    }, []);
    useEffect(() => {
        validarEsFacturable()
    },[rows])
    return (
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            {/*modal de decalracion exitosa*/}
            <ModalDeclaracionExitosa 
                estadoModal = {estadoModal}
                cerrarModal = {cerrar}
            />
            {/*modal de carga*/}
            <ModalCargando estadoModal={cargando}/>
            {/*fomulario*/}
            <StyledTableContainer >
                <Table stickyHeader >
                    <StyledTableHead >
                        <StyledTableRow>
                            <ColumnaTextoHeader style={{ width: anchoColumnas[0] }} >PERIODO</ColumnaTextoHeader>
                            <ColumnaTextoHeader style={{ width: anchoColumnas[1] }}>VENCIMIENTO</ColumnaTextoHeader>
                            <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }} >ACTIVIDAD</ColumnaTextoExtendida>
                            <ColumnaNumericaHeader style={{ width: anchoColumnas[3],minWidth:anchoColumnas[3], textAlign: 'right' }}>BASE IMPONIBLE</ColumnaNumericaHeader>
                            <ColumnaNumericaHeader  style={{ width: anchoColumnas[4], textAlign: 'right' }}>ALICUOTA</ColumnaNumericaHeader>
                            <ColumnaIconos/>
                            <ColumnaBotones style={{ width: anchoColumnas[5] }}>
                                <Button 
                                    variant="contained" 
                                    onClick={()=>{facturar()}}
                                    disabled={!precalculado}
                                >
                                    DECLARAR
                                </Button>
                            </ColumnaBotones>
                        </StyledTableRow>
                    </StyledTableHead>
                    <StyledTableBody style={{maxHeight:200, minHeight: 50}}>
                    {
                    rows.map((row) => {
                        
                        return generarFila(row);
                    })}
                    </StyledTableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
    //notificaciones por snackbar
    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function generarMensajeExito(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
    //notificaciones por snackbar

    function facturar(){
        declararBase();
    }
    function procesarImporte(data){
        if(data.importe > 0.0 || data.baseCalculada){
            return acortarDosDecimales(data.importe);
        }
        return "";
    }

    function SeleccionarFila( paramIDFila){
        var contador = 0;
        setRows(edicion => rows.map((row) => {
            if(contador > 0){
                cambiarEstadoDePago(true);
            }else{
                cambiarEstadoDePago(false);
            }
            //cambio de estado de la seleccion
            if (row.ID === paramIDFila) {
                if(row.seleccionado == false){
                    contador++
                }
                return {
                ...row,
                seleccionado: !row.seleccionado
                };
            } else {
                if(row.seleccionado == true){
                    contador++
                }
                return row;
            }
        }));

    };
    function generarFila(data){
        return (
            <StyledTableRow>
                
                <ColumnaTexto style={{ width: anchoColumnas[0] }} component="th" scope="row">
                    {data.periodo}
                </ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[1] }}>
                    {data.fechaVencimiento}
                </ColumnaTexto>
                <Tooltip title={data.actividad}>
                    <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }}>
                        {data.actividad}
                    </ColumnaTextoExtendida>
                </Tooltip>
                {generarIngresoBaseImponible(data)}
                <ColumnaNumerica style={{ width: anchoColumnas[4] }}>{acortarCuatroDecimales(data.alicuota)}</ColumnaNumerica>
                {generarIconoEdicion(data)}

                <ColumnaNumerica style={{ width: anchoColumnas[5] }}>{procesarImporte(data)}</ColumnaNumerica>
            </StyledTableRow>
        );
    };
    function habilitarFacturacion(data){
        data.forEach(item => {
            if(item.declaracionHabilitada){
                cambiarEstadoDePago(true);
            }
        });
        
    }
    function calcularImporte(estado, event){
        setRows(edicion => rows.map((row) => {
            //cambio de estado de la seleccion
            if (row.codigoMPS === estado.codigoMPS) {
                return {
                ...row,
                baseImponible: event.target.value,
                baseCalculada: false,
                importe: 0.0
                //importe: event.target.value * row.alicuota,
                };
            } else {
                return row;
            }
        }));
        
    };
    function generarIngresoBaseImponible(estado){
        return(
                <ColumnaNumerica style={{ width: anchoColumnas[3], padding:0 }}>
                    {valorTestigo}
                    {estado.declaracionHabilitada && <BaseDeclarableInput
                        onChange={actualizarBase}
                        indiceEdicion = {estado.codigoMPS} 
                        reset={reset}
                        setReset={setReset}
                    />}
                    
                </ColumnaNumerica>
            );
    };
    function actualizarBase(valor, indiceMovimiento) {
        setRows(edicion => rows.map((row) => {
            //cambio de estado de la seleccion
            if (row.codigoMPS === indiceMovimiento) {
                return {
                ...row,
                baseImponible: valor,
                baseCalculada: false,
                importe: 0.0
                //importe: event.target.value * row.alicuota,
                };
            } else {
                return row;
            }
        }));
    }
    function generarIconoEdicion(estado){
        if(estado.declaracionHabilitada && !estado.baseCalculada){
            return(
                <Tooltip title={"Calcular monto."}>
                    <ColumnaIconos>
                        <IconoCheck onClick={()=>{calcularBase(estado)}} />
                    </ColumnaIconos>
                </Tooltip>
            );
        }
        return(
            <ColumnaIconos/>
        ); 
    };

    function calcularMontoDeclarado(){
        //busqueda de montos y codigos del movimiento
        rows.forEach(item => {
            if(item.declaracionHabilitada){
                alert("codigo:" + item.codigoMPS + "monto" + item.baseImponible);
            }
        });
        
    };
    function generarIconoSeleccion(editable, estado){
        if(editable){
            return(
                <ColumnaBotones >
                    <input type="checkbox" checked={estado.seleccionado} onChange={()=>SeleccionarFila(estado.ID)}/>
                </ColumnaBotones>
            );
        }
        return(
            <ColumnaBotones >
                {"      "}
            </ColumnaBotones>
        );
    }
    function acortarDosDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(2)
    }
    function acortarCuatroDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(4)
    }
    function habilitarEdicionBaseImponible(estado){
        setRows(edicion => rows.map((row) => {
            //cambio de estado de la seleccion
            if (row.ID === estado.ID) {
                return {
                ...row,
                enDeclaracion: true
                };
            } else {
                return row;
            }
        }));
    }
    function deshabilitarEdicionBaseImponible(estado){
        setRows(edicion => rows.map((row) => {
            //cambio de estado de la seleccion
            if (row.ID === estado.ID) {
                return {
                ...row,
                enDeclaracion: false
                };
            } else {
                return row;
            }
        }));
    }
  }