import Footer from "../../componentes/footer";
import Header from "../../componentes/header";
import { TextField, Button,Grid } from "@mui/material";
import { useState,useEffect } from "react";
import ModalCargando from "../../componentes/modalCargando";
import rutas from "../../configuracion/Configuracion";
import Modal from "../../componentes/modal";
import IconoAlert from "../../componentes/iconos/iconoAlert";
import ContadorDescendente from "../../componentes/contadorDescendente";
import { useSearchParams,useNavigate,Link  } from 'react-router-dom'
import Sesion from "../../configuracion/controladorSesiones";

function RegistroEmail(){
  const navigate = useNavigate();
  //se queda
  const [searchParams, setSearchParams] = useSearchParams();
  // pendiente a revision

  const[nombre, setNombre] = useState("")
  const[cuitArmado,setCuitArmado] = useState("")
  const[email, setEmail] = useState("")
  const[codigoCliente, setCodigoCliente] = useState("")

  const[codigoVerificacion, setCodigoVerificacion] = useState("")
  const[tiempoRestante, setTiempoRestante] = useState(0)
  const [pass,setPass] = useState("")
  const [cuitValidado,setCuitValidado] = useState(false)
  const [esperandoCodigo,setEsperandoCodigo] = useState(false)
  const [generado,setGenerado] = useState(false)
  const [cargando, setCargando] = useState(false)
  const [mensajeError,setMensajeError] = useState("")
  const[mostrarMensaje, setMostrarMensaje] = useState(false);
  const[permitirValidado, setPermitirValidado] = useState(true);

  var datosCompletos = cuitArmado != '' && email != '' && nombre != '' && email.indexOf('@') !== -1;
  var errorEmail = email === '' || email.indexOf('@') === -1

  useEffect(() => {
    buscarDatos()
  }, []);

  const ocultarCargando = () =>{
    setCargando(false)
  }
  const cambiarCuit = event => {
    setPass(event.target.value);
  };  
  const cambiarCodigoVerificacion = event => {
    setCodigoVerificacion(event.target.value.toUpperCase());
  };  
  const cambiarEmail = event =>{
    setEmail(event.target.value)
  };
  const buscarDatos = async () =>{
    try {
      setCargando(true)
      const sesion = new Sesion();
      const response = await fetch(rutas.API + rutas.BusquedaUsuario + sesion.buscarCodigoContribuyente());
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
        setCuitArmado(data.Datos.Datos.cuit)
        setEmail(data.Datos.Datos.email)
        setNombre(data.Datos.Datos.nombre)
        setCodigoCliente(data.Datos.Datos.codigoCliente)
      }else{
        setMensajeError(data.Error)
        setMostrarMensaje(true)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const registrar = async () =>{
    try {
      setCargando(true)
      var url = rutas.API + rutas.GenerarRegistroEmail
      url = url.replace("param1",codigoCliente)
      url = url.replace("param2",cuitArmado.replace("-",""))
      url = url.replace("param3",email)
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//se genero el email correctamente y el registro
        setEsperandoCodigo(true)
        setTiempoRestante(data.Datos.Datos.restante)
      }else{
        setMensajeError(data.Error)
        setMostrarMensaje(true)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const cancelar = async () =>{
    setEsperandoCodigo(false)
    setGenerado(false)
    setCuitValidado(false) 
  }
  const volverAlIngreso = async () =>{
    setEsperandoCodigo(false)
    setGenerado(false)
    setCuitValidado(false)
  }
  const verificarCodigo = async () =>{
    try {
      setCargando(true)
      var url = rutas.APIHTTP + rutas.ValidarRegistroEmail
      url = url.replace("param1",codigoVerificacion)
      url = url.replace("param2",codigoCliente)
      url = url.replace("param3",cuitArmado.replace("-",""))
      url = url.replace("param4",email)
      const response = await fetch(url);
      const data = await response.json();
      if(data.Exito && data.Datos.Respuesta){//se genero el email correctamente y el registro
        setGenerado(true)
      }else{
        setMensajeError(data.Error)
        setMostrarMensaje(true)
      }
      ocultarCargando()
    } catch (error) {
      console.error(error)
    } 
  }
  const redirigirLogin = async () =>{
    navigate('/login')
  }
  const panelStyle = {
    marginTop:120,
    fontFamily:'Roboto, sans-serif',
    display: 'block',
    marginBottom:120
  };
  const tituloItem = {
    marginTop: 0,
    marginBottom: 0
  };
  return(
    <div style={{fontFamily: "Roboto,sans-serif"}}>
      <Header />
      <Modal isOpen={mostrarMensaje} closeModal={cerrarMensaje} style={{marginTop:200}}>
        <div className="modal-background" visible>
          <div className="modal-container" style={{minWidth:600}}  >
            {panelError()}
          </div>
        </div>
      </Modal>
      <ModalCargando estadoModal={cargando} cerrarModal={ocultarCargando}></ModalCargando> 
      <div  className="form-container" style={{marginTop:80}}>
        <img className="logo-login" 
          alt="" 
          height="100" 
          width="150" 
          src="/imagenes/LogoHeader.png" 
          style={{ marginTop:0 }}
        />
        
        {!esperandoCodigo && !generado && panelAceptarRegistro()}
        {esperandoCodigo && !generado &&  panelvalidadoCodigo()}
        {esperandoCodigo && generado && panelRegistroCorrecto()}

      </div>
      <Footer/>   
    </div>
  )
  //Paneles 
  function panelAceptarRegistro(){
    return(
      <div style={{maxWidth:'300px'}}>
        <div style={{borderRadius: '5px',boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',padding:'1em', marginTop:'20px'}}>
        <TextField
          style={{marginTop:10}}
          label="Nombre"
          value={nombre}
          size="small"
          type="text"
          fullWidth
        />
        <TextField
          style={{marginTop:10}}
          label=""
          value={email}
          size="small"
          type="text"
          onChange={cambiarEmail}
          error={errorEmail}
          helperText={errorEmail ? 'El Correo Electrónico es obligatorio.' : ''}
          fullWidth
        />
        <TextField
          style={{marginTop:10}}
          label="CUIT"
          value={cuitArmado}
          size="small"
          type="text"
          fullWidth
        />
        </div>
          <h3 style={{textAlign:'center'}}>Se le enviará un correo de confirmación, ¿desea continuar?</h3>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button 
                style={{display: "block", margin: "auto"}}
                variant="contained" 
                onClick={() => {redirigirLogin()}}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button 
                style={{display: "block", margin: "auto"}}
                variant="contained" 
                onClick={() => {registrar()}}
                disabled = {!datosCompletos}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
      </div>
    )
  }
  function panelvalidadoCodigo(){
    return(
      <div style={{maxWidth:'300px', textAlign: 'center', marginTop:'40px'}}>
        <ContadorDescendente segundos={tiempoRestante} notificarFinConteo={notificarFinConteo}></ContadorDescendente>
        <p>Se envió a su correo electrónico un código de verificación, por favor ingréselo.</p>
        <TextField
          style={{ marginTop:10 }}
          label="Codigo"
          type="text"
          value={codigoVerificacion}
          variant="standard"
          onChange={cambiarCodigoVerificacion}
          size="small"
          fullWidth
        />
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button 
            style={{display: "block", margin: "auto", marginTop: '20px'}}
            variant="contained" 
            onClick={() => {cancelar()}}
            >
              Cancelar
            </Button>
          </Grid>
            <Grid item>
            <Button 
              style={{display: "block", margin: "auto", marginTop: '20px'}}
              variant="contained" 
              onClick={() => {verificarCodigo()}}
              disabled={!permitirValidado}
            >
              Validar
            </Button>
          </Grid>
        </Grid>
          

      </div>
    )
  }
  function panelRegistroCorrecto(){
    return(
      <div style={{textAlign: 'center'}}>
        <h1>Bienvenido</h1>
        <p>Su correo electrónico registrado fue exitosamente.</p>
        <Button 
          style={{display: "block", margin: "auto"}}
          variant="contained" 
          onClick={() => {redirigirLogin()}}
        >
          Iniciar Sesion
        </Button>
      </div>
    )
  }
  function cerrarMensaje(){
    setMostrarMensaje(false);
  }
  function notificarFinConteo(){
    setPermitirValidado(false)
  }
  function panelError(){
    return (
      <div>
        <IconoAlert alto="64px" ancho="64px"></IconoAlert>
          <h2>{mensajeError}</h2>
        <Button variant="contained" onClick={cerrarMensaje} >Aceptar</Button>
      </div>
    )
  }
  //Paneles
}
export default RegistroEmail;

class UsuarioPost{
  constructor(
    paramId = 0, 
    paramNombre = "", 
    paramCUIT = "", 
    paramContrasenia = "" , 
    paramNuevaContrasenia = "",
    paramEmail = "", 
    paramCodigoValidacion = ""
  ){
    this.ID = paramId;
    this.Nombre = paramNombre
    this.CUIT = paramCUIT;
    this.Contrasenia = paramContrasenia;
    this.NuevaContrasenia = paramNuevaContrasenia;
    this.Email = paramEmail;
    this.CodigoValidacion = paramCodigoValidacion;
  }
}