import Header from "../../componentes/header";
import Footer from "../../componentes/footer";
import { useState,useEffect, useRef } from "react";
import ModalCargando from "../../componentes/modalCargando";
import {useNavigate } from 'react-router-dom';
import Sesion from "../../configuracion/controladorSesiones";
import ModalPrimerUso from "../../componentes/modalPrimerUso";
import { Button } from "@mui/material";
import CampoEditable from "../../componentes/campoEditable";
import {
    Box,
    Grid,
} from "@mui/material";
import rutas from "../../configuracion/Configuracion";
import HookModal from '../../componentes/hooks/hookModal';
import IconoCorrecto from "../../componentes/iconos/iconoCorrecto";
import IconoAlertGreen from "../../componentes/iconos/iconoAlertGreen";
import IconoAlert from "../../componentes/iconos/iconoAlert";
import Modal from "../../componentes/modal";


function Edicion() {
    const[existenCambios, setExistenCambios] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [modalExito,abrirExitoso, cerrarExitoso] = HookModal();
    const [nombre, setNombre] = useState("");
    const [domicilio, setDomicilio] = useState("");
    const [cuit, setCUIT] = useState("");
    const [localidad, setLocalidad] = useState("");
    const [provincia, setProvincia] = useState("");
    const [codigoPostal, setCodigoPostal] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [nombreReferencia, setNombreReferencia] = useState("");
    const [domicilioReferencia, setDomicilioReferencia] = useState("");
    const [cuitReferencia, setCUITReferencia] = useState("");
    const [localidadReferencia, setLocalidadReferencia] = useState("");
    const [provinciaReferencia, setProvinciaReferencia] = useState("");
    const [codigoPostalReferencia, setCodigoPostalReferencia] = useState("");
    const [telefonoReferencia, setTelefonoReferencia] = useState("");
    const [emailReferencia, setEmailReferencia] = useState("");
    const [dniReferencia, setDniReferencia] = useState("");
    const[mostrarMensaje, setMostrarMensaje] = useState(false);
    const[mensaje, setMensaje] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
        validarSesionVencida();
    }, [])
    useEffect(() => {
        //valido cuando se setea el ultimo valor del los state.
        validarCambios();
    }, [dniReferencia])
    const fetchData = async() =>{
        setModalCargando(true)
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                const response = await fetch(rutas.API + rutas.DatosContribuyente + codigoContribuyente);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setNombre(data.Datos.Datos.nombre);
                    setDomicilio(data.Datos.Datos.domicilio);
                    setCUIT(data.Datos.Datos.cuit);
                    setLocalidad(data.Datos.Datos.localidad);
                    setProvincia(data.Datos.Datos.provincia);
                    setCodigoPostal(data.Datos.Datos.codigoPostal);
                    setTelefono(data.Datos.Datos.telefono);
                    setEmail(data.Datos.Datos.email);
                    setDni(data.Datos.Datos.dni);
                    setNombreReferencia(data.Datos.Datos.nombre);
                    setDomicilioReferencia(data.Datos.Datos.domicilio);
                    setCUITReferencia(data.Datos.Datos.cuit);
                    setLocalidadReferencia(data.Datos.Datos.localidad);
                    setProvinciaReferencia(data.Datos.Datos.provincia);
                    setCodigoPostalReferencia(data.Datos.Datos.codigoPostal);
                    setTelefonoReferencia(data.Datos.Datos.telefono);
                    setEmailReferencia(data.Datos.Datos.email);
                    setDniReferencia(data.Datos.Datos.dni);
                    
                }
            }
            cerrarMensaje();
        } catch (error) {
            console.error(error);
        }
        setModalCargando(false)
    }
    const panelDatos = {
        color: '#00000061',
        fontFamily:'Roboto, sans-serif',
        marginBottom: 40,
        marginLeft: 20,
        marginRight: 20,
    };
    const campoMensaje = {
        display:'flex',
        //backgroundColor: 'rgba(255, 255, 0, 0.4)', // Amarillo cálido semitransparente al 40%
        padding: '0px', // Espacio interno para el texto
        ///width: '90vw', // Ancho del campo, ajustado al 80% del ancho de la pantalla
        margin: '0 auto', // Centrar el campo horizontalmente
        borderRadius: '8px', // Bordes redondeados con un radio de 8px
        //color: 'green',
        fontFamily: '"Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif', // Fuente con opciones de fallback
      };
    const modificarCampo = async (paramCampo, paramValor)=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && validarCampoObligatorio(paramCampo)){
                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var url =  rutas.API + rutas.ModificarContribuyente;
                url = url.replace("codigoCliente", codigoContribuyente) 
                url = url.replace("param2", paramCampo) 
                url = url.replace("param3", paramValor) 
                const response = await fetch(url);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    } 
    const guardarCambios = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                setModalCargando(true)

                const codigoContribuyente = sesion.buscarCodigoContribuyente();
                var url =  rutas.API + rutas.ModificarContribuyente;
                url = url.replace("param1", codigoContribuyente)
                url = url.replace("param2", nombre) 
                url = url.replace("param3", cuit) 
                url = url.replace("param4", dni) 
                url = url.replace("param5", domicilio) 
                url = url.replace("param6", localidad) 
                url = url.replace("param7", provincia) 
                url = url.replace("param8", codigoPostal) 
                url = url.replace("param9", telefono) 
                url = url.replace("param10", email) 
                const response = await fetch(url);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setModalCargando(false)
                    abrirExitoso();
                    return true;
                }
                setModalCargando(false)
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    } 
    const validarCampoObligatorio = async (paramCampo)=>{
        try {
            switch(paramCampo){
                case 'dni':
                    break;
                case 'cuit':
                    break;
                case 'nombre':
                    break;
                case 'telefono':
                    break;
                case 'email':
                    if(email !== ""){
                        return true;
                    }
                    setMensaje("El campo " + paramCampo + " es de caracter obligatorio.")
                    setMostrarMensaje(true);
                    return false;
                    break;
                case 'domicilio':
                    if(domicilio !== ""){
                        return true;
                    }
                    setMensaje("El campo " + paramCampo + " es de caracter obligatorio.")
                    setMostrarMensaje(true);
                    return false;
                    break;
                case 'localidad':
                    if(localidad !== ""){
                        return true;
                    }
                    setMensaje("El campo " + paramCampo + " es de caracter obligatorio.")
                    setMostrarMensaje(true);
                    return false;
                    break;
                case 'provincia':
                    if(provincia !== ""){
                        return true;
                    }
                    setMensaje("El campo " + paramCampo + " es de caracter obligatorio.")
                    setMostrarMensaje(true);
                    return false;
                    break;
                case 'codigoPostal':
                    if(codigoPostal !== ""){
                        return true;
                    }
                    setMensaje("El campo " + paramCampo + " es de caracter obligatorio.")
                    setMostrarMensaje(true);
                    return false;
                    break;
                default:
            }
        } catch (error) {
            console.error(error);
            return false;
        }  
    }
    return (
        <div>
            <Header  hideButton={false} hideReturnMenu={false}/>
            <ModalPrimerUso/>
            <ModalCargando estadoModal={modalCargando}/>
            {/*modal de modificacion exitosa*/}
            <Modal isOpen={modalExito} closeModal={cerrarExitoso} style={{marginTop:100}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:500,marginTop:80}} >
                        <IconoCorrecto/>
                        <h2>Se modificaron correctamente los datos del contribuyente.</h2>
                        <Button 
                            variant="contained"
                            onClick={actualizar}
                        >
                        LISTO
                        </Button>
                    </div>
                </div>
            </Modal>
            {/*Modal de campo obligatorio */}
            <Modal isOpen={mostrarMensaje} closeModal={cerrarMensaje} style={{marginTop:200}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:600}}  >
                        <IconoAlert></IconoAlert>
                        <h2>{mensaje}</h2>
                        <Button variant="contained" onClick={cerrarMensaje} style={{margin:20}}>Aceptar</Button>
                    </div>
                </div>
            </Modal>
            
            <div style={{marginTop:64}}>
                <div style={{paddingTop: 20}}>
                <div style={panelDatos} 
            >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={3}>
                        <CampoEditable  modificar={setNombre} valor={nombre} valorOriginal={nombreReferencia} label="Nombre" campo="nombre" editable={false} obligatorio={false} largoMaximo={199} avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoEditable  modificar={setCUIT} valor={cuit} valorOriginal={cuitReferencia} label="CUIT" campo="cuit" editable={false} obligatorio={false} largoMaximo={13} carateresPermitidos="1234567890-" avisarEdicion={setExistenCambios} esCUIT={true}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable  modificar={setDni} valor={dni} valorOriginal={dniReferencia} label="DNI" campo="dni" editable={false} obligatorio={false} largoMaximo={8} carateresPermitidos="1234567890" avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoEditable  modificar={setTelefono} valor={telefono} valorOriginal={telefonoReferencia} label="Teléfono" campo="telefono" largoMaximo={15} carateresPermitidos="1234567890" obligatorio={false} avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CampoEditable  modificar={setEmail} valor={email} valorOriginal={emailReferencia} label="Correo Electrónico" campo="email" largoMaximo={250} avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={3}>
                        <CampoEditable  modificar={setDomicilio} valor={domicilio} valorOriginal={domicilioReferencia} label="Domicilio" largoMaximo={100} campo="domicilio" avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable  modificar={setLocalidad} valor={localidad} valorOriginal={localidadReferencia}  label="Localidad" campo="localidad" largoMaximo={50} avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable  modificar={setProvincia} valor={provincia} valorOriginal={provinciaReferencia} label="Provincia" campo="provincia" largoMaximo={100} avisarEdicion={setExistenCambios}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable  modificar={setCodigoPostal} valor={codigoPostal} valorOriginal={codigoPostalReferencia} label="Código Postal" campo="codigoPostal" largoMaximo={4} carateresPermitidos="1234567890" avisarEdicion={setExistenCambios}/>
                    </Grid>



                </Grid>
            </Box>
        </div>
                </div>
            </div>
            <div style={campoMensaje}>
                <div style={{padding:'10px'}}>
                    <IconoAlertGreen ancho="24px" alto="24px" color="black" ></IconoAlertGreen>
                </div>
                <div>
                    <p >ATENCIÓN: Los datos aqui consignados revisten carácter de Declaración Jurada.</p>
                </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:20, gap:10}} >
                    <Button 
                        variant="contained"
                        onClick={descartarCambios}
                        disabled={!existenCambios}
                        >
                        DESCARTAR
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={guardar}
                        disabled={!existenCambios}
                        >
                        GUARDAR
                    </Button>
                </div>
            <Footer sugerenciaActiva={true} />
        </div>
    );

    async function guardar(){
        validarCambios();
        if(existenCambios){
            var salida = true;
            setModalCargando(true)
            if(domicilio === ""){
                setMensaje("El campo domicilio es de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }
            if(telefono === ""){
                setMensaje("El campo telefono es de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }
            if(email === ""){
                setMensaje("El email es un dato de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }
            if(cuit === ""){
                setMensaje("El email es un dato de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }
            if(localidad === ""){
                setMensaje("La localidad es un dato de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }
            if(provincia === ""){
                setMensaje("La provincia es un dato de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }
            if(codigoPostal === ""){
                setMensaje("El codigo postal es un dato de caracter obligatorio.")
                setMostrarMensaje(true);
                setModalCargando(false);
                salida = false;
            }

            if(salida){
                guardarCambios();
            }    
        }
 
    }
    function actualizar(){
        fetchData();
        cerrarExitoso();
    }
    function validarSesionVencida(){
        const sesion =  new Sesion();
        if(!sesion.sesionActiva()){
            navigate("/login");
        }
    }
    function descartarCambios(){
        setDni(dniReferencia);
        setNombre(nombreReferencia);
        setDomicilio(domicilioReferencia);
        setCUIT(cuitReferencia);
        setLocalidad(localidadReferencia);
        setProvincia(provinciaReferencia);
        setCodigoPostal(codigoPostalReferencia);
        setTelefono(telefonoReferencia);
        setEmail(emailReferencia);
        //navigate("/contribuyente/impuestos");
    }
    function cerrarMensaje(){
        setMostrarMensaje(false);
    }
    function validarCambios(){
        setExistenCambios(false);
        if(domicilio == "" ){
            setExistenCambios(true);
        }
        if(localidad == "" ){
            setExistenCambios(true);
        }
        if(provincia == "" ){
            setExistenCambios(true);
        }
        if(codigoPostal == "" ){
            setExistenCambios(true);
        }
        if(email == "" ){
            setExistenCambios(true);
        }
        if(domicilio !== domicilioReferencia ){
            setExistenCambios(true);
        }
        if(localidad !== localidadReferencia){
            setExistenCambios(true);
        }
        if(provincia !== provinciaReferencia){
            setExistenCambios(true);
        }
        if(codigoPostal !== codigoPostalReferencia ){
            setExistenCambios(true);
        }
        if(telefono !== telefonoReferencia ){
            setExistenCambios(true);
        }
        if(email !== emailReferencia ){
            setExistenCambios(true);
        }
    }
}

export default Edicion;
