import { useState,useEffect } from "react";
import rutas from "../../configuracion/Configuracion";
import ModalCargando from "../../componentes/modalCargando";
import { useSearchParams } from "react-router-dom";

function TerminosPlanPago() {
    const [cargando, setCargando] = useState(true);
    const [contenido, setContenido] = useState("");
    const [tipoPadron, setTipoPadron] = useState("");
    const [codigoPlan, setCodigoPlan] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        setTipoPadron(searchParams.get('padron'));
        setCodigoPlan(searchParams.get('codigo'));
        imprimir(searchParams.get('codigo'), searchParams.get('padron'))
    }, []);
    const imprimir = async (codigo,padron)=>{
        setCargando(true)
        try {
            var url = rutas.API + rutas.ImpresionTerminosYCondiciones;
            url = url.replace("param1",await codigo) 
            url = url.replace("param2",await padron) 
            const response = await fetch(url);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }

    }
    return(
        <div>
            {
            cargando ? 
            <ModalCargando estadoModal={cargando}/>
            :
            <div style={{ width: '100%', height: '100vh' }}>
                <iframe
                src={contenido}
                title="PDF Viewer"
                style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
            }
        </div>
    )
}
export default TerminosPlanPago