const IconoAlert = ({ancho="128px", alto="128px", color = "#f2ee1d"}) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  stroke="currentColor"
  strokeLinecap="round"
  strokeLinejoin="round"
  strokeWidth={2}
  width={ancho}
  height={alto}
  className="feather feather-alert-triangle"
  viewBox="0 0 24 24"
>
  <path
    d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
    style={{
      stroke: color,
    }}
  />
  <path
    d="M12 9v4"
    style={{
      stroke: color,
    }}
  />
  <path
    d="M12 17h0"
    style={{
      stroke: color,
    }}
  />
</svg>
)
export default IconoAlert
