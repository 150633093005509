import ModalCargando from './modalCargando';
import rutas from '../configuracion/Configuracion'
import { useState, useEffect, useRef } from "react";
import Sesion from '../configuracion/controladorSesiones';
import {
    Button,
} from "@mui/material";
import { useLocation } from 'react-router-dom';

///La idea con este boton es que a diferenia del original sirve para pagar mme y no una factura o recibo ya constituidos
///funciona para pagar cuotas de un plan de pagos o un pago parcial de un recibo donde se selecciona 
///la cuota(MME ) especifico de cada caso

function BotonPagoPlanPago({listaItems=[], funcionActualizar}) {
    ///El formato de los items es tipo documento(ej. FV) y la factura (ej 000060002001)
    ///en caso que la redireccion este activa se buscara en la api el form de pago
    ///en caso contrario se genera un button se generara un button desactivado
    const[clicked, setClick] = useState(false);
    const[pagoPreparado, setPagoPreparado] = useState(false);
    const[redireccionActiva,cambiarEstadoFacturacion]=useState(false);
    const [botonHTML, setBotonHTML] = useState("");
    const [cargando, cambiarEstadoCargando] = useState(false);
    const formRef = useRef(null);
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        setPagoPreparado(false);
    }, []);
    useEffect(() => {
        if(listaItems.length < 1){//valido sin existen items a procesa, y como entan en parejas verifico de 2 para atras
            setPagoPreparado(false);
        }else{
            var existenElementosNulos = false;
            console.log(listaItems);
            listaItems.forEach(element => {
                if( element == null){
                    existenElementosNulos=true;
                }
            });
            setPagoPreparado(!existenElementosNulos);
        }
    }, [listaItems]);
    useEffect(() => {
        if(pagoPreparado && clicked){
            document.getElementById('formMacro').submit();
        }
    }, [botonHTML]);
    const generarForm = async () =>{
        //para evitar pisado de datos declaro dentro de la funcion asyncrona
        var listado = listaItems;
        var esRecibo = true;
        ///tipo de pago a generar 
        
        var tipoPadron = "";
        switch (true) {
            case pathname.includes('/contribuyente/automotor'):
                tipoPadron="XA";
            break;
            case pathname.includes('/contribuyente/rural'):
                tipoPadron="XR";
            break;
            case pathname.includes('/contribuyente/urbano'):
                tipoPadron="XU";
            break;
            case pathname.includes('/contribuyente/tish'):
                tipoPadron="XH";
            break;
            case pathname.includes('/contribuyente/ingresosbrutos'):
                tipoPadron="XB";
            break;
        }
        ///tipo de pago a generar 

            try {
                cambiarEstadoCargando(true);
                const sesion =  new Sesion();
                if(sesion.sesionActiva()){
                    const codigoContribuyente = sesion.buscarCodigoContribuyente();
                    var bodyPost = JSON.stringify({
                        "bodyCodigoContribuyente":codigoContribuyente,
                        "bodyListOriginantesConTipo":listado,
                        "bodyPagarRecibo": false,
                        "bodyEsPlanPago": true,
                        "tipoPadron":"",
                    });
                    const response = await fetch(rutas.API + rutas.GenerarPagoElectronico + bodyPost);
                    const data = await response.json();
                    if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                        setClick(true);
                        setBotonHTML(data.Datos.HTML);
                        //actualizar la tabla padre
                        //funcionActualizar();
                        //espero un par de segundos antes de actualizar la tabla para evitar que no se haya tardado el procesamiento
                        setTimeout(function () {
                            funcionActualizar();
                        }, 3000);
                    }
                }
                cambiarEstadoCargando(false);
            } catch (error) {
                console.error(error);
            }

    }  
    return(
        <div >
            <form ref={formRef} style={{display:'none'}} id='formMacro' target="_blank" method='post' action={rutas.EndPointPagosMacro}>
                <div dangerouslySetInnerHTML={{ __html: botonHTML }}/>
                <button type='submit' >Pagar</button>
            </form>
            <ModalCargando  estadoModal={cargando}/>
            <Button 
                variant="contained"
                disabled={!pagoPreparado}
                onClick={generarForm}
            >
                PAGAR
            </Button>

        </div>
    );
}
export default BotonPagoPlanPago 