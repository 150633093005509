import { Menu,MenuItem } from "@mui/material";
import { useState,useEffect } from "react";
import React from "react";

function MenuImpresion({codigoPublico,codigoInterno, tipoDocumento = "", tipoPadron = ""}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [esFactura,setEsFactura] = useState(false);
  const [esFacturaXX,setEsFacturaXX] = useState(false);
  const [esRecibo,setEsRecibo] = useState(false);
  const [esPlanPago,setEsPlanPago] = useState(false);
  const [esPlanPagoModulos,setEsPlanPagoModulos] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    switch(tipoDocumento){
      case "TMEC":
        setEsFactura(false);
        setEsFacturaXX(false);
        setEsRecibo(false);
        setEsPlanPago(false);
        setEsPlanPagoModulos(true);
        break;
      case "TMEB":
        setEsFactura(false);
        setEsFacturaXX(false);
        setEsRecibo(false);
        setEsPlanPago(true);
        setEsPlanPagoModulos(false);
        break;
      case "TMEA":
        setEsFactura(false);
        setEsFacturaXX(false);
        setEsRecibo(true);
        setEsPlanPago(false);
        setEsPlanPagoModulos(false);
        break;
      case "FV":
        setEsFactura(true);
        setEsFacturaXX(false);
        setEsRecibo(false);
        setEsPlanPagoModulos(false);
        setEsPlanPago(false);
        break;
      case "XX":
        setEsFactura(true);
        setEsFacturaXX(true);
        setEsRecibo(false);
        setEsPlanPagoModulos(false);
        setEsPlanPago(false);
        break;
    }
  }, [tipoDocumento]);

  return (
    <div>
        <div 
          onClick={handleClick}
          style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
          }}
          title="Click para ver opciones."
        >
        {codigoPublico}
        </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
          {(!esFactura && !esRecibo && esPlanPago && tipoPadron != "" && !esPlanPagoModulos) && <MenuItem onClick={imprimirConvenioPago}  >Ver convenio de pago</MenuItem>}
          {(esFactura && !esRecibo && !esPlanPago && !esPlanPagoModulos) && <MenuItem onClick={imprimirFactura}>Ver factura</MenuItem>}
          {(!esFactura && esRecibo && !esPlanPago && !esPlanPagoModulos) && <MenuItem onClick={imprimirRecibo}>Ver recibo</MenuItem>}
          {(!esFactura && !esRecibo && esPlanPago && !esPlanPagoModulos) && <MenuItem onClick={imprimirPlanPago}>Ver plan de pago</MenuItem>}
          {(!esFactura && !esRecibo && !esPlanPago && esPlanPagoModulos) && <MenuItem onClick={imprimirPlanPagoModulos}>Ver plan de pago en modulos</MenuItem>}
          <MenuItem onClick={imprimirCobros}>Ver pagos</MenuItem>

        </Menu>
      </div>
    
  );
  function imprimirCobros(){
    if(esFactura && !esRecibo && !esPlanPago && !esPlanPagoModulos){
      imprimirCobrosFactura();
    }
    if(!esFactura && esRecibo && !esPlanPago && !esPlanPagoModulos){
      imprimirCobrosRecibo();
    }
    if(!esFactura && !esRecibo && (esPlanPago || esPlanPagoModulos)){
      imprimirCobrosPlanPago();
    }
  }
  function imprimirFactura(){
    window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoInterno.replace(/-/g, "")+'&tipo='+tipoDocumento+'&facturas=1', '_blank', 'noreferrer');
  }
  function imprimirRecibo(){
    window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoPublico.replace(/-/g, "")+'&tipo='+tipoDocumento+'&facturas=1', '_blank', 'noreferrer');
  }
  function imprimirPlanPago(){
    window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoPublico.replace(/-/g, "")+'&tipo='+tipoDocumento+'&facturas=1', '_blank', 'noreferrer');
  }
  function imprimirPlanPagoModulos(){
    window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoPublico.replace(/-/g, "")+'&tipo='+tipoDocumento+'&facturas=1', '_blank', 'noreferrer');
  }
  function imprimirCobrosFactura(){
    if(esFacturaXX){
      window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoInterno.replace(/-/g, "")+'&tipo='+tipoDocumento+'&pagos=1', '_blank', 'noreferrer');
    }else{
      window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoPublico.replace(/-/g, "")+'&tipo='+tipoDocumento+'&pagos=1', '_blank', 'noreferrer');

    }
  }
  function imprimirCobrosRecibo(){
    window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoPublico.replace(/-/g, "")+'&tipo='+tipoDocumento+'&pagos=1', '_blank', 'noreferrer');
  }
  function imprimirCobrosPlanPago(){
    window.open('https://' + window.location.hostname + '/contribuyente/impresion?cod='+codigoPublico.replace(/-/g, "")+'&tipo='+tipoDocumento+'&pagos=1', '_blank', 'noreferrer');
  }
  function imprimirConvenioPago(){
    window.open('https://' + window.location.hostname + '/contribuyente/contratos/planpago?codigo='+codigoPublico.replace(/-/g, "")+'&padron='+tipoPadron, '_blank', 'noreferrer');
  }
}
export default MenuImpresion;