import Modal from "./modal"
import { Button } from "@mui/material";

function ModalPrevisualizarImpresion({contenidoPDF = '', estadoModal=false, cerrarModal = null, funcionAceptar = null }){
    return(
        <Modal isOpen={estadoModal} closeModal={cerrarModal} margenSuperior="100px" modalBase={true} >
            <div style={{ width: '1000px', height: '400px', margin:'10px' ,boxShadow:'0px 0px 10px rgb(0 0 0 / 20%)'}}>
                <iframe
                src={contenidoPDF}
                title=""
                style={{ width: '100%', height: '100%', transform: 'scale(1)' }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap:10}} >
                <Button 
                    variant="contained"
                    onClick={cerrarModal}
                >
                CANCELAR
                </Button>
                <Button 
                    variant="contained"
                    onClick={funcionAceptar}
                >
                Acepto el convenio de pago
                </Button>
            </div>
        </Modal>
    );
}
export default ModalPrevisualizarImpresion