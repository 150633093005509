const IconoTicket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 256 256"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="green"
          d="M0 256V0h256v197.016c-2.084-37.277-33.225-67.127-71-67.127-39.111 0-71.111 32-71.111 71.111 0 22.101 10.218 41.931 26.164 55zm256 0h-26.053c14.982-12.278 24.907-30.525 26.053-51.016z"
        />
      </clipPath>
    </defs>
    <g
      fill="green"
      strokeMiterlimit={10}
      clipPath="url(#a)"
      fontFamily="none"
      fontSize="none"
      fontWeight="none"
      style={{
        mixBlendMode: "normal",
      }}
      textAnchor="none"
    >
      <path
        fill="green"
        d="M42.667 42.667c-11.787 0-21.334 9.546-21.334 21.333v128c0 11.787 9.547 21.333 21.334 21.333h170.666c11.787 0 21.334-9.546 21.334-21.333V64c0-11.787-9.547-21.333-21.334-21.333zm0 21.333h170.666v21.333H42.667zm0 53.333h170.666V192H42.667z"
      />
    </g>
    <path
      fill="green"
      strokeMiterlimit={10}
      d="m6.4 6 1.5 1.7-.4.4-2-1.7V6l.2-3.2h.5zM6 1.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5M6 0c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6z"
      fontFamily="none"
      fontSize="none"
      fontWeight="none"
      style={{
        mixBlendMode: "normal",
      }}
      textAnchor="none"
      transform="translate(131.667 147.667) scale(8.88889)"
    />
  </svg>
)
export default IconoTicket
