import { useSearchParams } from "react-router-dom";
import { useState,useEffect } from "react";
import rutas from "../../configuracion/Configuracion";
import ModalCargando from "../../componentes/modalCargando";
import Sesion from "../../configuracion/controladorSesiones";

function Impresion() {
    const [tipo, setTipo] = useState("");
    const [codigo, setCodigo] = useState("");
    const [impresionFacturas, setImpresionFacturas] = useState(false);
    const [impresionPagos, setImpresionPagos] = useState(false);
    const [impresionLibreDeuda, setImpresionLibreDeuda] = useState(false);
    const [impresionInscripcion, setImpresionInscripcion] = useState(false);
    const [impresionValuacionFiscal, setImpresionValuacionFiscal] = useState(false);
    const [impresionHabilitacionComercial, setImpresionHabilitacionComercial] = useState(false);
    const [impresionBaja, setImpresionBaja] = useState(false);
    const [codigoCliente, setCodigoCliente] = useState("");
    const [contenido, setContenido] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [cargando, setCargando] = useState(true);

    useEffect(() => {
        //en caso de no existir un parametro este devuelve null, para resolver esto nunca se usan
        //los parametros puros, en cambio se usa un state con un valor default false
        setCodigo(searchParams.get('cod'));
        setTipo(searchParams.get('tipo'));
        if(searchParams.get('libredeuda') == "1"){
            setImpresionLibreDeuda(true);
        }
        if(searchParams.get('pagos') == "1"){
            setImpresionPagos(true);
        }
        if(searchParams.get('valuacionfiscal') == "1"){
            setImpresionValuacionFiscal(true);
        }
        if(searchParams.get('inscripcion') == "1"){
            setImpresionInscripcion(true);
        }
        if(searchParams.get('habilitacioncomercial') == "1"){
            setImpresionHabilitacionComercial(true);
        }
        if(searchParams.get('facturas') == "1"){
            setImpresionFacturas(true);
        }
        if(searchParams.get('baja') == "1"){
            setImpresionBaja(true);
        }
        const sesion =  new Sesion();
        if(sesion.sesionActiva()){
            setCodigoCliente(sesion.buscarCodigoContribuyente());
        }

    }, []);
    useEffect(() => {
        if(impresionInscripcion){
            imprimirInscripcion();
        }
        if(impresionValuacionFiscal ){
            imprimirValuacionFiscal();
        }
        if(impresionLibreDeuda){
            imprimirLibreDeuda();
        }
        if(impresionPagos ){
            imprimirCobros();
        }
        if(impresionHabilitacionComercial ){
            imprimirHabilitacionComercial();
        }
        if(impresionBaja){
            imprimirBaja();
        }
        if(impresionFacturas){
            switch (tipo) {
                case "FV":
                    imprimirFactura();
                    break;
                case "XX":
                    imprimirFactura();
                    break;
                case "TMEA":
                    imprimirRecibo();
                    break;
                case "TMEB":
                    imprimirPlanPago();
                    break;
                case "TMEC":
                    imprimirPlanPago();
                    break;
                default:
                    break;
            }
        }

    }, [tipo]);
    ///diccionario de tipos imprimibles
    //fact = factura
    //recb = recibo
    //cobfact = cobros de facturas
    //cobrecb = cobros de recibos
    //prsp = percepcciones
    ///diccionario de tipos imprimibles
    const imprimirFactura = async ()=>{
        try {
            const response = await fetch(rutas.API + rutas.impresionFactura + codigo);
            const data = await response.json();

            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    } 
    const imprimirRecibo = async ()=>{
        const sesion =  new Sesion();
        try {
            var codigoCliente = "";
            if(sesion.sesionActiva()){
                codigoCliente = sesion.buscarCodigoContribuyente();
            }
            var url =  rutas.API + rutas.impresionRecibo;
            url = url.replace("param1", codigo) 
            url = url.replace("param2", codigoCliente) 
            const response = await fetch(url);
            const data = await response.json();

            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    } 
    const imprimirPlanPago = async ()=>{
        try {
            const response = await fetch(rutas.API + rutas.impresionPlanPago + codigo);
            const data = await response.json();

            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirCobros = async () =>{
        const sesion =  new Sesion();
        try {
            var codigoCliente = "";
            if(sesion.sesionActiva()){
                codigoCliente = sesion.buscarCodigoContribuyente();
            }
            var url = rutas.API + rutas.ImpresionCobrosUniversal;
            url = url.replace("param1",tipo);
            url = url.replace("param2",codigo);
            url = url.replace("param3",codigoCliente);
            url = url.replace("param4","0");
            url = url.replace("param5","0");
            url = url.replace("param6","");
            const response = await fetch(url);
            const data = await response.json();

            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirCobrosFactura = async ()=>{
        try {
            const response = await fetch(rutas.API + rutas.impresionCobrosFactura + codigo);
            const data = await response.json();

            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    } 
    const imprimirCobrosRecibo = async ()=>{
        try {
            const response = await fetch(rutas.API + rutas.impresionCobrosRecibo + codigo);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    } 
    const imprimirLibreDeuda = async ()=>{
        try {
            var url = rutas.API + rutas.ImpresionLibreDeudaUniversal;
            url = url.replace("param2",tipo);
            url = url.replace("param1",codigo);
            const response = await fetch(url);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirValuacionFiscal = async ()=>{
        try {
            var url = rutas.API + rutas.ImpresionValuacionFiscal;
            url = url.replace("param2",tipo);
            url = url.replace("param1",codigo);
            const response = await fetch(url);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirInscripcion = async ()=>{
        try {
            var url = rutas.API + rutas.ImpresionInscripcion;
            url = url.replace("param2",tipo);
            url = url.replace("param1",codigo);
            const response = await fetch(url);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirHabilitacionComercial = async ()=>{
        try {
            var url = rutas.API + rutas.ImpresionHabilitacionComercial + codigo;
            const response = await fetch(url);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const imprimirBaja = async ()=>{
        try {
            var url = rutas.API + rutas.ImpresionBaja;
            url = url.replace("param2",tipo);
            url = url.replace("param1",codigo);
            const response = await fetch(url);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                setContenido("data:application/pdf;base64,"+data.Datos.Datos.base64)
                setCargando(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    return(
        <div>
            {
            cargando ? 
            <ModalCargando estadoModal={cargando}/>
            :
            <div style={{ width: '100%', height: '100vh' }}>
                <iframe
                src={contenido}
                title="PDF Viewer"
                style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
            }
        </div>
    )
}
export default Impresion