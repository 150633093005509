const IconoCheck = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 96 960 960"
    fill="green"
    {...props}
  >
    <path d="M378 810 154 586l43-43 181 181 384-384 43 43-427 427Z" />
  </svg>
)
export default IconoCheck
