
function TotalTabla({textoMonto="",textoDescuento="",textoTotal="", monto=0, descuento=0}) {

    return(
        <div style={{marginTop: 20}}>
            {generarColumnasMonto()}
            {generarColumnasDescuento()}
        </div>
    );
    function generarColumnasMonto(){
        if(textoMonto === '' ){
            return "";
        }
        return(
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{textoMonto}</span>
                <div style={{textAlign: 'right',fontWeight: 'bold',}}>{acortarDosDecimales(monto)}</div>
            </div>
        )
    }
    function generarColumnasDescuento(){
        if(descuento == 0){
            return "";
        }
        return(
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{textoDescuento}</span>
                    <div style={{textAlign: 'right',fontWeight: 'bold',}}>{acortarDosDecimales(descuento)}</div>
                </div>
                <hr/>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{textoTotal}</span>
                    <div style={{textAlign: 'right',fontWeight: 'bold',}}>{acortarDosDecimales(monto - descuento)}</div>
                </div>
            </div>
        )
    }
    //conversion de datos
    function acortarDosDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(2)
    }
}
export default TotalTabla 