import { useEffect,useState } from "react";
import rutas from '../configuracion/Configuracion'
import { Box,Grid,TextField,Tooltip } from "@mui/material";
import MenuCertificados from "./menuCertificados";

function PanelAutomotor({codigoImpuesto}){
    const [nombre, setNombre] = useState("");
    const [patente, setPatente] = useState("");
    const [estado, setEstado] = useState("");
    const [referencia, setReferencia] = useState("");
    const divStyle = {
        //border: '1px solid gray',
        //boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        //borderRadius: '5px',
        //padding: '10px',
        margin: '20px',
        //marginBottom: '0px',
        color: '#00000061',
        fontFamily:'Roboto, sans-serif',
        marginTop:40, 
        marginBottom: 40,
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(rutas.API + rutas.BusquedaDatosAutomotor + codigoImpuesto);
                const data = await response.json();
                if(data.Exito){//busqueda exitosa
                    setNombre(data.Datos.Datos.nombre)
                    setPatente(data.Datos.Datos.dominio)
                    setEstado(data.Datos.Datos.estado)
                    setReferencia(data.Datos.Datos.referencia)
                }
              } catch (error) {
                console.error(error);
              }
          }
        fetchData();
    }, [codigoImpuesto]);
    return(
        <div style={divStyle}>
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Automotor"
                            id="standard-basic"  
                            variant="standard"
                            value = {nombre} 
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Patente"
                            id="standard-basic"  
                            variant="standard"
                            value = {patente} 
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            label="Estado"
                            id="standard-basic"  
                            variant="standard"
                            value = {estado} 
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <div>
                            <Tooltip title={referencia} arrow>
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Referencia"
                                    id="standard-basic"  
                                    variant="standard"
                                    value = {referencia} 
                                />
                            </Tooltip>
                        </div>

                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
export default PanelAutomotor;
