import Header from "../../componentes/header";
import Footer from "../../componentes/footer";
import TablaImpuestos from "../../componentes/tablaImpuestos";
import PanelContribuyente from "../../componentes/panelContribuyente";
import Notificacion from "../../componentes/notificacion";
import { useState,useEffect } from "react";
import ModalCargando from "../../componentes/modalCargando";
import {useNavigate } from 'react-router-dom';
import Sesion from "../../configuracion/controladorSesiones";
import ModalPrimerUso from "../../componentes/modalPrimerUso";
import ModalEmailSinRegistrar from "../../componentes/modalEmailSinRegistrar";
import TablaPlanesPago from "../../componentes/tablaPlanesPago";
import { Tabs, Tab,Box } from '@mui/material';

function Impuestos() {
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    const [modalCargando, setModalCargando] = useState(false);
    const [codigoContribuyente, setCodigoContribuyente] = useState("");
    const [pestaniaActiva, setPestaniaActiva] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchData() {
            try {
                const sesion =  new Sesion();
                if(sesion.sesionActiva()){
                    setCodigoContribuyente(sesion.buscarCodigoContribuyente());
                }
            } catch (error) {
                console.error(error);
            }
          }
        fetchData();
        validarSesionVencida();
    }, []);
    const eventoTab = (event, newValue) => {
        setPestaniaActiva(newValue);
    };
    return (
        <div>
            <Header  hideButton={false} certificadoContribuyente={true} codigoCertificados={codigoContribuyente} />
            <ModalPrimerUso/>
            <ModalEmailSinRegistrar/>
            <ModalCargando estadoModal={modalCargando}/>
            <div style={{marginTop:64}}>
                <div style={{paddingTop: 20}}>
                    <PanelContribuyente 
                        generarMensaje={generarMensaje}
                        generarMensajeExito={generarMensajeExito}
                    />
                    <Notificacion 
                        mensaje={textoNotificacion} 
                        visible={notificacionVisible} 
                        funcionAbrir = {abrirNotificacion} 
                        funcionCerrar = {cerrarNotificacion}
                    />
                </div>
                <Tabs value={pestaniaActiva} onChange={eventoTab}>
                    <Tab label="IMPUESTOS Y TASAS" />
                    <Tab label="PLANES DE PAGO" />
                </Tabs>
                <TabPanel value={pestaniaActiva} index={0}>
                    <TablaImpuestos setModalCargando={setModalCargando} />
                </TabPanel>
                <TabPanel value={pestaniaActiva} index={1}>
                    <TablaPlanesPago setModalCargando={setModalCargando}></TablaPlanesPago>
                </TabPanel>
            </div>
          <Footer sugerenciaActiva={true} />
        </div>
    );
    function validarSesionVencida(){
        const sesion =  new Sesion();
        if(!sesion.sesionActiva()){
            navigate("/login");
        }
    }
    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
      }
    function generarMensajeExito(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
}

export default Impuestos;

function TabPanel({ children, value, index }) {
    return (
      <div hidden={value !== index}>
        {value === index && (
          <Box p={3} style={{paddingTop: 0}}>
            {children}
          </Box>
        )}
      </div>
    );
  }