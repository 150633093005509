import ModalCargando from './modalCargando';
import BotonPagoPlanPago from './botonPagoPlanPago';
import {
    Table,
    Box,
    Button,
    Tooltip,
    Grid
} from "@mui/material";
import {
    StyledTableContainer,StyledTableHead,ColumnaNumerica,
    StyledTableBody,ColumnaNumericaHeader,ColumnaNumericaAcortada,
    ColumnaTexto,ColumnaTextoHeader,ColumnaTextoExtendida,
    ColumnaBotones,ColumnaIconos,StyledTableRow
} from './componentesModificados';
//importacion de iconos
import IconoCancelar from './iconos/iconoCancelar';
import IconoTicket from './iconos/iconoTicket';
import IconoTarjeta from './iconos/iconoTarjeta';
import rutas, { CancelarPagofallido } from '../configuracion/Configuracion'
import { useState, useEffect } from "react";
import Sesion from '../configuracion/controladorSesiones';
import HookModal from './hooks/hookModal';
import Notificacion from './notificacion';
import Contexto from './contexts/contexto'
import MenuImpresion from './menuImpresion';
import ModalPlanPago from './modalPlanPago';

export default function TablaResumenPlanPagoModulos({recarga=false, setRecarga,codigoRecibo=""}) {
    //snackbar de notificaciones
    const [notificacionVisible, setNotificacionVisible] = useState(false);
    const [textoNotificacion, setTextoNotificacion] = useState("");
    //snackbar de notificaciones
    const[estadoModal, abrir, cerrar] = HookModal();
    const[codigoFacturaPercepciones, cambiarFacturaPercepciones] = useState("");
    const [rows, setRows] = useState([]);
    const [cargando, cambiarEstadoCargando] = useState(false);
    const [pagoPermitido, cambiarPagoPermitido] = useState(false);
    const anchoColumnas = ['3%', '9%','20%','7%','7%', '2%','7%', '7%','7%','7%', '7%'];
    const[listaPagos, setListaPagos] = useState([]);//lista de pagos a generar pago electronico
    const [tipoPadron, setTipoPadron] = useState("");

    useEffect(() => {
        if(recarga){
            setRecarga(false)
        }
     }, [recarga]);
    //busqueda de columnas
    const buscarDatos = async ()=>{
        if(codigoRecibo!= null && codigoRecibo!==""){
            try {
                const sesion =  new Sesion();
                if(sesion.sesionActiva()){
                    cambiarEstadoCargando(true);
                    const response = await fetch(rutas.API + rutas.BusquedaResumenYDeudaPlanPagoModulos + codigoRecibo);
                    const data = await response.json();
                    if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                        cambiarPagoPermitido(data.Datos.Datos.pagoPermitido);
                        setRows(data.Datos.Instancias);
                        setTipoPadron(data.Datos.Datos.tipoPadron);
                        setListaPagos([]);
                    }else{
                        generarMensaje("Error de conexion.")
                    }
                    cambiarEstadoCargando(false);
                }
            } catch (error) {
                cambiarEstadoCargando(false);
                generarMensaje("Error de conexion.")
                console.error(error);
            }
        }

    } 
    useEffect(() => {
        buscarDatos();
    }, [codigoRecibo]);
    useEffect(() => {
        procesarPagosPendientes();
    }, [rows]);
    
    const procesarPagosPendientes = async () =>{
        rows.forEach(item => {
            //cancelar la factura pendiente
            if(item.pagoMacroPendiente || item.pagoMacroFallido){
                if(item.codigoRecibo == ''){//caso de factura
                    cancelarPagoPendiente(item.codigoFactura);
                }else{
                    cancelarPagoPendiente(item.codigoRecibo);
                }
            }
        });        
    }
    const pagar = async (grilla) =>{
        try {
            var items = await generarItemsRecibo(grilla);
            setListaPagos(items);
        } catch (error) {
            console.error(error);
        }
    }    
    const generarItemsRecibo = async (grilla) =>{
        const salida = [];
        grilla.forEach(item => {
            if(item.seleccionado) {
                //salida.push(item.tipoDocumentoOriginante);
                salida.push(item.IDMME);
            }
        })
        return salida;
    }
    const cancelarPagoPendiente = async (codigoFactura) =>{
                
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                const response = await fetch(rutas.API + rutas.CancelarPagoPendiente + codigoFactura);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//cancelado exitoso
                    console.log("cancelando pago pendiente.")
                }
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    const cancelarPagoFallido = async (codigoFactura) =>{
                
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                const response = await fetch(rutas.API + rutas.CancelarPagofallido + codigoFactura);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//cancelado exitoso
                    console.log("cancelando pago pendiente.")
                }
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center',flexGrow: 1 }}>
            <Contexto.Provider value={buscarDatos}>
            {/*notificaciones por snackbar*/}
            <Notificacion 
                mensaje={textoNotificacion} 
                visible={notificacionVisible} 
                funcionAbrir = {abrirNotificacion} 
                funcionCerrar = {cerrarNotificacion}
            />
            {/*notificaciones por snackbar*/}
            {/*modal de carga*/}
            <ModalCargando estadoModal={cargando}/>
            {/*fomulario*/}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StyledTableContainer >
                        <Table stickyHeader >
                            <StyledTableHead >
                                <StyledTableRow>
                                    <ColumnaTextoHeader style={{ width: anchoColumnas[0] }} >FECHA</ColumnaTextoHeader>
                                    <ColumnaTextoHeader style={{ width: anchoColumnas[1] }}>PLAN</ColumnaTextoHeader>
                                    <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }} >REFERENCIA</ColumnaTextoExtendida>
                                    <ColumnaNumericaHeader style={{ width: anchoColumnas[3], textAlign: 'right'  }}>MONTO</ColumnaNumericaHeader>
                                    <ColumnaNumericaHeader  style={{ width: anchoColumnas[6], textAlign: 'right'  }}>TOTAL</ColumnaNumericaHeader>
                                    
                                    <ColumnaTextoHeader  style={{ width: anchoColumnas[8] }}>FECHA PAGO</ColumnaTextoHeader>
                                    <ColumnaTextoHeader  style={{ width: anchoColumnas[9] }}>COMPROBANTE</ColumnaTextoHeader>
                                    
                                    <ColumnaBotones style={{ width: anchoColumnas[10] }}>
                                        <BotonPagoPlanPago listaItems={listaPagos} funcionActualizar={buscarDatos}/>
                                    </ColumnaBotones>
                                </StyledTableRow>
                            </StyledTableHead>
                            <StyledTableBody style={{maxHeight:300, minHeight: 50}}>
                            {rows.map((row) => {
                                return generarFila(row);
                            })}
                            </StyledTableBody>
                        </Table>
                    </StyledTableContainer>
                </Grid>
            </Grid>
            </Contexto.Provider>
        </Box>
            

    );
    //notificaciones por snackbar
    function generarMensaje(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function generarMensajeExito(mensaje){
        setTextoNotificacion(mensaje)
        setNotificacionVisible(true)
    }
    function abrirNotificacion(){
        setNotificacionVisible(true)
    }
    function cerrarNotificacion(){
        setNotificacionVisible(false)
    }
    //notificaciones por snackbar

    function SeleccionarFila( paramIDFila){
        var seguirMarcando = false;
        var deseleccionarTodo = false;
        var contador = 0;
        const data = rows.map((row) => {
            if (row.codigoMME === paramIDFila && !row.seleccionado){
                seguirMarcando = true;
            }
            if(row.codigoMME === paramIDFila && row.seleccionado){
                deseleccionarTodo = true;
            }
            if(deseleccionarTodo){
                return {
                    ...row,
                    seleccionado: false
                };
            }else{
                if(seguirMarcando && row.pagoHabilitado){
                    contador++
                    return {
                        ...row,
                        seleccionado: true
                    };
                }else{
                    return {
                        ...row,
                        seleccionado: false
                    };
                }
            }

        })
        setRows(data);
        if(contador > 0){
            pagar(data);
        }else{
            setListaPagos([]);
        }
    };
    function generarFila(data){
        const editable = data.pagoHabilitado;
        return (
            <StyledTableRow>
                <ColumnaTexto style={{ width: anchoColumnas[0] }} component="th" scope="row">
                    {data.fecha}
                </ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[1] }}>
                    {generarBotonImpresionOriginante(data)}
                </ColumnaTexto>
                <Tooltip title={data.referencia}>
                    <ColumnaTextoExtendida style={{ width: anchoColumnas[2] }}>
                        {data.referencia}
                    </ColumnaTextoExtendida>
                </Tooltip>
                <ColumnaNumerica style={{ width: anchoColumnas[3] }}>{acortarDosDecimales(data.monto)}</ColumnaNumerica>
                <ColumnaNumerica style={{ width: anchoColumnas[7] }}>{acortarDosDecimales(data.saldo)}</ColumnaNumerica>
                <ColumnaTexto style={{ width: anchoColumnas[9] }} >{data.fechaPago}</ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[10] }}>{generarBotonImpresionCancelante(data)}</ColumnaTexto>
                
                {generarIconoSeleccion(data)}
            </StyledTableRow>
        );
    };
    function generarBotonImpresionOriginante(estado){
        return (
            <Tooltip title={"Visualizar"}>
                <MenuImpresion 
                    codigoInterno={estado.codi} 
                    codigoPublico={estado.codigoPublicoFactura}
                    tipoDocumento={estado.tipoDocumentoOriginante}
                    tipoPadron={tipoPadron}
                />
            </Tooltip>
        )
    }
    function generarBotonImpresionCancelante(estado){
        return (
            <Tooltip title={"Visualizar"}>
                <MenuImpresion 
                    codigoInterno={estado.codigoRecibo} 
                    codigoPublico={estado.codigoPublicoRecibo} 
                    tipoDocumento={estado.tipoDocumentoCancelante}
                    tipoPadron={tipoPadron}
                />
            </Tooltip>
        )
    }
    
    function generarIconoSeleccion( estado){
        if(estado.pagoMacroFallido){
            return(
                <Tooltip title={"Pago fallido."}>
                    <ColumnaIconos>
                        <IconoCancelar onClick={() => buscarDatos()}/>
                    </ColumnaIconos>
                </Tooltip>
            );
        }
        if(estado.pagoMacroPendiente){
            //
            return(
                <Tooltip title={"Pago en proceso, Haga click para actualizar."}>
                    <ColumnaIconos>
                        <IconoTarjeta onClick={() => buscarDatos()}/>
                    </ColumnaIconos>
                </Tooltip>
            );
        }

        if(estado.pagoHabilitado){
            if(pagoPermitido){
                return(
                    <ColumnaBotones style={{ width: anchoColumnas[11] }}>
                        <Tooltip title={"Seleccionar para pagar."}>
                            <input type="checkbox" checked={estado.seleccionado} onChange={()=>SeleccionarFila(estado.codigoMME)}/>
                        </Tooltip>
                    </ColumnaBotones>
                );
            }
            return(
                <ColumnaBotones style={{ width: anchoColumnas[11] }}>
                    <input type="checkbox" checked={estado.seleccionado} />
                </ColumnaBotones>
            );
        }
        return(
            <ColumnaBotones >
                {"      "}
            </ColumnaBotones>
        );
    }
    function recortarTexto(texto){
        return texto.substring(0,150) + "...";
    };
    function acortarDosDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(2)
    }
    function acortarCuatroDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(4)
    }
    function habilitarEdicionBaseImponible(estado){
        setRows(edicion => rows.map((row) => {
            //cambio de estado de la seleccionr
            if (row.ID === estado.ID) {
                return {
                ...row,
                enDeclaracion: true
                };
            } else {
                return row;
            }
        }));
    }
    function deshabilitarEdicionBaseImponible(estado){
        setRows(edicion => rows.map((row) => {
            //cambio de estado de la seleccion
            if (row.ID === estado.ID) {
                return {
                ...row,
                enDeclaracion: false
                };
            } else {
                return row;
            }
        }));
    }
    function mostrarDescuentoPorPercepciones(codigoFactura){
        cambiarFacturaPercepciones(codigoFactura);
        abrir();
    }
    function redireccionarContratoPlanPago(){
        window.open('/contratos/planpago?codigo='+codigoRecibo+'&padron='+tipoPadron, '_blank', 'noreferrer');
    }
  }