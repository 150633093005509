import react from "react";
import {
    Box,
    Paper,
    Grid,
    TextField,
    Button
} from "@mui/material";
import { useState, useEffect } from "react";
import rutas from '../configuracion/Configuracion'
import Sesion from '../configuracion/controladorSesiones';
import CampoEditable from "./campoEditable";
import MenuCertificados from "./menuCertificados";
import { useNavigate } from "react-router-dom";


function PanelContribuyente(props) {
    const [nombre, setNombre] = useState("");
    const [domicilio, setDomicilio] = useState("");
    const [cuit, setCUIT] = useState("");
    const [localidad, setLocalidad] = useState("");
    const [provincia, setProvincia] = useState("");
    const [codigoPostal, setCodigoPostal] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [codigo, setCodigo] = useState("");
    const navigate = useNavigate();

    const divStyle = {
        //border: '1px solid gray',
        //boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        //borderRadius: '5px',
        //padding: '10px',
        //marginBottom: '0px',
        color: '#00000061',
        fontFamily:'Roboto, sans-serif',
        marginBottom: 40,
        marginLeft: 20,
        marginRight: 20,
    };
    useEffect(() => {
        async function fetchData() {
            try {
                const sesion =  new Sesion();
                if(sesion.sesionActiva()){
                    const codigoContribuyente = sesion.buscarCodigoContribuyente();
                    setCodigo(sesion.buscarCodigoContribuyente());
                    const response = await fetch(rutas.API + rutas.DatosContribuyente + codigoContribuyente);
                    const data = await response.json();
                    if(data.Exito){//busqueda exitosa
                        setNombre(data.Datos.Datos.nombre);
                        setDomicilio(data.Datos.Datos.domicilio);
                        setCUIT(data.Datos.Datos.cuit);
                        setLocalidad(data.Datos.Datos.localidad);
                        setProvincia(data.Datos.Datos.provincia);
                        setCodigoPostal(data.Datos.Datos.codigoPostal);
                        setTelefono(data.Datos.Datos.telefono);
                        setEmail(data.Datos.Datos.email);
                        setDni(data.Datos.Datos.dni);
                    }
                }
            } catch (error) {
                console.error(error);
            }
          }
        fetchData();
    }, []);

    return(
        <div style={divStyle} >
            <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={3}>
                        <CampoEditable   valor={nombre} label="Nombre" campo="nombre" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={2}>
                        <CampoEditable   valor={cuit} label="CUIT/CUIL" campo="cuit" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable   valor={dni} label="DNI" campo="dni" editable={false} obligatorio = {false}/>
                    </Grid>                    
                    <Grid item xs={2}>
                        <CampoEditable   valor={telefono} label="Teléfono" campo="telefono" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CampoEditable   valor={email} label="Correo Electrónico" campo="email" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={3}>
                        <CampoEditable   valor={domicilio} label="Domicilio" campo="domicilio" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable   valor={localidad} label="Localidad" campo="localidad" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable   valor={provincia} label="Provincia" campo="provincia" editable={false} obligatorio = {false}/>
                    </Grid>
                    <Grid item xs={1}>
                        <CampoEditable   valor={codigoPostal} label="Código Postal" campo="codigoPostal" editable={false} obligatorio = {false}/>
                    </Grid>


                    <Grid item xs={1}>
                        <Button variant="contained" onClick={modificarDatos} >Modificar</Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
    function modificarDatos(){
        navigate("/contribuyente/edicion")
    }
}
export default PanelContribuyente 