const IconoEmail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 80"
    width="64px"
    height="64px"
    {...props}
  >
    <title>{"Asset 22"}</title>
    <g data-name="Layer 2">
      <path
        d="M63.47 5.17A8 8 0 0 0 56 0H8A8 8 0 0 0 .48 5.33 7.44 7.44 0 0 0 0 8v32a8 8 0 0 0 5 7.41A7.85 7.85 0 0 0 8 48h48a7.32 7.32 0 0 0 2.85-.56A7.84 7.84 0 0 0 63 43.89 8 8 0 0 0 64 40V8a7.86 7.86 0 0 0-.53-2.83ZM5.33 8A2.68 2.68 0 0 1 8 5.33h48A2.68 2.68 0 0 1 58.67 8v.51l-21.26 16L33 27.92l-1 .75-1-.78-4.46-3.33L5.33 8.61Zm0 32V15.28l16.78 12.61L5.39 40.45a2 2 0 0 1-.06-.45Zm6 2.67 15.23-11.44 3.84 2.9a2.64 2.64 0 0 0 3.2 0l3.81-2.88 15.12 11.42ZM58.67 40a2 2 0 0 1-.08.56L41.84 27.89l16.83-12.72Z"
        data-name="Layer 1"
        style={{
          fill: "#35353d",
        }}
      />
    </g>
  </svg>
)
export default IconoEmail
