import ModalCargando from './modalCargando';
import BotonPago from "../componentes/botonPago"
import {
    Table,
    Box,
    Grid,
    Button,
    Tooltip,
} from "@mui/material";
import {
    StyledTableContainer,StyledTableHead,ColumnaNumerica,
    StyledTableBody,ColumnaNumericaHeader,ColumnaNumericaAcortada,
    ColumnaTexto,ColumnaTextoHeader,ColumnaTextoExtendida,
    ColumnaBotones,ColumnaIconos,StyledTableRow
} from './componentesModificados';
//importacion de iconos
import IconoCancelar from './iconos/iconoCancelar';
import IconoTicket from './iconos/iconoTicket';
import IconoTarjeta from './iconos/iconoTarjeta';
import rutas, { CancelarPagofallido } from '../configuracion/Configuracion'
import { useState, useEffect } from "react";
import Sesion from '../configuracion/controladorSesiones';
import HookModal from '../componentes/hooks/hookModal';
import ModalPercepciones from '../componentes/modalPercepciones';
import Notificacion from './notificacion';
import Contexto from './contexts/contexto'
import MenuImpresion from './menuImpresion';
import ModalPlanPago from './modalPlanPago';
import IconoPercepciones from './iconos/iconoPercepciones';

export default function TablaPadron({
    actualizarDatos = false,
    codigoImpuesto="",
    esIngresoBruto=false, //se habililata el boton de percepciones, columna de las mismas y cambia la api de busqueda por un que busque los iibb pro idcliente
    tipoPadron="",
    esFacturasSueltas = false,
}) {
    const anchoColumnas = [75, 110,100,'10%',80, 40,'10%', '10%','8%',110, '7%'];
    const[cargando, comenzarCargando, terminarCargando] = HookModal();//modal de carga
    const[movimientosSeleccionados, setMovimientosSeleccionados] = useState([]);//lista de pagos a generar pago electronico
    const[codigoCliente, setCodigoCliente] =  useState("");
    const[columnas, setColumnas] = useState([]);
    const[actualizarTabla, setActualizarTabla] = useState(false);
    const[existenPercepciones, setExistenPercepciones] = useState(false);
    const[existenSeleccionados, setExistenSeleccionados] = useState(false);
    const[modalPercepcionesDisponibles, abrirModalPercepcionesDisponibles, cerrarModalPercepcionesDisponibles] = HookModal();//modal de percepciones disponibles
    const[modalAplicarPercepciones, abrirModalAplicarPercepciones, cerrarModalAplicarPercepciones] = HookModal();//modal de percepciones aplicables a un movimiento
    const[modalPlanPago, abrirModalPlanPago, cerrarModalPlanPago] = HookModal();//modal para generar un plan de pago
    const[pagosVencidos, setPagosVencidos] = useState(false);
    const[procesarMacro, setProcesarMacro] = useState(false);

    useEffect(() => {
        
        if(codigoImpuesto !== "" && !esIngresoBruto && !esFacturasSueltas){
            if (tipoPadron === "XA" || tipoPadron === "XH" || tipoPadron === "XU" || tipoPadron === "XR" || tipoPadron === "XB"){
                cargarTablaXA();
            }else{
                buscarDatosImpuesto();
            }
            
        }else if(codigoImpuesto !== "" && !esIngresoBruto && esFacturasSueltas){
            buscarDatosContribuyente();
        }
    }, [codigoImpuesto]);
    useEffect(() => {
        if(codigoCliente !== "" && esIngresoBruto){
            //buscarDatosIIBB();
            buscarDatosImpuesto();
        }
    }, [codigoCliente]);
    useEffect( () => {
        procesarColumnas();
        actualizarSeleccionados();
    }, [columnas]);
    useEffect( () => {
        buscarCodigoContribuyente();
    }, []);
    useEffect( () => {
        if(actualizarDatos){
            actualizar();
        }
    }, [actualizarDatos]);
    useEffect( () => {
        if(actualizarTabla){
            if(esIngresoBruto){
                //buscarDatosIIBB();
                buscarDatosImpuesto();
            }else{
                if(esFacturasSueltas){
                    buscarDatosContribuyente();
                }else{
                    buscarDatosImpuesto();
                }
                
            }
            setActualizarTabla(false);
        }
    }, [actualizarTabla]);
    useEffect( () => {
        if(procesarMacro === true){
            repasarDeudaMacro()
            setProcesarMacro(false)
        }
    }, [procesarMacro]);
    ///busqueda de datos
    const buscarCodigoContribuyente = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva()){
                setCodigoCliente(sesion.buscarCodigoContribuyente())
            }
        } catch (error) {
            console.log(error);
        }
    } 
    const cargarTablaXA = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && codigoImpuesto != ""){
                comenzarCargando();
                let url = rutas.API + rutas.NuevaBusquedaPadron
                url = url.replace("param1", codigoCliente);
                url = url.replace("param2", codigoImpuesto);
                url = url.replace("param3", tipoPadron);
                const response = await fetch(url);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setColumnas(data.Datos.Instancias)
                    setMovimientosSeleccionados([]);
                    terminarCargando();
                    setExistenPercepciones(data.Datos.Datos.existenPercepciones)
                    if(data.Datos.Datos.pagosMacroPendientes){
                        setProcesarMacro(true);
                    }
                }else{
                    generarMensaje("Error de conexion.")
                    terminarCargando();
                }
            }
        } catch (error) {
            generarMensaje("Error de conexion.")
            terminarCargando();
        }
    } 
    const repasarDeudaMacro = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && codigoImpuesto != ""){
                let url = rutas.API + rutas.NuevaCancelacionMacro
                url = url.replace("param1", codigoCliente);
                url = url.replace("param2", codigoImpuesto);
                url = url.replace("param3", tipoPadron);
                const response = await fetch(url);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    cargarTablaXA()
                }
            }
        } catch (error) {
            generarMensaje("Error de conexion.")
        }
    } 
    const buscarDatosImpuesto = async ()=>{
        if(tipoPadron === "XA" || tipoPadron === "XH" ||  tipoPadron === "XU" || tipoPadron === "XR" || tipoPadron === "XB"){
            cargarTablaXA();
        }else{
            
            try {
                const sesion =  new Sesion();
                if(sesion.sesionActiva() && codigoImpuesto != ""){
                    comenzarCargando();
                    const response = await fetch(rutas.API + rutas.BusquedaResumenYDeudaImpuesto + codigoImpuesto);
                    const data = await response.json();
                    if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                        setColumnas(data.Datos.Instancias)
                        setMovimientosSeleccionados([]);
                        terminarCargando();
                    }else{
                        generarMensaje("Error de conexion.")
                        terminarCargando();
                    }
                }
            } catch (error) {
                generarMensaje("Error de conexion.")
                terminarCargando();
            }
        }

    } 
    const buscarDatosIIBB = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && codigoCliente != ""){
                comenzarCargando();
                var rutaApi = rutas.API + rutas.DetalleDeuda
                rutaApi = rutaApi.replace("param1",codigoCliente)
                rutaApi = rutaApi.replace("param2",codigoImpuesto)
                const response = await fetch(rutaApi);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setExistenPercepciones(data.Datos.Datos.existenPercepciones)
                    setColumnas(data.Datos.Instancias)
                    setMovimientosSeleccionados([]);
                    terminarCargando();
                }else{
                    generarMensaje("Error de conexion.")
                    terminarCargando();
                }
            }
        } catch (error) {
            generarMensaje("Error de conexion.");
            terminarCargando();
        }
    } 
    const buscarDatosContribuyente = async ()=>{
        try {
            const sesion =  new Sesion();
            if(sesion.sesionActiva() && codigoImpuesto != ""){
                comenzarCargando();
                const response = await fetch(rutas.API + rutas.BusquedaFacturasSueltas + codigoCliente);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                    setColumnas(data.Datos.Instancias)
                    setMovimientosSeleccionados([]);
                    terminarCargando();
                }else{
                    generarMensaje("Error de conexion.")
                    terminarCargando();
                }
            }
        } catch (error) {
            generarMensaje("Error de conexion.")
            terminarCargando();
        }
    } 
    ///busqueda de datos
    const procesarColumnas = async () =>{
        var alMenosUnoSeleccionado = false;
        columnas.forEach(item => {
            //cancelar pagos pendientes de macro
            if(tipoPadron !== "XA" && tipoPadron !== "XH" && tipoPadron !== "XU" || tipoPadron === "XR"){//con el esquema viejo sigo usando estos metodos
                if(item.pagoMacroPendiente || item.pagoMacroFallido){
                    if(item.codigoRecibo == ''){//caso de factura
                        pagarPendientePagoMacro(item.codigoFactura);
                    }else{
                        pagarPendientePagoMacro(item.codigoRecibo);
                    }
                }
            }
            if(item.seleccionado && !alMenosUnoSeleccionado){
                alMenosUnoSeleccionado  =true;
            }
        })
        if(alMenosUnoSeleccionado){
            setExistenSeleccionados(true);
        }else{
            setExistenSeleccionados(false);
        }
    }
    const actualizarSeleccionados = async () =>{
        const salida = [];
        try {
            await columnas.forEach(item => {
                if(item.seleccionado) {
                    salida.push(item.tipoDocumentoOriginante);
                    salida.push(item.IDFactura);
                }
            })
            setMovimientosSeleccionados(salida);
        } catch (error) {
            console.log(error);
        }
    }
    const pagarPendientePagoMacro = async (codigo) =>{
        try {
            const response = await fetch(rutas.API + rutas.CancelarPagoPendiente + codigo);
            const data = await response.json();
            if(data.Exito && data.Datos.Respuesta){//cancelado exitoso
                console.log("cancelando pago pendiente.")
            }
        } catch (error) {
            console.error(error);
        }
    }
    //viejo
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center',flexGrow: 1 }}>
            {/*notificaciones por snackbar
            <Notificacion 
                mensaje={textoNotificacion} 
                visible={notificacionVisible} 
                funcionAbrir = {abrirNotificacion} 
                funcionCerrar = {cerrarNotificacion}
            />*/}
            {/*notificaciones por snackbar*/}
            {/*modal de carga*/}
            <ModalCargando estadoModal={cargando}/>
            {/*modal de carga*/}
            {/*modal aplicar percepciones*/}
            <ModalPercepciones 
              estadoModalPercepciones = {modalPercepcionesDisponibles}
              cerrarPercepciones = {cerrarModalPercepcionesDisponibles}
              codigoFactura = {""}
              funcionActualizarPadre={actualizar}
            />
            {/*modal aplicar percepciones*/}
            {/*modal plan de pago*/}
            <ModalPlanPago 
            estadoModal={modalPlanPago} 
            cerrarModal={cerrarModalPlanPago} 
            listaMovimientos={movimientosSeleccionados} 
            actualizar={actualizar} 
            tipoPadron={tipoPadron}/>
            {/*modal plan de pago*/}
            <Grid container spacing={2}>
                <Grid item xs={7.5}>
                </Grid>
                {!esFacturasSueltas && <Grid item xs={2}>
                    {existenPercepciones && <Button variant="contained" onClick={abrirModalPercepcionesDisponibles} >percepciones disponibles</Button>}
                </Grid>}
                {!esFacturasSueltas && <Grid item xs={2.5} >
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" disabled={!existenSeleccionados || !pagosVencidos} onClick={abrirModalPlanPago} >generar plan de pago</Button>
                    </Box>
                </Grid>}
                <Grid item xs={12}>
                    <StyledTableContainer >
                        <Table stickyHeader >
                            <StyledTableHead >
                                <StyledTableRow>
                                    <ColumnaTextoHeader style={{ width: anchoColumnas[0],minWidth: anchoColumnas[0] }} >FECHA</ColumnaTextoHeader>
                                    <ColumnaTextoHeader style={{ width: anchoColumnas[1],minWidth: anchoColumnas[1] }}>FACTURA</ColumnaTextoHeader>
                                    <ColumnaTextoExtendida style={{ width: anchoColumnas[2],minWidth: anchoColumnas[2] }} >REFERENCIA</ColumnaTextoExtendida>
                                    <ColumnaNumericaHeader style={{ width: anchoColumnas[3],minWidth: anchoColumnas[3], textAlign: 'right'  }}>MONTO</ColumnaNumericaHeader>
                                    {existenPercepciones &&<ColumnaIconos>PERCEPCIONES</ColumnaIconos>}
                                    <ColumnaNumericaHeader  style={{ width: anchoColumnas[7],minWidth: anchoColumnas[7], textAlign: 'right'  }}>SALDO</ColumnaNumericaHeader>
                                    <ColumnaNumericaAcortada  style={{ width: anchoColumnas[4],minWidth: anchoColumnas[4] }}>DÍAS</ColumnaNumericaAcortada>
                                    <ColumnaNumericaHeader  style={{ width: anchoColumnas[5],minWidth: anchoColumnas[5], textAlign: 'right'  }}>INTERÉS</ColumnaNumericaHeader>
                                    <ColumnaNumericaHeader  style={{ width: anchoColumnas[6],minWidth: anchoColumnas[6], textAlign: 'right'  }}>TOTAL</ColumnaNumericaHeader>
                                    
                                    <ColumnaTextoHeader  style={{ width: anchoColumnas[8],minWidth: anchoColumnas[8] }}>FECHA PAGO</ColumnaTextoHeader>
                                    <ColumnaTextoHeader  style={{ width: anchoColumnas[9],minWidth: anchoColumnas[9] }}>COMPROBANTE</ColumnaTextoHeader>
                                    
                                    <ColumnaBotones style={{ width: anchoColumnas[10],minWidth: anchoColumnas[10] }}>
                                        <BotonPago listaItems={movimientosSeleccionados} funcionActualizar={actualizar}/>
                                    </ColumnaBotones>
                                </StyledTableRow>
                            </StyledTableHead>
                            <StyledTableBody style={{maxHeight:300, minHeight: 50}}>
                            {columnas.map((row) => {
                                return generarFila(row);
                            })}
                            </StyledTableBody>
                        </Table>
                    </StyledTableContainer>
                </Grid>
            </Grid>


            {/*fomulario*/}

        </Box>
    );

    function SeleccionarFila( paramIDFila){
        var seguirMarcando = false;
        var deseleccionarTodo = false;
        var contador = 0;
        var todosVencidos = true;
        var anualSeleccionado  =false

        var data = columnas.map((row) => {

            if(row.pagoHabilitado && row.codigoMPS === paramIDFila){
                if (row.lote === 'XA2024' || row.lote === 'XH2024' || row.lote === 'XU2024' || row.lote === 'XR2024' ){
                    anualSeleccionado = true
                }
            }
            if(row.pagoHabilitado && (row.seleccionado || row.codigoMPS === paramIDFila) ){
                todosVencidos = todosVencidos && row.estaVencido;
            }
            if (row.codigoMPS === paramIDFila && !row.seleccionado){
                seguirMarcando = true;
            }
            if(row.codigoMPS === paramIDFila && row.seleccionado){
                deseleccionarTodo = true;
            }
            if(deseleccionarTodo){
                return {
                    ...row,
                    seleccionado: false
                };
            }else{
                if(seguirMarcando && row.pagoHabilitado ){
                    if (row.lote === 'XA2024' || row.lote === 'XH2024' || row.lote === 'XU2024' || row.lote === 'XR2024' ){
                        return {
                            ...row,
                            seleccionado: false
                        };
                    }else{
                        contador++
                        return {
                            ...row,
                            seleccionado: true
                        };
                    }
                }else{
                    return {
                        ...row,
                        seleccionado: false
                    };
                }
            }
        })
        if (anualSeleccionado){
            data = columnas.map((row) => {
                
                if(row.pagoHabilitado && (row.seleccionado || row.codigoMPS === paramIDFila) ){
                    todosVencidos = todosVencidos && row.estaVencido;
                }
                if(row.codigoMPS === paramIDFila && row.seleccionado){
                    deseleccionarTodo = true;
                }
                if(deseleccionarTodo){
                    return {
                        ...row,
                        seleccionado: false
                    };
                }else{
                    if (row.lote === 'XA2024' || row.lote === 'XH2024' || row.lote === 'XU2024' || row.lote === 'XR2024' ){
                        if(row.pagoHabilitado){
                            return {
                                ...row,
                                seleccionado: true
                            };
                        }else{
                            return {
                                ...row,
                                seleccionado: false
                            };
                        }
                        
                    }else{
                        return {
                            ...row,
                            seleccionado: false
                        };
                    }
                }
            })
        }
        setPagosVencidos(todosVencidos)
        setColumnas(data);
    };
    //notificaciones por snackbar
    function generarMensaje(mensaje){
        //setTextoNotificacion(mensaje)
        //setNotificacionVisible(true)
    }
    //notificaciones por snackbar

    function generarFila(data){
        const editable = data.pagoHabilitado;
        return (
            <StyledTableRow>
                
                <ColumnaTexto style={{ width: anchoColumnas[0],minWidth: anchoColumnas[0] }} component="th" scope="row">
                    {data.fecha}
                </ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[1],minWidth: anchoColumnas[1] }}>
                    {generarBotonImpresionOriginante(data)}
                </ColumnaTexto>
                <Tooltip title={data.referencia}>
                    <ColumnaTextoExtendida style={{minWidth: anchoColumnas[2] }}>
                        {data.referencia}
                    </ColumnaTextoExtendida>
                </Tooltip>
                <ColumnaNumerica style={{ width: anchoColumnas[3],minWidth: anchoColumnas[3] }}>{acortarDosDecimales(data.monto)}</ColumnaNumerica>
                {existenPercepciones && generarIconoPercepciones(data)}
                <ColumnaNumerica style={{ width: anchoColumnas[8],minWidth: anchoColumnas[8] }}>{acortarDosDecimales(data.saldo)}</ColumnaNumerica>
                <ColumnaNumericaAcortada style={{ width: anchoColumnas[5],minWidth: anchoColumnas[4] }}>{data.dias}</ColumnaNumericaAcortada>
                <ColumnaNumerica style={{ width: anchoColumnas[6],minWidth: anchoColumnas[6] }} >{acortarDosDecimales(data.interes)}</ColumnaNumerica>
                <ColumnaNumerica style={{ width: anchoColumnas[7],minWidth: anchoColumnas[7] }}>{acortarDosDecimales(data.total)}</ColumnaNumerica>
                
                <ColumnaTexto style={{ width: anchoColumnas[8],minWidth: anchoColumnas[8] }} >{data.fechaPago}</ColumnaTexto>
                <ColumnaTexto style={{ width: anchoColumnas[9],minWidth: anchoColumnas[9] }}>{generarBotonImpresionCancelante(data)}</ColumnaTexto>
                
                {generarIconoSeleccion(data)}
            </StyledTableRow>
        );
    };
    function generarBotonImpresionOriginante(estado){
        return (
            <Tooltip title={"Visualizar"}>
                <MenuImpresion 
                    codigoInterno={estado.codigoFactura} 
                    codigoPublico={estado.codigoPublicoFactura} 
                    tipoDocumento={estado.tipoDocumentoOriginante}
                    tipoPadron={tipoPadron}
                />
            </Tooltip>
        )
    }
    function generarBotonImpresionCancelante(estado){
        return (
            <Tooltip title={"Visualizar"}>
                <MenuImpresion 
                    codigoInterno={estado.codigoRecibo} 
                    codigoPublico={estado.codigoPublicoRecibo} 
                    tipoDocumento={estado.tipoDocumentoCancelante}
                    tipoPadron={tipoPadron}
                />
            </Tooltip>
        )
    }
    function generarIconoPercepciones(estado){
        if(estado.percepcionesDisponibles){
            return(
                <ColumnaBotones style={{ width: anchoColumnas[11],minWidth: anchoColumnas[11] }}>
                    <Tooltip title={"Aplicar percepciones disponibles"}>
                        <div>
                        <IconoPercepciones codigoFactura={estado.IDFactura} actualizar={actualizar} />
                        </div>
                    </Tooltip>
                    
                </ColumnaBotones>
            );
        }
        return(
            <ColumnaBotones >
                {"      "}
            </ColumnaBotones>
        );
    }
    
    function generarIconoSeleccion( estado){
        if(estado.pagoMacroFallido){
            return(
                <Tooltip title={"Pago fallido."}>
                    <ColumnaIconos style={{ width: anchoColumnas[11],minWidth: anchoColumnas[11] }}>
                        {esIngresoBruto 
                            ? 
                            <IconoCancelar onClick={() => buscarDatosImpuesto()}/> 
                            : 
                            esFacturasSueltas
                            ?
                            <IconoTarjeta onClick={() => buscarDatosContribuyente()}/>
                            :
                            <IconoCancelar onClick={() => buscarDatosImpuesto()}/>
                        }
                    </ColumnaIconos>
                </Tooltip>
            );
        }
        if(estado.pagoMacroPendiente){
            //
            return(
                <Tooltip title={"Pago en proceso, Haga click para actualizar."}>
                    <ColumnaIconos>
                        {esIngresoBruto 
                            ? 
                            <IconoTarjeta onClick={() => buscarDatosImpuesto()}/> 
                            : 
                            esFacturasSueltas
                            ?
                            <IconoTarjeta onClick={() => buscarDatosContribuyente()}/>
                            :
                            <IconoTarjeta onClick={() => buscarDatosImpuesto()}/>
                        }
                        
                    </ColumnaIconos>
                </Tooltip>
            );
        }

        if(estado.pagoHabilitado){
            return(
                <ColumnaBotones style={{ width: anchoColumnas[11] }}>
                    <Tooltip title={"Seleccionar para pagar."}>
                        <input type="checkbox" checked={estado.seleccionado} onChange={()=>SeleccionarFila(estado.codigoMPS)}/>
                    </Tooltip>
                </ColumnaBotones>
            );
        }
        return(
            <ColumnaBotones >
                {"      "}
            </ColumnaBotones>
        );
    }
    function acortarDosDecimales( paramMonto){
        return parseFloat(paramMonto).toFixed(2)
    }
    function actualizar(){
        setActualizarTabla(true);
    }
}