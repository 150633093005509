class Sesion{
  constructor(){
    this.localStorage = window.localStorage;
  }
  sesionActiva(){
    const fechaActual = new Date();
    const descontada = new Date(fechaActual.getTime() - (6 * 60 * 60 * 1000));
    const sesionActual = this.localStorage.getItem('sesion');
    const instancia = JSON.parse(sesionActual);
    if(instancia.fechaFin > descontada.getTime()){
      return true;
    }
    return false;
  }
  primerUso(){
    const sesionActual = this.localStorage.getItem('sesion');
    const instancia = JSON.parse(sesionActual);
    if(!instancia.primerUso || instancia.test){
      return true;
    }
    return false;
  }
  emailRegistrado(){
    const sesionActual = this.localStorage.getItem('sesion');
    const instancia = JSON.parse(sesionActual);
    if(instancia.emailRegistrado){//no lo envio directo por si da la casualidad tiene un sesion vieja
      return true;
    }
    return false;
  }
  generarSesion(paramCodigoContribuyente, paramNombre,paramCUIT,paramPrimerUso, paramEmailRegistrado ){
    const fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() + 6);
    const sesion = { 
      fechaFin: fechaActual.getTime(),
      codigoContribuyente: paramCodigoContribuyente,
      nombre: paramNombre,
      cuit:paramCUIT,
      test:false,
      primerUso: paramPrimerUso,
      emailRegistrado: paramEmailRegistrado
    };
    this.localStorage.setItem('sesion', JSON.stringify(sesion));
    console.log("sesion generada.")
  }
  desactivarSesion(){
    const fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() + 6);
    const sesion = { fechaFin: 0, codigoContribuyente: 0, nombre: "", cuit:"", test:false, primerUso:"",emailRegistrado:false };
    this.localStorage.setItem('sesion', JSON.stringify(sesion));
  }
  buscarCodigoContribuyente(){
    try {
      const sesionActual = this.localStorage.getItem('sesion');
      const instancia = JSON.parse(sesionActual);
      return instancia.codigoContribuyente;
    } catch (error) {
      return 0;
    }
  }
  buscarPrimer(){
    try {
      const sesionActual = this.localStorage.getItem('sesion');
      const instancia = JSON.parse(sesionActual);
      return instancia.primerUso;
    } catch (error) {
      return false;
    }
  }
  buscarInstanciaSesion(){
    if(this.sesionActiva()){
      const sesionActual = this.localStorage.getItem('sesion');
      return  JSON.parse(sesionActual);
    }
    return null;
  }
  buscarCUIT(){
    try {
      const sesionActual = this.localStorage.getItem('sesion');
      const instancia = JSON.parse(sesionActual);
      return instancia.cuit;
    } catch (error) {
      return "";
    }
  }
}
export default Sesion;