const rutas ={

    //API: 'http://localhost:14415/api/',
    //AmbienteLocal: true,
    //API: 'http://localhost:4000/api/',
    //API: 'http://200.142.236.197:4000/api/',
    //API: 'http://rentas.paulcardenas.com.ar/api/api/',
    API: 'https://rentas.haciendatrevelin.gob.ar/api/',
    AmbienteLocal: false,
    APIHTTP: 'http://rentas.haciendatrevelin.gob.ar/api/',
    APIHTTPS: 'https://rentas.haciendatrevelin.gob.ar/api/',
    Version: '2024.08.26',
    ProxyPlanPagoPreview: 'planpago/preview/',
    ProxyPlanPagoGenerar: 'planpago/generar/',
    ProxyExclude: '/api/',
    DetalleDeuda: 'contribuyente/detalledeuda/buscar?codigoCliente=param1&codigoImpuesto=param2',
    DeclaracionPendiente: 'contribuyente/declaracionpendiente/buscar?codigoCliente=',
    DatosContribuyente: 'contribuyente/buscar?codigoCliente=',
    PreCalculoMonto: 'factura/ingresosbrutos/precalculo/generar?serializedBody=',
    Login: 'usuario/existe',
    LoginGet: 'usuario/existe?usuario=param1&contrasenia=param2',
    CambiarContrasenia: 'usuario/modificar?serializedBody=',
    BusquedaUsuario: 'usuario/buscar?codigoCliente=',
    DeclaracionIIBB: 'factura/ingresosbrutos/generar?serializedBody=',
    ExistenPercepcionesCliente: 'contribuyente/percepcion/existe?codigoCliente=',
    PercepcionesCliente: 'contribuyente/percepcion/buscar?codigoCliente=',
    PercepcionesFactura: 'factura/percepcion/buscar?codigoFactura=',
    AplicarPagoConPercepciones: 'factura/ingresosbrutos/pagopercepciones/aplicar?serializedBody=',//desuso
    GenerarPagoElectronico: 'pago/generar?serializedBody=',
    //EndPointPagosMacro: 'https://sandboxpp.asjservicios.com.ar/',
    EndPointPagosMacro: 'https://botonpp.macroclickpago.com.ar/',
    CancelarPagoPendiente: 'macro/transaccion/cancelar?codigoFactura=',
    CancelarPagofallido: 'macro/transaccion/despagar?codigoFactura=',
    impresionFactura: 'impresion/factura?idFactura=',
    impresionRecibo: 'impresion/recibo?idRecibo=param1&codigoCliente=param2',
    impresionPlanPago: 'impresion/planpago?idPlanPago=',
    impresionCobrosFactura: 'impresion/cobros/factura?idFactura=',
    impresionCobrosRecibo: 'impresion/cobros/recibo?idRecibo=',
    impresionPercepciones: 'impresion/percepciones?idCliente=',
    BusquedaImpuestos: 'contribuyente/impuestos/buscar?codigoCliente=',
    BusquedaPlanesPago: 'contribuyente/planpago/buscar?codigoCliente=',
    BusquedaResumenYDeudaImpuesto: 'contribuyente/impuesto/detalledeuda/buscar?codigoImpuesto=',
    BusquedaFacturasSueltas: 'contribuyente/detalledeuda/sueltos?codigoCliente=',
    BusquedaDatosTish: 'impuestos/tish/buscar?codigoImpuesto=',
    BusquedaDatosAutomotor: 'impuestos/automotor/buscar?codigoImpuesto=',
    BusquedaDatosRural: 'impuestos/rural/buscar?codigoImpuesto=',
    BusquedaDatosUrbano: 'impuestos/urbano/buscar?codigoImpuesto=',
    BusquedaDatosiibb: 'impuestos/iibb/buscar?codigoCliente=',
    BusquedaDatosPlanPago: 'impuestos/planpago/buscar?codigoPlan=',
    BusquedaDatosPlanPagoModulos: 'impuestos/planpago/modulos/buscar?codigoPlan=',
    EnviarSugerencia: 'sugerencia/generar?codigoCliente=param1&texto=param2&path=param3',
    PreviewPlanPago: '/preview',
    GenerarPlanPago: '/generar',
    BusquedaResumenYDeudaPlanPago: 'contribuyente/impuesto/planpago/buscar?codigoRecibo=',
    BusquedaResumenYDeudaPlanPagoModulos: 'contribuyente/impuesto/planpago/modulos/buscar?codigoPlan=',
    ImpresionCobrosUniversal: 'impresion/cobros?tipoDocumento=param1&codigoDocumento=param2&codigoCliente=param3&filtrarCliente=param4&filtrarOriginante=param5&filtroOriginante=param6',
    ImpresionLibreDeudaUniversal: 'impresion/libredeuda?codigo=param1&tipo=param2',
    ImpresionValuacionFiscal: 'impresion/valuacionfiscal?codigo=param1&tipo=param2',
    ImpresionInscripcion: 'impresion/inscripcion?codigo=param1&tipo=param2',
    ImpresionHabilitacionComercial: 'impresion/habilitacioncomercial?codigo=',
    ModificarContribuyente: 'contribuyente/modificar?codigoCliente=param1&nombre=param2&cuit=param3&dni=param4&domcilio=param5&localidad=param6&provincia=param7&codigoPostal=param8&telefono=param9&email=param10',
    ValidarLibreDeudaImprimible: 'impresion/libredeuda/existe?codigo=param1&tipo=param2',
    ValidarBajaImprimible: 'impresion/baja/existe?codigo=param1&tipo=param2',
    ImpresionBaja:'impresion/baja?codigo=param1&tipo=param2',
    ImpresionTerminosYCondiciones:'impresion/contrato/planpago?codigoPlan=param1&tipoPadron=param2',
    VerificarCUIT: 'contribuyente/cuit/validar?cuit=',
    GenerarRegistroWeb: 'contribuyente/registro/generar?cuit=param1&email=param2&telefono=param3',
    CancelarRegistroWeb: 'contribuyente/registro/cancelar?cuit=param1',
    ValidarRegistroWeb: 'contribuyente/registro/validar?cuit=param1&codigo=param2',
    GenerarRegistroEmail: 'usuario/email/registrar?codigoCliente=param1&cuit=param2&correo=param3',
    ValidarRegistroEmail: 'usuario/email/validar?codigo=param1&codigoCliente=param2&cuit=param3&correo=param4',
    PosiblesDominiosBloquebles: 'https://botonpp.macroclickpago.com.ar/',
    RevisionReciboMacro: '/macro/transaccion/validar?codigo=',
    NuevaBusquedaPadron:'contribuyente/deuda/tabla?codigoCliente=param1&codigoPadron=param2&tipoPadron=param3',
    NuevaCancelacionMacro:'contribuyente/deuda/repaso?codigoCliente=param1&codigoPadron=param2&tipoPadron=param3',
    verificarRecibo:'macro/validar?codigoRecibo=',
    aplicarPercepciones:'factura/percepciones/afectar?codigoFactura=',
};
module.exports = rutas;