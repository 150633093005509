import react from "react";
import { useState } from "react";
import { Button,Tooltip } from "@mui/material";
import rutas from "../configuracion/Configuracion";

function Footer({sugerenciaActiva = false}) {
    const panelStyle = {
        position: 'fixed',
        right: 4,
        bottom: 4,
        zIndex:10,
        borderRadius:'5px',
        boxShadow:'0px 0px 10px rgb(0 0 0 / 20%)',
        backgroundColor: 'white'
      };
    return(
        <div>
            <strong className="footer">
                Patagonia Software Coop Ltda. V{rutas.Version}
            </strong>

        </div>
    );
}

export default Footer 