import { useContext, useState,useEffect } from "react"

function ContadorDescendente({segundos = 300,notificarFinConteo = null}){
    const[contador, setContador] = useState(segundos)
    
    useEffect(() => {
        const interval = setInterval(() => {
          if (contador > 0) {
            setContador(contador - 1);
          }else{
            notificarFinConteo()
          }
        }, 1000);
        return () => clearInterval(interval);
    }, [contador]);
    return (
        <h3>
            {convertirSegundosAHorasMinutosSegundos(contador)}
        </h3>
    )

    function convertirSegundosAHorasMinutosSegundos(segundos) {
        const minutos = Math.floor(segundos / 60);
        const segundosRestantes = segundos % 60;
      
        const minutosFormateados = minutos.toString().padStart(2, '0');
        const segundosFormateados = segundosRestantes.toString().padStart(2, '0');
      
        return `${minutosFormateados}:${segundosFormateados}`;
      }
}
export default ContadorDescendente;