import ModalCargando from './modalCargando';
import rutas from '../configuracion/Configuracion'
import { useState, useEffect, useRef } from "react";
import Sesion from '../configuracion/controladorSesiones';
import {
    Button,
} from "@mui/material";
import { useLocation } from 'react-router-dom';
import {EsDispositivoMovil} from '../configuracion/Utils'

function BotonPago({listaItems=[], funcionActualizar}) {
    ///El formato de los items es tipo documento(ej. FV) y la factura (ej 000060002001)
    ///en caso que la redireccion este activa se buscara en la api el form de pago
    ///en caso contrario se genera un button se generara un button desactivado
    const[clicked, setClick] = useState(false);
    const[pagoPreparado, setPagoPreparado] = useState(false);
    const[redireccionActiva,cambiarEstadoFacturacion]=useState(false);
    const [botonHTML, setBotonHTML] = useState("");
    const [cargando, cambiarEstadoCargando] = useState(false);
    const [codigoReciboGenerado, cambiarCodigoReciboGenerado] = useState("");
    const formRef = useRef(null);
    const location = useLocation();
    const { pathname } = location;
    // const [codigoReciboGenerado, setCodigoReciboGenerado] = useState("");
    const [procesarReciboGenerado, setProcesarReciboGenerado] = useState(false);
    useEffect(() => {
        setPagoPreparado(false);
    }, []);
    useEffect(() => {
        if(listaItems.length < 1){//valido sin existen items a procesa, y como entan en parejas verifico de 2 para atras
            setPagoPreparado(false);
        }else{
            var existenElementosNulos = false;
            listaItems.forEach(element => {
                if( element == null){
                    existenElementosNulos=true;
                }
            });
            setPagoPreparado(!existenElementosNulos);
        }
    }, [listaItems]);
    useEffect(() => {
        if(pagoPreparado && clicked){
            /*
            console.log("buscando forms " + procesarReciboGenerado + " " + codigoReciboGenerado)
            if(procesarReciboGenerado){
                validarReciboGenerado(codigoReciboGenerado)
            } */
            console.log("buscando forms")
            validarRecibo();
            document.getElementById('formMacro').submit();
        }
    }, [botonHTML]);
    useEffect(() => {
        if(codigoReciboGenerado !== null && codigoReciboGenerado !== undefined && codigoReciboGenerado !== ''){
            validarRecibo();
        }
    }, [codigoReciboGenerado]);
    const validarRecibo = async() =>{
        try {
            cambiarCodigoReciboGenerado("");//
            let codigo  = codigoReciboGenerado.replace('-','');
            console.log("validando recibo generado. ",codigo );
            if(EsDispositivoMovil()){
                await sleep(25000);//25 segundos para validar
            }else{
                await sleep(60000);//1 minuto para validar
            }
            //le damos 120 seg de gracia para que genere en macro la transaccion como pendiente
            const responseValidacion = await fetch(rutas.API + rutas.verificarRecibo + codigo);
            const dataVal = await responseValidacion.json();
            if(dataVal.Exito && dataVal.Datos.Respuesta && dataVal.Datos.Datos.recargar){//recibo generado sin respaldo en macro
                console.log("recibo generado erroneamente")
                setTimeout(function () {
                    funcionActualizar();
                }, 1000);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };
    const generarForm = async () =>{
        //para evitar pisado de datos declaro dentro de la funcion asyncrona
        var listado = listaItems;
        var esRecibo = listado.length>2;
        ///tipo de pago a generar 
        
        var tipoPadron = "";
        switch (true) {
            case pathname.includes('/contribuyente/automotor'):
                tipoPadron="XA";
            break;
            case pathname.includes('/contribuyente/rural'):
                tipoPadron="XR";
            break;
            case pathname.includes('/contribuyente/urbano'):
                tipoPadron="XU";
            break;
            case pathname.includes('/contribuyente/tish'):
                tipoPadron="XH";
            break;
            case pathname.includes('/contribuyente/ingresosbrutos'):
                tipoPadron="XB";
            break;
          }
        
        ///tipo de pago a generar 
            try {
                cambiarEstadoCargando(true);
                const sesion =  new Sesion();
                if(sesion.sesionActiva()){
                    const codigoContribuyente = sesion.buscarCodigoContribuyente();
                    var bodyPost = JSON.stringify({
                        "bodyCodigoContribuyente":codigoContribuyente,
                        "bodyListOriginantesConTipo":listado,
                        "bodyPagarRecibo":esRecibo,
                        "tipoPadron":tipoPadron,
                    });
                    const response = await fetch(rutas.API + rutas.GenerarPagoElectronico + bodyPost);
                    const data = await response.json();
                    var codigoRecibo = data.Datos.Datos.codigoRecibo;
                    var esRecibo = data.Datos.Datos.esRecibo;
                    if(data.Exito && data.Datos.Respuesta){//busqueda exitosa
                        setClick(true);
                        setBotonHTML(data.Datos.HTML);
                        if(esRecibo === true){
                            cambiarCodigoReciboGenerado(codigoRecibo)
                        }
                        /*
                        if((codigoRecibo !== null || codigoRecibo !== undefined || codigoRecibo !== '' ) && esRecibo == true && !EsDispositivoMovil()){
                            try {
                                const responseValidacion = await fetch(rutas.API + rutas.RevisionReciboMacro + codigoRecibo);
                                const dataVal = await responseValidacion.json();
                                if(dataVal.Exito && dataVal.Datos.Respuesta && dataVal.Datos.Datos.recargar){//recibo generado sin respaldo en macro
                                    console.log( codigoRecibo + "Eliminada " + dataVal.Datos.Datos.eliminada)
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        }
                        */
                        //espero un par de segundos antes de actualizar la tabla para evitar que no se haya tardado el procesamiento
                        setTimeout(function () {
                            funcionActualizar();
                        }, 3000);
                    }
                }
                cambiarEstadoCargando(false);
            } catch (error) {
                console.error(error);
            }

    }  
    const validarReciboGenerado = async (codigoRecibo) =>{
        //valido si el recibo recien generado tiene una contraparte en macro, ya sea por que estaba apagado el servicio o lo que sea
        //al generar el recibo web de macro, en caso del recibo generado que no exista en macro se cancela el recibo
        console.log("Procesando " + codigoRecibo);
        if(codigoRecibo !== null || codigoRecibo !== undefined || codigoRecibo !== ''){
            setProcesarReciboGenerado(false)
            try {
                const response = await fetch(rutas.API + rutas.RevisionReciboMacro + codigoRecibo);
                const data = await response.json();
                if(data.Exito && data.Datos.Respuesta && data.Datos.recargar){//recibo generado sin respaldo en macro
                    console.log("recibo sin respaldo en macro.")
                }
            } catch (error) {
                console.error(error);
            }
        }
    }  
    return(
        <div >
            <form ref={formRef} style={{display:'none'}} id='formMacro' target="_blank" method='post' action={rutas.EndPointPagosMacro}>
                <div dangerouslySetInnerHTML={{ __html: botonHTML }}/>
                <button type='submit' >Pagar</button>
            </form>
            <ModalCargando  estadoModal={cargando}/>
            <Button 
                variant="contained"
                disabled={!pagoPreparado}
                onClick={generarForm}
            >
                PAGAR
            </Button>

        </div>
    );
}
export default BotonPago 