import Modal from '../componentes/modal';
import {
    Button,
} from "@mui/material";


function ModalDeclaracionExitosa({estadoModal, cerrarModal }) {

    return(
        <Modal isOpen={estadoModal} closeModal={cerrarModal} style={{marginTop:200}}>
            <div className="modal-background" visible>
                <div className="modal-container" >
                    <div className="Declaracion exitosa" />
                    <Button variant="contained" onClick={cerrarModal}>Aceptar</Button>
                </div>
            </div>
        </Modal>
    );
}
export default ModalDeclaracionExitosa 