import rutas from "./Configuracion"; 

export function EsDispositivoMovil() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
}
export function RedireccionarHTTP(){
    if (window.location.protocol === 'http:' && !EsDispositivoMovil() && rutas.AmbienteLocal == false) {
        window.location.href = 'https://' + window.location.host + window.location.pathname + window.location.search;
        console.log('redireccion a HTTPS.')
    }
}