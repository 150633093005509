import { styled } from '@mui/system';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Box,
    TableBody,
    Button,
    Tooltip,
    Icon
} from "@mui/material";

const StyledTableContainer = styled(TableContainer)({
    //marginTop: 80,
    width: '100%',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
});
const StyledTableHead = styled(TableHead)({
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
});
const StyledTableBody = styled(TableBody)({
    //marginTop: '100px',
    maxHeight: 250,
    height: 'auto'
    //height:'250px',
});
const ColumnaTexto = styled(TableCell)({
    minWidth:'40px',
    textAlign: "left",
    fontSize: '0.75rem', // Tamaño de la fuente
    padding: '10px 8px',
});
const ColumnaTextoHeader = styled(ColumnaTexto)({
    paddingLeft: "10px",
});
const ColumnaTextoExtendida = styled(ColumnaTexto)({
    minWidth:'250px',
});
const ColumnaNumerica = styled(ColumnaTexto)({
    textAlign: "right",
    padding: '10px 2px',
    paddingRight:'10px'
});
const ColumnaNumericaAcortada = styled(ColumnaNumerica)({
    minWidth:'40px',
    textAlign: "center",
    fontSize: '0.75rem',
});
const ColumnaNumericaHeader = styled(ColumnaNumerica)({
    textAlign: "center",
});
const ColumnaBotones = styled(ColumnaTexto)({
    textAlign: "center",
});
const ColumnaIconos = styled(TableCell)({
    textAlign: "center",
    padding: "4px 4px",
    minWidth: "30px"
});
const StyledTableRow = styled(TableRow)({
    //display: "flex",
});
export {
    StyledTableContainer,StyledTableHead,ColumnaNumerica,
    StyledTableBody,ColumnaNumericaHeader,ColumnaNumericaAcortada,
    ColumnaTexto,ColumnaTextoHeader,ColumnaTextoExtendida,
    ColumnaBotones,ColumnaIconos,StyledTableRow
};
