import { useState } from "react";

const HookModal = () =>{
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const abrirModal = () =>{
        cambiarEstadoModal(true);
    }
    const cerrarModal = () =>{
        cambiarEstadoModal(false);
    }
    return [estadoModal, abrirModal, cerrarModal]
}
export default HookModal