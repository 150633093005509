import '../styles/HeaderStyle.css';
import '../styles/FooterStyle.css';
import '../styles/ContainerStyle.css';
import '../styles/SesionStyles.css';
import '../styles/ModalStyle.css'
import Header from "../componentes/header"
import Footer from "../componentes/footer"
import rutas from '../configuracion/Configuracion';
import Sesion from '../configuracion/controladorSesiones';
import { useState, useEffect,useRef } from "react";
import { Router, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    InputAdornment,
    IconButton
} from "@mui/material";
import { Link } from "react-router-dom";
import ModalCargando from '../componentes/modalCargando';
import Modal from '../componentes/modal';
import IconoAlert from '../componentes/iconos/iconoAlert';
import IconoOjoAbierto from '../componentes/iconos/iconoOjoAbierto';
import IconoOjoCerrado from '../componentes/iconos/iconoOjoCerrado';
import {RedireccionarHTTP} from '../configuracion/Utils'

function Login() {
    const [usuario, setUsuario] = useState("");
    const [contrasenia, setContrasenia] = useState("");
    const [estadoSesion, setEstadoSesion] = useState(false);
    const [mensajeError, setMensajeError]  = useState("");
    const [mostrarMensajeError, setMostrarMensajeError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [cargando, setCargando] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        RedireccionarHTTP();
    }, []);
    const iniciarSesion = async () =>{
        try {
            setCargando(true)
            const sesion =  new Sesion();
                var url =  rutas.API + rutas.LoginGet;
                url = url.replace("param1", usuario) 
                url = url.replace("param2", contrasenia) 
                const response = await fetch(url);
                const data = await response.json();
                if(data.Exito){
                    if(data.Datos.Respuesta && data.Datos.Datos.codigoCliente != ''){//busqueda exitosa
                        setMostrarMensajeError(false);
                        const sesion = new Sesion();
                        sesion.desactivarSesion();
                        sesion.generarSesion(
                            data.Datos.Datos.codigoCliente,
                            data.Datos.Datos.nombre,
                            data.Datos.Datos.cuit,
                            data.Datos.Datos.primerUso,
                            data.Datos.Datos.existeEmail
                        );
                        setEstadoSesion(true);
                        navigate("/contribuyente/impuestos");
                    }else{
                        setEstadoSesion(false);
                        setMensajeError(data.Datos.Datos.mensaje);
                        setMostrarMensajeError(true);
                    }
                }
                setCargando(false)
            return false;
        } catch (error) {
            console.error(error);
            setEstadoSesion(false);
        }
        /*
        try {
            setCargando(true)
            var bodyPost = JSON.stringify({
                "bodyUsuario":usuario,
                "bodyContrasenia":contrasenia})
            const response = await fetch(rutas.API + rutas.Login,{
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' },
                body: bodyPost
            });
            const data = await response.json();
            if(data.Exito){
                if(data.Datos.Respuesta && data.Datos.Datos.codigoCliente != ''){//busqueda exitosa
                    setMostrarMensajeError(false);
                    //mensaje
                    //generado de sesion
                    const sesion = new Sesion();
                    sesion.desactivarSesion();
                    sesion.generarSesion(
                        data.Datos.Datos.codigoCliente,
                        data.Datos.Datos.nombre,
                        data.Datos.Datos.cuit,
                        data.Datos.Datos.primerUso,
                        data.Datos.Datos.existeEmail
                    );
                    setEstadoSesion(true);
                    navigate("/contribuyente/impuestos");
                }else{
                    setEstadoSesion(false);
                    setMensajeError(data.Datos.Datos.mensaje);
                    setMostrarMensajeError(true);
                }
            }
            setCargando(false)
        } catch (error) {
            console.error(error);
            setEstadoSesion(false);
        }*/
    }
    const cambiarUsuario = event => {
        setUsuario(event.target.value);
    };
    const cambiarContrasenia = event => {
        setContrasenia(event.target.value);
    };
    function cerrarMensaje(){
        setMostrarMensajeError(false);
    }
    const ocultarCargando = () =>{
        setCargando(false)
    }
    const handleContrasenia = (event) => {
        if (event.key === 'Enter') {
          iniciarSesion()
        }
      };
      const togglePasswordVisibility  = () => {
        setShowPassword(!showPassword);
      };
    return(
        <div>
            <Header/>
            <ModalCargando estadoModal={cargando} cerrarModal={ocultarCargando}></ModalCargando> 
            <Modal isOpen={mostrarMensajeError} closeModal={cerrarMensaje} style={{marginTop:200}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:600}}  >
                        <IconoAlert ancho='64px' alto='64px'></IconoAlert>
                        <h2>{mensajeError}</h2>
                        <Button variant="contained" onClick={cerrarMensaje}>Aceptar</Button>
                    </div>
                </div>
            </Modal>
            <div className="form-container" style={{marginTop:80, fontFamily: "Roboto,sans-serif"}}>
                <img className="logo-login" 
                    alt="" 
                    height="100" 
                    width="150" 
                    src="/imagenes/LogoHeader.png" 
                    style={{ marginTop:10 }}
                />
                <div className="input-login" style={{alignItems: "center"}} >
                    <li>
                        <TextField
                            style={{ marginTop:20 }}
                            label="CUIT/CUIL"
                            type="text"
                            value={usuario}
                            variant="standard"
                            onChange={cambiarUsuario}
                            size="small"
                            fullWidth
                        />
                        <div style={{ position: 'relative'}}>
                            <TextField
                                label="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                value={contrasenia}
                                variant="standard"
                                onChange={cambiarContrasenia}
                                size="small"
                                onKeyDown={handleContrasenia}
                                fullWidth
                            />
                            <IconButton onClick={togglePasswordVisibility} style={{ position: 'absolute', top: '65%', right: 4, transform: 'translateY(-50%)' }}>
                                {showPassword ? <IconoOjoCerrado style={{ opacity: 0.5 }} /> : <IconoOjoAbierto style={{ opacity: 0.5 }} /> }
                            </IconButton>
                        </div>
                        <div style={{marginTop:20}}>
                            <Button 
                                style={{display: "block", margin: "auto"}}
                                variant="contained" 
                                onClick={() => {iniciarSesion()}}
                            >
                                Iniciar sesion
                            </Button>
                        </div>
                        <div style={{ marginTop:10 ,textAlign:'center' }}>
                            <Link 
                                to={"/cambio"}
                            >
                                ¿Desea cambiar su contraseña?
                            </Link>
                        </div>
                        <div style={{ marginTop:5 ,textAlign:'center'}}>
                            <Link 
                                to={"/registro"}
                            >
                                Obtener clave web
                            </Link>
                        </div>
                    </li>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default Login 