import IconoCorrecto from "./iconos/iconoCorrecto";
import Modal from "./modal";
import {
    Button,
} from "@mui/material";

function ModalDeclaracion({codigoFactura, estadoModal, cerrarModal}){

    return(
        <Modal isOpen={estadoModal} closeModal={cerrarModal} style={{marginTop:200}}>
            <div className="modal-background" visible>
                <div className="modal-container" style={{minWidth:500}} >
                    <IconoCorrecto/>
                    <p>Se genero correctamente la factura {codigoFactura}, para visualizarla vaya a la pestaña VENCIMIENTOS Y PAGOS.</p>
                    <Button 
                        variant="contained"
                        onClick={cerrar}
                    >
                    LISTO
                    </Button>
                </div>
            </div>
        </Modal>
    );
    function cerrar(){
        cerrarModal(false)
    }
}
export default ModalDeclaracion;
