import { useState, useEffect } from "react";
import { TextField,Button,InputAdornment,Grid } from "@mui/material";
import Modal from "./modal";
import IconoInfo from "./iconos/iconoInfo";

function CampoEditable({valor = "", valorOriginal = "", label = "", modificar=null, largoMaximo= 100, carateresPermitidos = "", editable = true, obligatorio = true, avisarEdicion=null, esCUIT = false}){
    const[editado, setEditado] = useState(false);
    const[mostrarMensaje, setMostrarMensaje] = useState(false);
    const[mensaje, setMensaje] = useState("El campo " + label + " es de caracter obligatorio.");
    const[mensajeLabel, setMensajeLabel] = useState("");

    useEffect(() => {
        //setValorOriginal(valor);
        //setValorEditable(valor);
    }, []);
    useEffect(() => {
        setMensajeLabel(label)
        if(obligatorio && valor === ""){
            setMensajeLabel(label + "*")
        }
    }, [valor]); 
    return (
        <div>
            <Modal isOpen={mostrarMensaje} closeModal={cerrarMensaje} style={{marginTop:200}}>
                <div className="modal-background" visible>
                    <div className="modal-container" style={{minWidth:600}}  >
                        <h2>{mensaje}</h2>
                        <Button variant="contained" onClick={cerrarMensaje} style={{margin:20}}>Aceptar</Button>
                    </div>
                </div>
            </Modal>
            {editado || obligatorio ?
                <TextField 
                fullWidth 
                label={mensajeLabel} 
                value={valor} 
                onChange={handleEdit} 
                onBlur={handleFocusOut} 
                onKeyDown={handleKeyDown} 
                inputProps={{ maxLength: largoMaximo }} 
                id="standard-basic"  
                variant="standard" />
                :
                <TextField 
                fullWidth 
                label={mensajeLabel} 
                value={valor} 
                onChange={handleEdit}
                onFocus={handleFocus}
                inputProps={{ maxLength: largoMaximo }} 
                id="standard-basic"  
                variant="standard" />
            }

        </div>
    )
    function handleFocus(){
        if(valor == ""){
            setEditado(true)
        }
    }
    function handleEdit(event) {
        if(!editable){
            event.preventDefault();
        }
        if(editable && modificar != null){
            if(avisarEdicion != null && valor != valorOriginal){
                avisarEdicion(true);
            }
            modificar(event.target.value)
        }
        //setValorEditable(event.target.value);
        setEditado(true);
    }
    function handleFocusOut(event){
        //validarCampo();
    }
    function validarCampo(){
        if(obligatorio && valor == ""){
            setMostrarMensaje("El campo " + label + " es de caracter obligatorio.");
            setMostrarMensaje(true);
        }
        if(!obligatorio && valor == ""){
            setEditado(false);
        }
    }
    function handleKeyDown (event){
        if(!editable){
            event.preventDefault();
        }
        if(esCUIT){
            
            if(valor.length == 2 && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)){
                event.preventDefault();
                modificar(valor + "-" + event.key);
            }
            if(valor.length == 11 && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)){
                event.preventDefault();
                modificar(valor + "-" + event.key);
            }
        }
        if (event.key === 'Enter') {
            handleFocusOut(event)
        }
        if(carateresPermitidos !== ""){
            const { keyCode } = event;
            if((keyCode >= 48 && keyCode <= 57) || // Números de 0 a 9
            (keyCode >= 65 && keyCode <= 90) || // Letras mayúsculas
            (keyCode >= 96 && keyCode <= 105)){
                const { key } = event;
                if (!carateresPermitidos.includes(key)) {
                    event.preventDefault();
                }
            }

        }

    }
    function cerrarMensaje(){
        setMostrarMensaje(false);
    }
}
export default CampoEditable